// styles
import NavigationDropdown from "components/navigation-dropdown";
import styled, { css } from "styled-components";
import mixins from "theme/mixins";

export const StyledCustomDropdown = styled(NavigationDropdown)`
  width: 100%;
  ${mixins.fontCoda()};
  color: ${(props) => props.theme.white};

  border-radius: 0;
`;

export const customToggleStyles = (selected: any) => [
  ...mixins.badgeDefaults(),
  selected &&
    css`
      background-color: ${(props) => props.theme.orange};
    `,
];
