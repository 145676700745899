import { OrganizationModel } from "modules/organization/model";
import { SysadminActions } from "./actions";

export interface SysadminStateModel {
  loading: boolean;
  error: null;

  // currently edited
  organization: null | OrganizationModel;

  // all
  organizations: null | OrganizationModel[];
}

// Initial state
const initialState: SysadminStateModel = {
  loading: false,
  organization: null,
  organizations: null,
  error: null, // not used
};

// Reducer
export const sysadminReducer = (state = initialState, action: any = {}) => {
  switch (action.type) {
    case SysadminActions.ORGANIZATION_UPDATE:
      return {
        ...state,
        loading: true,
      };

    case SysadminActions.ORGANIZATION_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case SysadminActions.ORGANIZATION_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case SysadminActions.ORGANIZATIONS_FETCH:
      return {
        ...state,
        loading: true,
      };

    case SysadminActions.ORGANIZATIONS_FETCH_SUCCESS:
      return {
        ...state,
        organizations: action.payload,
        loading: false,
      };

    case SysadminActions.ORGANIZATIONS_FETCH_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case SysadminActions.ORGANIZATION_CREATE:
      return {
        ...state,
        loading: true,
      };

    case SysadminActions.ORGANIZATION_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case SysadminActions.ORGANIZATION_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case SysadminActions.ORGANIZATION_DELETE:
      return {
        ...state,
        loading: true,
      };

    case SysadminActions.ORGANIZATION_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case SysadminActions.ORGANIZATION_DELETE_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case SysadminActions.ORGANIZATION_EDIT_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case SysadminActions.ORGANIZATION_EDIT_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        organization: action.payload,
        error: null,
      };

    case SysadminActions.ORGANIZATION_EDIT_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        data: null,
      };

    case SysadminActions.ORGANIZATION_EDIT_SAVE:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case SysadminActions.ORGANIZATION_EDIT_SAVE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case SysadminActions.ORGANIZATION_EDIT_SAVE_FAILURE:
      return {
        ...state,
        loading: false,
        data: null,
      };
    default:
      return state;
  }
};
