import styled from "styled-components";
import mixins from "theme/mixins";
import { NotificationMap } from "./notifications";

// styled components
export const NotificationContainer = styled.div``;

export const StyledNotification = styled.div<any>`
  ${mixins.fontCoda()};
  position: fixed;
  z-index: 4;
  left: 0;
  right: 0;
  bottom: 0px;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 2px;
  background-color: ${(props) =>
    props.theme[NotificationMap[props.type].color]};
  color: ${(props) => props.theme.white};
  text-align: center;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.div`
  padding-left: 10px;
`;

export const Message = styled.div``;
