import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import IconAndText from "components/icon-and-text";
import RequireRole from "components/require-role";
import SimpleSlider from "components/simple-slider";
import { FunctionComponent, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { Col, Container, Row } from "reactstrap";
import { productViewLoad } from "./actions";
import { useProductViewState } from "./hook";
import ProductAttributes from "./product-attribute";
import { StyledTitle, Wrapper } from "./product-view.styles";

const ProductView: FunctionComponent = () => {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const productViewState = useProductViewState();

  useEffect(() => {
    if (uuid) {
      // load product data
      productViewLoad(uuid);
    }
  }, [uuid]);

  // exit if no product
  const { data } = productViewState;
  if (data === null) {
    return null;
  }

  const { images, links = [], title, description, attributes } = data;

  return (
    <Wrapper>
      <Container>
        <Row>
          <Col md="8">
            <SimpleSlider images={images} />
          </Col>
          <Col md="4">
            <Row>
              <Col>
                <StyledTitle>{title}</StyledTitle>
              </Col>
            </Row>

            <Row>
              <Col>
                <p>{description}</p>
              </Col>
            </Row>

            <br />

            <Row>
              <Col>
                <ProductAttributes attributes={attributes} />
              </Col>
            </Row>

            {links.length > 0 && (
              <Row>
                <Col>
                  <h6>LINKS</h6>
                  {links.map((link) => {
                    return (
                      <a target="_NEW" href={link.url}>
                        {link.name}
                      </a>
                    );
                  })}
                </Col>
              </Row>
            )}

            <br />

            <RequireRole requiredRole="user">
              <Row>
                <Col>
                  <IconAndText
                    onClick={() => navigate(`/products/${uuid}/edit`)}
                    icon={faPencilAlt}
                  >
                    Edit or remove product
                  </IconAndText>
                </Col>
              </Row>
            </RequireRole>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
};

export default ProductView;
