import { FunctionComponent } from "react";
import { StyledButton } from "./button.styles";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import Icon from "./icon";

interface ButtonProps {
  children: any;
  className?: string;
  disabled?: boolean;
  onClick: () => void;
  icon?: IconProp;
  iconPosition?: string;
  color?: string;
  id?: string;
  style?: any;
}

const Button: FunctionComponent<ButtonProps> = ({
  children,
  id,
  onClick,
  color = "primary",
  disabled = false,
  icon,
  iconPosition,
  className,
  style,
}) => {
  const renderIcon = () => {
    if (!icon) {
      return null;
    }

    const style =
      iconPosition === "left" ? { paddingRight: 10 } : { paddingLeft: 10 };

    return (
      <div style={style}>
        <Icon icon={icon} size="lg" />
      </div>
    );
  };

  return (
    <StyledButton
      style={style}
      className={className}
      disabled={disabled}
      onClick={onClick}
      color={color}
      id={id}
    >
      {iconPosition === "left" ? renderIcon() : null}
      <div>{children}</div>
      {iconPosition === "right" ? renderIcon() : null}
    </StyledButton>
  );
};

export default Button;
