import { faEdit } from "@fortawesome/free-solid-svg-icons";
import TableActionCell from "components/table/action-cell";
import { OrganizationModel } from "modules/organization/model";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router";

interface OrganizationEditCellProps {
  organization: OrganizationModel;
}

const OrganizationEditCell: FunctionComponent<OrganizationEditCellProps> = ({
  organization,
}) => {
  const navigate = useNavigate();

  return (
    <TableActionCell
      icon={faEdit}
      onClick={() => navigate(`/organizations/${organization.uuid}/edit`)}
    ></TableActionCell>
  );
};

export default OrganizationEditCell;
