import { successNotification } from "modules/notifications/actions";
import { store } from "redux/store";

export enum SystemSettingsActions {
  SYSTEM_SETTINGS_LIST = "SYSTEM_SETTINGS_LIST",
  SYSTEM_SETTINGS_LIST_SUCCESS = "SYSTEM_SETTINGS_LIST_SUCCESS",
  SYSTEM_SETTINGS_CREATE = "SYSTEM_SETTINGS_CREATE",
  SYSTEM_SETTINGS_UPDATE = "SYSTEM_SETTINGS_UPDATE",
}

export const list = () => {
  store.dispatch({
    type: SystemSettingsActions.SYSTEM_SETTINGS_LIST,
    apiCall: {
      endpoint: `/system-settings/`,
      method: "get",
    },
  });
};

export const update = (uuid: string, dataObject: any) =>  {
 store.dispatch({
    type: SystemSettingsActions.SYSTEM_SETTINGS_UPDATE,
    apiCall: {
      endpoint: `/system-settings/${uuid}`,
      payload: dataObject,
      method: "patch",
      success: () => {
        list();
        successNotification("System settings updated!");
      },
    },
  });
};

export const batchUpdate = (objects: any) =>  {
  store.dispatch({
    type: SystemSettingsActions.SYSTEM_SETTINGS_UPDATE,
    apiCall: {
      endpoint: `/system-settings/`,
      payload: { objects },
      method: "patch",
      success: () => {
        list();
        successNotification("System settings updated!");
      },
    },
  });
};

export const create = (dataObject: any) => {
  const payload = dataObject;

  store.dispatch({
    type: SystemSettingsActions.SYSTEM_SETTINGS_CREATE,
    apiCall: {
      endpoint: `/system-settings/`,
      payload,
      method: "post",
      success: () => {},
    },
  });
};
