import { FunctionComponent } from "react";

interface TableBodyProps {
  children: any;
}

const TableBody: FunctionComponent<TableBodyProps> = ({ children }) => {
  return <tbody>{children}</tbody>;
};

export default TableBody;
