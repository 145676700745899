import _ from "lodash";
import { ProductLinkModel } from "modules/product-link/model";
import { ProductLinkEditorActions } from "./actions";

export interface ProductLinkEditorStateModel {
  // original images
  links: ProductLinkModel[];

  // images added or marked as removed
  addedLinks: ProductLinkModel[];
  removedLinks: ProductLinkModel[];
}

const initialState: ProductLinkEditorStateModel = {
  links: [],
  addedLinks: [],
  removedLinks: [],
};

export const productLinkEditorReducer = (
  state = initialState,
  action: any = {},
) => {
  switch (action.type) {
    case ProductLinkEditorActions.PLE_INIT:
      return {
        ...state,
      };

    case ProductLinkEditorActions.PLE_RESET:
      return {
        ...state,
        links: [],
        addedLinks: [],
        removedLinks: [],
      };

    case ProductLinkEditorActions.PLE_SET_ORIGINAL_LINKS:
      return { ...state, links: action.payload };

    case ProductLinkEditorActions.PLE_ADD_LINK:
      return {
        ...state,
        addedLinks: [...state.addedLinks, action.payload],
      };

    case ProductLinkEditorActions.PLE_UPDATE_ADDED_LINK:
      return {
        ...state,
        addedLinks: [...state.addedLinks].map((link) => {
          if (link.name === action.payload.originalName) {
            return {
              ...link,
              name: action.payload.name,
              url: action.payload.url,
            };
          }
          return link;
        }),
      };

    case ProductLinkEditorActions.PLE_UPDATE_INITIAL_LINK:
      return {
        ...state,
        links: [...state.links].map((link) => {
          if (link.uuid === action.payload.uuid) {
            return {
              ...link,
              name: action.payload.name,
              url: action.payload.url,
            };
          }
          return link;
        }),
      };

    case ProductLinkEditorActions.PLE_REMOVE_ADDED_LINK:
      // needed variable
      const name = action.payload;
      const { addedLinks } = state;

      // loop over addedImages, and remove the image which matches
      const updatedAddedLinks = _.filter(
        addedLinks,
        (value: ProductLinkModel) => {
          if (value.name !== name) return true;
        },
      );

      return {
        ...state,
        addedLinks: updatedAddedLinks,
      };

    case ProductLinkEditorActions.PLE_REMOVE_INITIAL_LINK:
      // needed variable
      const link = action.payload;
      const { removedLinks } = state;

      return {
        ...state,
        removedLinks: [...removedLinks, link],
      };

    default:
      return state;
  }
};
