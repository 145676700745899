export const env = process.env.NODE_ENV;
export const apiUrl = process.env.REACT_APP_API_URL;
export const imageUrl = process.env.REACT_APP_IMAGE_URL;

const config = {
  env,
  apiUrl,
  imageUrl,
};

export default config;
