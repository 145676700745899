import { css } from "styled-components";

export const sizes: any = {
  giant: 1170,
  desktop: 992,
  tablet: 768,
  mobile: 376,
};

export const centered = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export const fontCoda = () => css`
  font-family: "Coda", cursive;
  font-weight: 300 !important;
  text-transform: uppercase;
  letter-spacing: 0.02em;
`;

export const fontFira = () => css`
  font-size: 16px;
  font-family: "Fira Sans", sans-serif;
  font-weight: 300;
  letter-spacing: 0.02em;
`;

export const badgeDefaults = () => css`
  line-height: 100%;
  border: 1px solid;
  letter-spacing: 0.8px;

  margin-right: 0.4em;
  margin-top: 0.2em;
  margin-bottom: 0.2em;

  padding: 0.4em 0.8em;

  background: transparent;
  font-size: 12px;

  display: inline-block;
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0;
`;

export const hoverUnderline = () => css`
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

// magic
// https://github.com/styled-components/styled-components/blob/master/docs/tips-and-tricks.md
export const media = Object.keys(sizes).reduce((accumulator: any, label) => {
  // use em in breakpoints to work properly cross-browser and support users
  // changing their browsers font-size: https://zellwk.com/blog/media-query-units/
  const emSize = sizes[label] / 16;
  accumulator[label] = (args: any) => {
    return css`
      @media (max-width: ${emSize}em) {
        ${css(args)};
      }
    `;
  };
  return accumulator;
}, {});

const mixins = {
  fontCoda,
  fontFira,
  centered,
  sizes,
  media,
  badgeDefaults,
  hoverUnderline,
};

export default mixins;
