import axios from "axios";
import { generateUrl } from "components/image";
import Footer from "components/footer";
import PageTitle from "components/page-title";
import { apiUrl } from "config";
import _ from "lodash";
import { ProductImageModel } from "modules/product-image/model";
import { FunctionComponent, useEffect, useState } from "react";
import { useParams } from "react-router";
import { Col, Container, NavbarBrand, NavItem, NavLink, Row } from "reactstrap";
import ProductCard from "./product-card";
import {
  BackgroundMesh,
  Header,
  StyledNavbar,
  StyledSpacer,
  TimberLogoContainer,
} from "./public-collector.styles";
import imageTimberLogo from "images/timber-logo-120-60.png";
import FsLightbox from "fslightbox-react";
import Image from "components/image";

const PublicCollector: FunctionComponent<any> = () => {
  const { collectorLink } = useParams();
  const [collectorData, toggleCollectorData] = useState<any | undefined>(
    undefined
  );
  const [urls, toggleUrls] = useState<string[]>([]);
  const [lightboxOpen, toggleLightboxOpen] = useState(false);
  const [imageIndex, toggleImageIndex] = useState(0);

  useEffect(() => {
    const fetch = async () => {
      if (collectorLink) {
        const result = await axios.post(`${apiUrl}/public-collector`, {
          collectorLink,
        });

        const images: ProductImageModel[] = _.get(result, "data.images", []);
        const urls = images.map((image) => generateUrl(image.publicId));
        console.log(`Data from ${apiUrl}/public-collector}`, result.data);

        toggleCollectorData(result.data);
        toggleUrls(urls);
      }
    };
    fetch();
  }, [collectorLink]);

  if (!collectorData) {
    return null;
  }

  const { organizationLogo } = collectorData;
  const images: ProductImageModel[] = collectorData.images;

  return (
    <div>
      <Header>
        <StyledNavbar light expand="md">
          <NavbarBrand>
            <TimberLogoContainer>
              {organizationLogo ? (
                <Image publicId={organizationLogo} />
              ) : (
                <img src={imageTimberLogo} height="100%" alt="" />
              )}
            </TimberLogoContainer>
          </NavbarBrand>
          <NavItem tag="div">
            <NavLink>{"\u00A0"} </NavLink>
          </NavItem>
        </StyledNavbar>
      </Header>

      <StyledSpacer>
        <Container>
          <Row>
            <Col style={{ marginTop: 7, marginBottom: 7 }}>{"\u00A0"}</Col>
          </Row>
        </Container>
      </StyledSpacer>

      <BackgroundMesh>
        <Container>
          <PageTitle title="Shared images" />
          <Row style={{ marginLeft: -30, marginRight: -30 }}>
            {images.map((image, index) => (
              <Col key={image.uuid} xs="12" sm="6" md="4">
                {image.product && (
                  <ProductCard
                    image={image}
                    product={image.product}
                    onRemove={undefined}
                    onClick={() => {
                      toggleLightboxOpen(true);
                      toggleImageIndex(index);
                    }}
                  />
                )}
              </Col>
            ))}
          </Row>
          {urls[imageIndex] && lightboxOpen && (
            <FsLightbox
              openOnMount
              toggler={true}
              sources={[urls[imageIndex]]}
              onClose={() => {
                toggleLightboxOpen(false);
              }}
            />
          )}
        </Container>
      </BackgroundMesh>

      <Footer />
    </div>
  );
};

export default PublicCollector;
