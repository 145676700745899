import { FunctionComponent } from "react";

import { Row, Col } from "reactstrap";

interface PageElementProps {
  children: any;
}

const PageElement: FunctionComponent<PageElementProps> = ({ children }) => (
  <Row style={{ marginTop: 30 }}>
    <Col>{children}</Col>
  </Row>
);

export default PageElement;
