import { FunctionComponent } from "react";
import { ClipLoader } from "react-spinners";
import theme from "theme/theme";
import { Container } from "./loading.styles";

interface LoadingProps {
  color?: string;
}

const Loading: FunctionComponent<LoadingProps> = ({ color }) => {
  return (
    <Container>
      <ClipLoader color={color ? theme.color : theme.darkBlue} loading />
    </Container>
  );
};

export default Loading;
