import styled from "styled-components";
import mixins from "theme/mixins";

// styled-components
export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  ${mixins.media.tablet`
      flex-direction: column;
      justify-content: flex-end;
    `};
  margin-bottom: 15px;
  margin-top: 15px;
`;

export const LeftSide = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  & > div {
    margin-right: 10px;
  }
`;

export const RightSide = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  ${mixins.media.tablet`
      justify-content: flex-end;
    `};
`;
