import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { Desktop } from "components";
import IconAndText from "components/icon-and-text";
import PageTitle from "components/page-title";
import TableBody from "components/table/body";
import TableBodyCell from "components/table/body-cell";
import TableHead from "components/table/head";
import TableHeadCell from "components/table/head-cell";
import TableRow from "components/table/row";
import Table from "components/table/table";
import _ from "lodash";
import { useAppState } from "modules/app/hook";
import { organizationUsersLoad } from "modules/organization-user/actions";
import { useOrganizationUsersState } from "modules/organization-user/hook";
import { FunctionComponent, useEffect } from "react";
import { useNavigate } from "react-router";
import OrganizationUserEdit from "./user-edit";
import OrganizationUserGroup from "./user-group";
import OrganizationUserUnits from "./user-units";

const OrganizationUserListView: FunctionComponent<any> = () => {
  const navigate = useNavigate();
  const appState = useAppState();
  const organizationUsersState = useOrganizationUsersState();
  const { selectedOrganizationUuid, selectedOrganizationData } = appState;
  const { loadedUsers = [] } = organizationUsersState;

  useEffect(() => {
    if (selectedOrganizationUuid) {
      organizationUsersLoad(selectedOrganizationUuid);
    }
  }, [selectedOrganizationUuid]);

  if (selectedOrganizationUuid === null || selectedOrganizationData === null) {
    return null;
  }

  return (
    <div>
      <PageTitle
        title="Organization Users"
        rightText="Edit users and invite new ones"
      />
      <Table>
        <TableHead>
          <TableRow>
            <TableHeadCell textAlign="center">#</TableHeadCell>

            <Desktop>
              <TableHeadCell>LAST NAME</TableHeadCell>
            </Desktop>

            <Desktop>
              <TableHeadCell>FIRST NAME</TableHeadCell>
            </Desktop>

            <TableHeadCell>EMAIL</TableHeadCell>

            <Desktop>
              <TableHeadCell textAlign="left">GROUP</TableHeadCell>
            </Desktop>

            <TableHeadCell textAlign="left">UNITS</TableHeadCell>

            <TableHeadCell textAlign="center">EDIT</TableHeadCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {_.map(loadedUsers, (user, userIndex) => (
            <TableRow key={user.uuid}>
              <TableBodyCell textAlign="center">{userIndex + 1}</TableBodyCell>

              <Desktop>
                <TableBodyCell textAlign="left">{user.firstName}</TableBodyCell>
              </Desktop>

              <Desktop>
                <TableBodyCell textAlign="left">{user.lastName}</TableBodyCell>
              </Desktop>

              <TableBodyCell textAlign="left">{user.email}</TableBodyCell>

              <Desktop>
                <OrganizationUserGroup
                  organization={selectedOrganizationData}
                  user={user}
                />
              </Desktop>

              <Desktop>
                <OrganizationUserUnits
                  organization={selectedOrganizationData}
                  user={user}
                />
              </Desktop>

              <OrganizationUserEdit
                organization={selectedOrganizationData}
                user={user}
              />
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <div style={{ marginTop: 20 }}>
        <IconAndText icon={faAdd} onClick={() => navigate("/add-user")}>
          Add user manually
        </IconAndText>
      </div>
    </div>
  );
};

export default OrganizationUserListView;
