import { DropdownToggle } from "reactstrap";
import styled from "styled-components";

export const StyledDropdownToggle = styled(DropdownToggle)`
  && {
    text-align: left;
  }
  cursor: pointer;
  width: 100%;
  ${(props) => props.customstyle};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const StyledTitle = styled.div`
  margin-top: 2px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const StyledCaret = styled.div`
  font-size: 120%;
  padding-left: 8px;
`;
