import { FunctionComponent, useState } from "react";
import { Dropdown, DropdownMenu, DropdownItem } from "reactstrap";
import {
  StyledCaret,
  StyledDropdownToggle,
  StyledTitle,
} from "./navigation-dropdown.styles";
import Icon from "./icon";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

export interface ItemModel {
  text: string;
  onClick: () => void;
}

interface NavigationDropdownProps {
  title: string;
  items: ItemModel[];
  className?: string;
  customToggleStyles?: any;
}

const NavigationDropdown: FunctionComponent<NavigationDropdownProps> = ({
  title,
  items,
  className,
  customToggleStyles,
}) => {
  const [open, toggleOpen] = useState(false);

  const handleOnChange = (item: ItemModel) => {
    // toggle internal
    toggleOpen(false);

    // delegate
    item.onClick();
  };

  return (
    <Dropdown
      className={className}
      isOpen={open}
      size="sm"
      toggle={() => toggleOpen(!open)}
    >
      <StyledDropdownToggle customstyle={customToggleStyles} tag="span">
        <StyledTitle>{title}</StyledTitle>
        <StyledCaret>
          <Icon icon={faChevronDown} />
        </StyledCaret>
      </StyledDropdownToggle>
      <DropdownMenu>
        {items.map((item) => {
          return (
            <DropdownItem key={item.text} onClick={() => handleOnChange(item)}>
              {item.text}
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    </Dropdown>
  );
};

export default NavigationDropdown;
