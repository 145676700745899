import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 23px;
  height: 23px;
  border: 1px solid ${(props) => props.theme.darkBlue};

  padding: 2px;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
`;

export const InnerBox = styled.div<any>`
  ${(props) =>
    props.checked &&
    css`
      background-color: ${props.theme.darkBlue};
      width: 100%;
      height: 100%;
    `};
`;
