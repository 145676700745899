import { ProductLinkModel } from "modules/product-link/model";
import { store } from "redux/store";

export enum ProductLinkEditorActions {
  PLE_INIT = "PLE_INIT",
  PLE_RESET = "PLE_RESET",
  PLE_SET_ORIGINAL_LINKS = "PLE_SET_ORIGINAL_LINKS",
  PLE_ADD_LINK = "PLE_ADD_LINK",
  PLE_UPDATE_ADDED_LINK = "PLE_UPDATE_ADDED_LINK",
  PLE_UPDATE_INITIAL_LINK = "PLE_UPDATE_INITIAL_LINK",
  PLE_REMOVE_ADDED_LINK = "PLE_REMOVE_ADDED_LINK",
  PLE_REMOVE_INITIAL_LINK = "PLE_REMOVE_INITIAL_LINK",
}

export const reset = () => {
  store.dispatch({
    type: ProductLinkEditorActions.PLE_RESET,
  });
};

export const setOriginalLinks = (links: ProductLinkModel[]) => {
  store.dispatch({
    type: ProductLinkEditorActions.PLE_SET_ORIGINAL_LINKS,
    payload: links,
  });
};

export const addLink = (link: ProductLinkModel) => {
  // file is: { identifier, file, progress}
  store.dispatch({
    type: ProductLinkEditorActions.PLE_ADD_LINK,
    payload: link,
  });
};

export const updateAddedLink = (
  originalName: string,
  name: string,
  url: string,
) => {
  store.dispatch({
    type: ProductLinkEditorActions.PLE_UPDATE_ADDED_LINK,
    payload: { originalName, name, url },
  });
};

export const updateInitialLink = (uuid: string, name: string, url: string) => {
  store.dispatch({
    type: ProductLinkEditorActions.PLE_UPDATE_INITIAL_LINK,
    payload: { uuid, name, url },
  });
};

export const removeAddedLink = (name: string) => {
  store.dispatch({
    type: ProductLinkEditorActions.PLE_REMOVE_ADDED_LINK,
    payload: name,
  });
};

export const removeInitialLink = (uuid: string) => {
  store.dispatch({
    type: ProductLinkEditorActions.PLE_REMOVE_INITIAL_LINK,
    payload: uuid,
  });
};
