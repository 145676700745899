import styled from "styled-components";
import mixins from "theme/mixins";

export const RoundedInitialsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const RoundedInitials = styled.div`
  ${mixins.fontFira()} display: flex;
  justify-content: center;
  align-items: center;
  font-size: 280%;
  background-color: ${(props) => props.theme.gray300};
  border-radius: 50%;
  width: 100px;
  height: 100px;
`;
