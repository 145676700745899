import { FunctionComponent } from "react";

interface TableHeadProps {
  children: any;
}

const TableHead: FunctionComponent<TableHeadProps> = ({ children }) => {
  return <thead>{children}</thead>;
};

export default TableHead;
