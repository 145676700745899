import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FunctionComponent, useState } from "react";
import {
    Cancel,
  InputContainer,
  Save,
  SaveAndCancel,
  StyledReactstrapInput,
  TextContainer,
} from "./add-input.styles";
import IconAndText from "./icon-and-text";

interface AddInputProps {
  text: string;
  placeholder: string;
  onSave: (value: string) => void;
}

const AddInput: FunctionComponent<AddInputProps> = ({
  text,
  placeholder,
  onSave,
}) => {
  const [value, toggleValue] = useState("");
  const [editing, toggleEditing] = useState(false);

  const renderText = () => {
    return (
      <TextContainer>
        <IconAndText icon={faPlus} onClick={() => toggleEditing(true)}>
          {text}
        </IconAndText>
      </TextContainer>
    );
  };

  const handleCancel = () => {
    toggleEditing(false);
    toggleValue("");
  };

  const handleSave = () => {
    onSave(value);
    toggleEditing(false);
    toggleValue("");
  };

  const renderInput = () => {
    return (
      <InputContainer>
        <StyledReactstrapInput
          type="text"
          value={value}
          placeholder={placeholder}
          onChange={(e: any) => toggleValue(e.target.value)}
          onKeyPress={(event: any) => {
            if (event.key === "Enter") {
              handleSave();
            } else if (event.key === "Esc") {
              handleCancel();
            }
          }}
        />
        <SaveAndCancel>
          <Save onClick={handleSave}>Save</Save>
          <Cancel onClick={handleCancel}>Cancel</Cancel>
        </SaveAndCancel>
      </InputContainer>
    );
  };

  if (editing) {
    return renderInput();
  }
  return renderText();
};

export default AddInput;
