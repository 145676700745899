import { FunctionComponent, useState } from "react";
import IconAndText from "./icon-and-text";
import {
  Cancel,
  InputContainer,
  Save,
  SaveAndCancel,
  StyledReactstrapInput,
  TextContainer,
} from "./edit-input.styles";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";

interface EditInputProps {
  value: string;
  text: string;
  placeholder: string;
  onSave: (value: string) => void;
}

const EditInput: FunctionComponent<EditInputProps> = ({
  value,
  text,
  placeholder,
  onSave,
}) => {
  const [editing, toggleEditing] = useState(false);
  const [internalValue, toggleInternalValue] = useState(value);

  const handleCancel = () => {
    toggleEditing(false);
    toggleInternalValue(value); // revert to original value
  };

  const handleSave = () => {
    toggleEditing(false);
    onSave(internalValue);
  };

  const onChange = (e: any) => {
    toggleInternalValue(e.target.value);
  };

  const renderText = () => {
    return (
      <TextContainer>
        {value}
        <IconAndText icon={faPencilAlt} onClick={() => toggleEditing(true)}>
          {text}
        </IconAndText>
      </TextContainer>
    );
  };

  const renderInput = () => {
    return (
      <InputContainer>
        <StyledReactstrapInput
          type="text"
          value={internalValue}
          placeholder={placeholder}
          onChange={(e: any) => onChange(e)}
          onKeyPress={(event: any) => {
            if (event.key === "Enter") {
              handleSave();
            } else if (event.key === "Esc") {
              handleCancel();
            }
          }}
        />
        <SaveAndCancel>
          <Save onClick={() => handleSave()}>Save</Save>
          <Cancel onClick={() => handleCancel()}>Cancel</Cancel>
        </SaveAndCancel>
      </InputContainer>
    );
  };

  if (editing === false) {
    return renderText();
  }
  return renderInput();
};

export default EditInput;
