import { faEdit } from "@fortawesome/free-solid-svg-icons";
import BigButton from "components/big-button";
import Form from "components/form";
import Input from "components/input";
import Label from "components/label";
import TableActionCell from "components/table/action-cell";
import { FunctionComponent, useState } from "react";
import { Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";

interface LinkEdit {
  pristine?: boolean;
  name: string;
  url: string;
  onSubmit: (name: string, url: string) => void;
}

interface FormModel {
  name: string;
  url: string;
}

const LinkEdit: FunctionComponent<LinkEdit> = ({
  pristine = false,
  name,
  url,
  onSubmit,
}) => {
  const [open, toggleOpen] = useState(false);
  const [form, toggleForm] = useState<FormModel>({
    name,
    url,
  });

  const handleSubmit = async () => {
    toggleModal();
    onSubmit(form.name, form.url);
  };

  const toggleModal = () => {
    toggleOpen(!open);
  };

  return (
    <TableActionCell
      icon={faEdit}
      onClick={() => toggleOpen(true)}
      style={{ borderRight: 0 }}
      header
    >
      <Modal size="lg" isOpen={open} toggle={() => toggleModal()}>
        <ModalHeader toggle={toggleModal}>Edit Attribute Group</ModalHeader>
        <ModalBody>
          <Form>
            <Row>
              <Col>
                <Label>Name:</Label>
                <Input
                  value={form.name}
                  required
                  placeholder="Link name"
                  type="text"
                  onChange={(name) => toggleForm({ ...form, name })}
                />
              </Col>

              <Col>
                <Label>Url:</Label>
                <Input
                  value={form.url}
                  required
                  placeholder="Link url"
                  type="text"
                  onChange={(url) => toggleForm({ ...form, url })}
                />
              </Col>
            </Row>

            <Row style={{ marginTop: 20 }}>
              <Col>
                <BigButton
                  onClick={() => {
                    toggleModal();
                  }}
                >
                  Cancel
                </BigButton>
              </Col>
              <Col>
                <BigButton
                  disabled={pristine}
                  type="submit"
                  onClick={() => handleSubmit()}
                >
                  Save changes
                </BigButton>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </TableActionCell>
  );
};

export default LinkEdit;
