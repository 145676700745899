import {
  errorNotification,
  successNotification,
} from "modules/notifications/actions";
import { UserDataModel } from "modules/user/models";
import { NavigateFunction } from "react-router";
import { store } from "redux/store";

export enum OrganizationUsersActions {
  ORGANIZATION_USERS_LOAD = "ORGANIZATION_USERS_LOAD",
  ORGANIZATION_USERS_LOAD_SUCCESS = "ORGANIZATION_USERS_LOAD_SUCCESS",
  ORGANIZATION_USERS_LOAD_FAILURE = "ORGANIZATION_USERS_LOAD_FAILURE",

  ORGANIZATION_USER_CHANGE_GROUP = "ORGANIZATION_USER_CHANGE_GROUP",

  ORGANIZATION_USER_MANUAL_ADD = "ORGANIZATION_USER_MANUAL_ADD",

  ORGANIZATION_USER_TOGGLE_UNIT = "ORGANIZATION_USER_TOGGLE_UNIT",
  ORGANIZATION_USER_EDIT = "ORGANIZATION_USER_EDIT",

  ORGANIZATION_USER_DELETE = "ORGANIZATION_USER_DELETE",
}

export const organizationUsersLoad = (organizationUuid: string) => {
  store.dispatch({
    type: OrganizationUsersActions.ORGANIZATION_USERS_LOAD,
    apiCall: {
      endpoint: `/organizations/${organizationUuid}`,
      method: "get",
    },
  });
};

export const userChangeGroup = (
  organizationUuid: string,
  userUuid: string,
  groupUuid: string
) => {
  const payload = {
    userUuid,
    groupUuid,
  };

  store.dispatch({
    type: OrganizationUsersActions.ORGANIZATION_USER_CHANGE_GROUP,
    apiCall: {
      endpoint: `/users/change-group`,
      method: "post",
      payload,
      success: () => {
        successNotification("Users group changed succesfully!");
        organizationUsersLoad(organizationUuid);
      },
    },
  });
};

export const userToggleUnits = (
  organizationUuid: string,
  userUuid: string,
  unitUuids: string[]
) => {
  const payload = {
    userUuid,
    unitUuids,
  };

  store.dispatch({
    type: OrganizationUsersActions.ORGANIZATION_USER_TOGGLE_UNIT,
    apiCall: {
      endpoint: `/users/toggle-units`,
      method: "post",
      payload,
      success: () => {
        successNotification("Users units info changed succesfully!");
        organizationUsersLoad(organizationUuid);
      },
      failure: () =>
        errorNotification(
          "User modification error, no further details, sorry."
        ),
    },
  });
};

export const organizationUserUpdate = (
  organizationUuid: string,
  userUuid: string,
  params: any
) => {
  // building the final payload (groupId, email, names)
  const payload = params;
  payload.status = "active";
  payload.organizationUuid = organizationUuid;

  store.dispatch({
    type: OrganizationUsersActions.ORGANIZATION_USER_EDIT,
    apiCall: {
      endpoint: `/users/${userUuid}`,
      payload,
      method: "patch",
      success: () => {
        successNotification("User updated succesfully");
        organizationUsersLoad(organizationUuid);
      },
      failure: () =>
        errorNotification("User update error, something went wrong..."),
    },
  });
};

export const organizationUserManualAdd = (
  organizationUuid: string,
  params: Partial<UserDataModel>,
  navigate: NavigateFunction
) => {
  // building the final payload (groupId, email, names)
  const payload = params;
  payload.status = "active";
  payload.organizationUuid = organizationUuid;

  store.dispatch({
    type: OrganizationUsersActions.ORGANIZATION_USER_MANUAL_ADD,
    apiCall: {
      endpoint: `/users`,
      payload,
      method: "post",
      success: () => {
        successNotification("User created succesfully");
        navigate("/users");
      },
      failure: () =>
        errorNotification("User create error, something went wrong..."),
    },
  });
};

export const removeUserFromOrganization = (
  organizationUuid: string,
  userUuid: string
) => {
  // needed constants

  const payload: any = {};
  payload.userUuid = userUuid;
  payload.organizationUuid = organizationUuid;

  store.dispatch({
    type: OrganizationUsersActions.ORGANIZATION_USER_MANUAL_ADD,
    apiCall: {
      endpoint: `/users/remove-from-organization`,
      payload,
      method: "post",
      success: () => {
        successNotification("User removed succesfully");
        organizationUsersLoad(organizationUuid);
      },
      failure: () =>
        errorNotification("User removal failure, something went wrong..."),
    },
  });
};

export const deleteUser = (organizationUuid: string, userUuid: string) => {
  store.dispatch({
    type: OrganizationUsersActions.ORGANIZATION_USER_DELETE,
    apiCall: {
      endpoint: `/users/${userUuid}`,
      method: "delete",
      payload: {},
      success: () => {
        successNotification("User deleted succesfully");
        organizationUsersLoad(organizationUuid);
      },
      failure: () => {},
    },
  });
};
