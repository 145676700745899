import styled from "styled-components";
import mixins from "theme/mixins";

export const ImageContainer = styled.div`
  border: 1px solid #eee;
`;

export const ChooseImageContainer = styled.div`
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  cursor: pointer;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: center;

  &:last-child {
    border-bottom: none;
  }
`;

export const StyledImageWrapper = styled.div`
  position: relative;
`;

export const StyledImage = styled.img``;

export const StyledNumber = styled.div`
  ${mixins.fontCoda()};
  font-size: 90%;
  position: absolute;
  bottom: 11px;
  left: 11px;
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 11px;
  padding-bottom: 11px;
  background-color: ${(props) => props.theme.lightBlue};
  color: ${(props) => props.theme.white};
`;

export const StyledDownload = styled.div`
  color: white;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0px;
  left: 0px;
  height: 45px;
  padding-left: 20px;
  padding-right: 20px;
  z-index: 9999999999;
  cursor: pointer;
`;
