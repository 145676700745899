import {
  addNotification,
  NotificationType,
} from "modules/notifications/actions";
import { store } from "redux/store";
import { loadMyUser } from "modules/user/actions";
import { NavigateFunction } from "react-router";

export enum FilterActions {
  // Actions
  FILTER_ADD_ATTRIBUTE = "FILTER_ADD_ATTRIBUTE",
  FILTER_REMOVE_ATTRIBUTE = "FILTER_REMOVE_ATTRIBUTE",

  FILTER_CLEAR = "FILTER_CLEAR",
  FILTER_HIDE = "FILTER_HIDE",
  FILTER_SHOW = "FILTER_SHOW",

  SHOW_FILTER_SETTINGS = "SHOW_FILTER_SETTINGS",
  HIDE_FILTER_SETTINGS = "HIDE_FILTER_SETTINGS",
  TOGGLE_FILTER_SETTINGS = "TOGGLE_FILTER_SETTINGS",

  CHANGE_FILTERING_METHOD = "CHANGE_FILTERING_METHOD",
}

// Action Creators
export const showSettings = () =>
  store.dispatch({
    type: FilterActions.SHOW_FILTER_SETTINGS,
  });

export const hideSettings = () =>
  store.dispatch({
    type: FilterActions.HIDE_FILTER_SETTINGS,
  });

export const toggleSettings = () =>
  store.dispatch({
    type: FilterActions.TOGGLE_FILTER_SETTINGS,
  });

export const filterAddAttribute = (uuid: string) =>
  store.dispatch({
    type: FilterActions.FILTER_ADD_ATTRIBUTE,
    payload: uuid,
  });

export const changeFilteringMethod = (
  newFilteringMethod: any,
  navigate: NavigateFunction
) => {
  const payload = {
    filteringMethod: newFilteringMethod,
  };

  store.dispatch({
    type: FilterActions.CHANGE_FILTERING_METHOD,
    apiCall: {
      endpoint: `/my/user`,
      method: "patch",
      payload,
      success: () => {
        addNotification(NotificationType.SUCCESS, "Filtering method changed!");
        loadMyUser(navigate);
        hideSettings();
      },
    },
  });
};

export const filterRemoveAttribute = (uuid: string) =>
  store.dispatch({
    type: FilterActions.FILTER_REMOVE_ATTRIBUTE,
    payload: uuid,
  });

export const filterClear = () =>
  store.dispatch({
    type: FilterActions.FILTER_CLEAR,
  });

export const filterShow = () =>
  store.dispatch({
    type: FilterActions.FILTER_SHOW,
  });

export const filterHide = () =>
  store.dispatch({
    type: FilterActions.FILTER_HIDE,
  });
