import { FunctionComponent, useEffect, useState } from "react";

// images
import emptyImage from "images/no-image-330-230.png";
import { ProductImageModel } from "modules/product-image/model";
import { generateUrl } from "components/image";
import {
  CardHeader,
  CardImage,
  StyledCard,
  StyledImage,
  StyledNumber,
} from "./product-card.styles";
import { ProductModel } from "modules/product/model";
import DeleteIcon from "components/delete-icon";

interface ProductCardProps {
  image: ProductImageModel;
  product: ProductModel;
  onRemove?: () => void;
  onClick?: () => void;
}

const ProductCard: FunctionComponent<ProductCardProps> = ({
  image,
  product,
  onRemove,
  onClick,
}) => {
  const [url, toggleUrl] = useState<string | undefined>(undefined);

  useEffect(() => {
    loadImage();
  }, []);

  const loadImage = async () => {
    const url = generateUrl(image.publicId);
    toggleUrl(url);
  };

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <StyledCard onClick={() => handleClick()}>
      <CardHeader>
        <h4>{product.title}</h4>
        {onRemove && <DeleteIcon onClickAction={() => onRemove()} />}
      </CardHeader>

      <CardImage>
        <StyledImage src={url || emptyImage} />
        {image.number && <StyledNumber>#{image.number}</StyledNumber>}
      </CardImage>
    </StyledCard>
  );
};

export default ProductCard;
