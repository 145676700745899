import _ from "lodash";

// I don't like this :()
export const calculateNewSortingNumber = (
  destinationIndex: number,
  sourceIndex: number,
  targetArray: any[]
) => {
  // it it's first
  if (destinationIndex === 0) {
    return _.first(targetArray).sorting / 2;
  }

  // if it's last
  if (destinationIndex === targetArray.length - 1) {
    return _.last(targetArray).sorting + 9;
  }

  // somewhere between
  let finalSortingNumber;

  if (destinationIndex > sourceIndex) {
    const firstNumber = targetArray[destinationIndex].sorting;
    const nextNumber = targetArray[destinationIndex + 1].sorting;
    const numberBetween = (nextNumber - firstNumber) / 2;
    finalSortingNumber = firstNumber + numberBetween;
  } else {
    const firstNumber = targetArray[destinationIndex - 1].sorting;
    const nextNumber = targetArray[destinationIndex].sorting;
    const numberBetween = (nextNumber - firstNumber) / 2;
    finalSortingNumber = firstNumber + numberBetween;
  }

  return finalSortingNumber;
};

export const toggleItemInArray = (collection: any, item: any) => {
  const index = _.indexOf(collection, item);
  if (index !== -1) {
    return _.without(collection, item);
  }
  return [...collection, item];
};

export const findChangedPropertiesOfAnObject = (first: any, second: any) =>
  _.reduce(
    first,
    (result, value, key) => (_.isEqual(value, second[key]) ? result : result.concat(key as any)),
    []
  );

export const pickChangedValues = (first: any, second: any) => {
  const changedFields = findChangedPropertiesOfAnObject(first, second);

  return _.pickBy(first, (value, key) => _.includes(changedFields, key));
};
