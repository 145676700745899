import { FunctionComponent } from "react";
import _ from "lodash";
import { AttributeModel } from "modules/attribute/model";
import {
  BadgeGroup,
  Container,
  StyledBadge,
} from "./product-attributes.styles";

interface ProductAttributesProps {
  attributes: AttributeModel[];
}
const ProductAttributes: FunctionComponent<ProductAttributesProps> = ({
  attributes,
}) => {
  const groups: any = _.groupBy(attributes, "attributeGroup.uuid");
  return (
    <Container>
      {_.map(groups, (group, index) => (
        <BadgeGroup key={`productGroup-${index}`}>
          <h6>{group[0].attributeGroup.name}</h6>
          {_.map(group, (attribute) => (
            <StyledBadge key={attribute.uuid} color="primary">
              {attribute.name}
            </StyledBadge>
          ))}
        </BadgeGroup>
      ))}
    </Container>
  );
};

export default ProductAttributes;
