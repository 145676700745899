// images
import timberBackground from "images/Timber_Splash_Background_1920x1229.png";
import { Row } from "reactstrap";
import styled from "styled-components";

export const StyledRow = styled(Row)`
  padding-top: 15px;
`;

export const BlueBackground = styled.div`
  background: url(${timberBackground}) no-repeat center center fixed;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  min-height: 65.5px;
`;
