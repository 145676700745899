import _ from "lodash";
import { CollectorActions } from "./actions";

export interface CollectorStateModel {
  loading: boolean;
  products: any[];
  images: string[]; // bunch of uuids
  loadedImages: any[];
  collectorData: any;
  error: any | null;
}

// Initial state
const initialState: CollectorStateModel = {
  loading: false,
  products: [],
  images: [],
  loadedImages: [],
  collectorData: null,
  error: null, // not used
};

// Reducer
export const collectorReducer = (state = initialState, action: any = {}) => {
  switch (action.type) {
    case CollectorActions.COLLECTOR_ADD_PRODUCT:
      return {
        ...state,
        products: _.union(state.products, [action.payload]),
      };

    case CollectorActions.COLLECTOR_ADD_IMAGE:
      return {
        ...state,
        images: _.union(state.images, [action.payload]),
      };

    case CollectorActions.COLLECTOR_REMOVE_IMAGE: {
      return {
        ...state,
        images: _.without(state.images, action.payload),
      };
    }

    case CollectorActions.COLLECTOR_LOAD_IMAGES_SUCCESS:
      return {
        ...state,
        loadedImages: action.payload,
      };

    case CollectorActions.COLLECTOR_GENERATE_LINK_SUCCESS: {
      return {
        ...state,
        collectorData: action.payload,
      };
    }

    case CollectorActions.COLLECTOR_CLEAR:
      return {
        ...state,
        products: [],
        images: [],
        loadedImages: [],
        collectorData: null,
        loading: false,
        error: null,
      };

    case CollectorActions.COLLECTOR_CLEAR_LINK: {
      return {
        ...state,
        collectorData: null,
      };
    }

    default:
      return state;
  }
};
