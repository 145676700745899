import { Fragment, FunctionComponent } from "react";
import Label from "./label";
import { StyledSelect } from "./select.styles";

interface SelectProps {
  children: any;
  label?: string;
  value?: string;
  onChange: (value: string) => void;
}

const Select: FunctionComponent<SelectProps> = ({
  onChange,
  label,
  value,
  children,
}) => {
  return (
    <Fragment>
      {label && <Label>{label}</Label>}
      <StyledSelect
        style={{ height: 40 }}
        type="select"
        onChange={(event: any) => onChange(event.target.value)}
        value={value}
      >
        {children}
      </StyledSelect>
    </Fragment>
  );
};

export default Select;
