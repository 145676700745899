import { FunctionComponent } from "react";
import TableCell from "./cell";
import { CellTextAlignType, CellVerticalAlignType } from "./cell.styles";

interface TableHeadCellProps {
  children?: any;
  className?: string;
  textAlign?: CellTextAlignType;
  verticalAlign?: CellVerticalAlignType;
  fullWidth?: boolean;
  colSpan?: number;
  style?: any;
}

const TableHeadCell: FunctionComponent<TableHeadCellProps> = ({
  children,
  textAlign = "left",
  fullWidth = false,
  colSpan = 1,
  className,
  style,
  verticalAlign,
}) => {
  return (
    <TableCell
      header
      style={{ ...style }}
      className={className}
      colSpan={colSpan}
      fullWidth={fullWidth}
      textAlign={textAlign}
      verticalAlign={verticalAlign}
    >
      {children}
    </TableCell>
  );
};

export default TableHeadCell;
