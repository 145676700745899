import { FunctionComponent, useState } from "react";
import moment from "moment";
import DP from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

interface DatePickerProps {
  initialDate?: moment.Moment;
  onDateChange: (date: moment.Moment) => void;
}

const DatePicker: FunctionComponent<DatePickerProps> = ({
  initialDate = moment(),
  onDateChange,
}) => {
  const [date, toggleDate] = useState(initialDate);

  return (
    <div>
      <DP
        calendarStartDay={1}
        selected={date.toDate()}
        onChange={(date) => {
          if (date) {
            toggleDate(moment(date));
            onDateChange(moment(date));
          }
        }}
      />
    </div>
  );
};

export default DatePicker;
