import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FunctionComponent } from "react";
import { StyledContent, StyledIcon, StyledText } from "./action-cell.styles";
import TableBodyCell from "./body-cell";
import { CellTextAlignType } from "./cell.styles";
import TableHeadCell from "./head-cell";

interface TableActionCellProps {
  onClick: () => void;
  className?: string;
  icon: IconProp;
  text?: string;
  children?: any;
  style?: any;
  textAlign?: CellTextAlignType;
  header?: boolean;
}

const TableActionCell: FunctionComponent<TableActionCellProps> = ({
  onClick,
  className,
  icon,
  text,
  textAlign = "center",
  children,
  style,
  header = false,
}) => {
  const Component = header ? TableHeadCell : TableBodyCell;
  return (
    <Component className={className} textAlign={textAlign} style={style}>
      <StyledContent>
        <StyledIcon icon={icon} onClick={onClick} size="lg" />
        {text && <StyledText>{text}</StyledText>}
      </StyledContent>
      {children}
    </Component>
  );
};

export default TableActionCell;
