import _ from "lodash";
import axios from "axios";
import { getCookie } from "services/cookies";

import { apiUrl } from "../config";

// utils
import { getValueFromStore } from "redux/store";
import { log } from "utils/log-utils";
import { Middleware } from "redux";
import { RootState } from "./reducers";

const apiCallMiddleware: Middleware<unknown, RootState> =
  (store) => (next) => async (action) => {
    // if action has same symbol as id, then do the fetch here, and execute the
    // callbacks and dispatch the before and after types etc..

    if (!_.has(action, "apiCall")) return next(action);

    // dispatch default action, usually sets loading true or similar
    store.dispatch({
      type: action.type,
    });

    // inject token from cookie to the request.
    const token = getCookie() || "";

    const { apiCall } = action;

    // create the request
    const axiosConfig = {
      method: apiCall.method,
      url: apiUrl + apiCall.endpoint,
      data: apiCall.payload,
      headers: {
        "Content-Type": "application/json",
      } as any, // must type as any for now
    };

    // append organizationUuid to headers if one is present
    const organizationUuid = getValueFromStore("app.selectedOrganizationUuid");

    if (!_.isNil(organizationUuid)) {
      axiosConfig.headers.organizationUuid = organizationUuid;
    }

    if (!_.has(apiCall, "noAuth")) {
      axiosConfig.headers.Authorization = `${token}`;
    }

    try {
      const result = await axios(axiosConfig);

      // dispatch SUCCESS action with payload "result.data"
      store.dispatch({
        type: `${action.type}_SUCCESS`,
        payload: result.data,
      });

      // if success key is assigned, run defined function
      if (_.has(action, "apiCall.success")) {
        // pass only data back
        apiCall.success(result.data);
      }
    } catch (error) {
      console.error(error);
      const failurePayload = _.get(error, "response.data.error", {
        type: "unhandled_error",
      });

      log.info({ obj: failurePayload }, `${action.type}_FAILURE`);

      // dispatch the normal failure action, with error as a payload
      store.dispatch({
        type: `${action.type}_FAILURE`,
        payload: failurePayload,
      });

      // call the failure function define in apiCall
      if (_.has(action, "apiCall.failure")) {
        apiCall.failure(error);
      }
    }
  };

export default apiCallMiddleware;
