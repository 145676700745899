import { FunctionComponent } from "react";
import _ from "lodash";
import TableRow from "components/table/row";
import TableBodyCell from "components/table/body-cell";
import Table from "components/table/table";
import TableHead from "components/table/head";
import TableHeadCell from "components/table/head-cell";
import {
  attributeGroupCreate,
  attributeGroupDelete,
  attributeGroupUpdate,
} from "modules/attribute-group/actions";
import {
  attributeCreate,
  attributeDelete,
  attributeUpdate,
} from "modules/attribute/actions";
import { useAppState } from "modules/app/hook";
import PageTitle from "components/page-title";
import AddInput from "components/add-input";
import AttributeEdit from "modules/attribute/attribute-edit";
import AttributeGroupEdit from "modules/attribute-group/attribute-group-edit";
import AttributeDelete from "modules/attribute/attribute-delete";
import AttributeGroupDelete from "modules/attribute-group/attribute-group-delete";

const PageHeader = () => (
  <PageTitle
    title="Attributes"
    rightText="Add, edit and organize unit based product attributes and sort them into groups"
  />
);

// main class
const AttributeListView: FunctionComponent = () => {
  const appState = useAppState();
  const {
    selectedOrganizationUnitData,
    selectedOrganizationUnitUuid,
    selectedOrganizationUuid,
  } = appState;

  if (
    !selectedOrganizationUnitData ||
    !selectedOrganizationUnitUuid ||
    !selectedOrganizationUuid
  ) {
    return null;
  }

  const { attributeGroups } = selectedOrganizationUnitData;

  return (
    <div>
      <PageHeader />

      {_.map(attributeGroups, (attributeGroup) => {
        return (
          <Table key={attributeGroup.uuid} style={{ tableLayout: "auto" }}>
            <TableHead>
              <TableRow>
                <TableHeadCell
                  style={{ borderRight: 0 }}
                  colSpan={2}
                  fullWidth
                  textAlign="left"
                >
                  {attributeGroup.name} [sorting = {attributeGroup.sorting}]
                </TableHeadCell>

                <AttributeGroupEdit
                  name={attributeGroup.name}
                  sorting={attributeGroup.sorting}
                  type={attributeGroup.type}
                  onSubmit={(name, sorting, type) => {
                    attributeGroupUpdate(
                      selectedOrganizationUnitUuid,
                      attributeGroup.uuid,
                      {
                        name,
                        sorting,
                        type,
                      }
                    );
                  }}
                />

                <AttributeGroupDelete
                  name={attributeGroup.name}
                  onSubmit={() => {
                    attributeGroupDelete(
                      selectedOrganizationUnitUuid,
                      attributeGroup.uuid
                    );
                  }}
                />
              </TableRow>
            </TableHead>

            <tbody>
              {_.map(attributeGroup.attributes, (attribute, attributeIndex) => {
                return (
                  <tr key={attribute.uuid}>
                    <TableBodyCell
                      style={{ minWidth: 50 }}
                      textAlign="center"
                      verticalAlign="middle"
                    >
                      {attributeIndex + 1}
                    </TableBodyCell>

                    <TableBodyCell
                      textAlign="left"
                      fullWidth
                      verticalAlign="middle"
                    >
                      {attribute.name} [sorting = {attribute.sorting}]
                    </TableBodyCell>

                    <AttributeEdit
                      name={attribute.name}
                      sorting={attribute.sorting}
                      onSubmit={(name, sorting) => {
                        attributeUpdate(
                          selectedOrganizationUnitUuid,
                          attribute.uuid,
                          { name, sorting }
                        );
                      }}
                    />

                    <AttributeDelete
                      name={attribute.name}
                      onSubmit={() => {
                        attributeDelete(
                          selectedOrganizationUnitUuid,
                          attribute.uuid
                        );
                      }}
                    />
                  </tr>
                );
              })}

              <TableRow>
                {attributeGroup.attributes.length > 0 && (
                  <TableBodyCell textAlign="left" />
                )}

                <TableBodyCell colSpan={5} fullWidth textAlign="left">
                  <AddInput
                    text="Add new attribute"
                    placeholder="Enter a name for the new attribute"
                    onSave={(name) => {
                      attributeCreate(
                        selectedOrganizationUnitUuid,
                        attributeGroup.uuid,
                        {
                          name,
                        }
                      );
                    }}
                  />
                </TableBodyCell>
              </TableRow>
            </tbody>
          </Table>
        );
      })}

      <div style={{ marginTop: 20 }}>
        <AddInput
          text="Add new attribute group"
          placeholder="Enter a name for the new attribute group"
          onSave={(name) => {
            attributeGroupCreate(
              selectedOrganizationUuid,
              selectedOrganizationUnitUuid,
              {
                name,
              }
            );
          }}
        />
      </div>
    </div>
  );
};

export default AttributeListView;

console.warn(
  "TODO: luo poistolle omat komponentit, kuten muokkauksellekin on nyt"
);
