import { faEdit } from "@fortawesome/free-solid-svg-icons";
import BigButton from "components/big-button";
import Form from "components/form";
import Input from "components/input";
import Label from "components/label";
import TableActionCell from "components/table/action-cell";
import { FunctionComponent, useState } from "react";
import { Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";

interface OrganizationUnitEditProps {
  name: string;
  sorting: number;
  onSubmit: (name: string, sorting: number) => void;
}

interface FormModel {
  name: string;
  sorting: string;
}

const OrganizationUnitEdit: FunctionComponent<OrganizationUnitEditProps> = ({
  name,
  sorting,
  onSubmit,
}) => {
  const [open, toggleOpen] = useState(false);
  const [form, toggleForm] = useState<FormModel>({
    name,
    sorting: sorting.toString(),
  });

  const handleSubmit = async () => {
    toggleModal();
    onSubmit(form.name, parseFloat(form.sorting));
  };

  const toggleModal = () => {
    toggleOpen(!open);
  };

  return (
    <TableActionCell icon={faEdit} onClick={() => toggleOpen(true)}>
      <Modal size="lg" isOpen={open} toggle={() => toggleModal()}>
        <ModalHeader toggle={toggleModal}>Edit organization unit</ModalHeader>
        <ModalBody>
          <Form>
            <Row>
              <Col>
                <Label>Name:</Label>
                <Input
                  value={form.name}
                  required
                  placeholder="Organization unit name"
                  type="text"
                  onChange={(name) => toggleForm({ ...form, name })}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <Label>Sorting:</Label>
                <Input
                  value={form.sorting.toString()}
                  required
                  placeholder="Attribute Name"
                  type="text"
                  onChange={(sorting) => toggleForm({ ...form, sorting })}
                />
              </Col>
            </Row>

            <Row style={{ marginTop: 20 }}>
              <Col>
                <BigButton
                  onClick={() => {
                    toggleModal();
                  }}
                >
                  Cancel
                </BigButton>
              </Col>
              <Col>
                <BigButton onClick={() => handleSubmit()}>
                  Save changes
                </BigButton>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </TableActionCell>
  );
};

export default OrganizationUnitEdit;
