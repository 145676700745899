import { FunctionComponent } from "react";
import _ from "lodash";
import { AttributeGroupModel } from "modules/attribute-group/model";
import Table from "./table/table";
import TableBody from "./table/body";
import TableRow from "./table/row";
import { StyledTableBodyCell } from "./vertical-attribute-list.styles";
import TableBodyCell from "./table/body-cell";
import Badge from "./badge";

interface VerticalAttributeListProps {
  attributeGroups: AttributeGroupModel[];
  selectedAttributes: string[];
  onAttributeClick: (attributeUuid: string) => void;
  onAttributeAdd?: (attributeUuid: string) => void;
  onAttributeRemove?: (attributeUuid: string) => void;
}

const VerticalAttributeList: FunctionComponent<VerticalAttributeListProps> = ({
  attributeGroups,
  onAttributeClick,
  selectedAttributes,
  onAttributeAdd = () => {},
  onAttributeRemove = () => {},
}) => {
  return (
    <Table>
      <TableBody>
        {_.map(attributeGroups, (attributeGroup, dataIndex) => (
          <TableRow key={`row-${dataIndex}`}>
            <StyledTableBodyCell textAlign="left">
              {attributeGroup.name}
            </StyledTableBodyCell>
            <TableBodyCell>
              {/* loop through all of the attributes */}
              {_.map(attributeGroup.attributes, (attribute) => {
                if (!_.isNil(attribute.name)) {
                  const selected = _.includes(
                    selectedAttributes,
                    attribute.uuid
                  );
                  return (
                    <Badge
                      key={attribute.uuid}
                      color={selected ? "white" : "primary"}
                      backgroundColor={selected ? "orange" : ""}
                      onClick={() => {
                        onAttributeClick(attribute.uuid);
                        if (selected) {
                          onAttributeRemove(attribute.uuid);
                        } else {
                          onAttributeAdd(attribute.uuid);
                        }
                      }}
                    >
                      {attribute.name}
                    </Badge>
                  );
                }
              })}
            </TableBodyCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default VerticalAttributeList;
