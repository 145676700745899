import PageTitle from "components/page-title";
import { FunctionComponent, useState } from "react";
import { Nav, NavItem, NavLink, TabPane } from "reactstrap";
import OrganizationList from "./organizations-tab";
import { Container, StyledTabContent } from "./sysadmin-view.styles";
import SystemSettingsTab from "./system-settings-tab";

const SysadminView: FunctionComponent = () => {
  const [tab, toggleTab] = useState("1");

  const renderTabHeader = (title: string, id: string) => {
    return (
      <NavItem>
        <NavLink active={tab === id} onClick={() => toggleTab(id)}>
          {title}
        </NavLink>
      </NavItem>
    );
  };

  return (
    <div>
      <PageTitle title="Sysadmin" rightText="" />
      <Container>
        <Nav tabs>
          {renderTabHeader("Organizations", "1")}
          {renderTabHeader("System Settings", "2")}
        </Nav>

        <StyledTabContent activeTab={tab}>
          <TabPane tabId="1">
            <OrganizationList />
          </TabPane>
          <TabPane tabId="2">
            <SystemSettingsTab />
          </TabPane>
        </StyledTabContent>
      </Container>
    </div>
  );
};

export default SysadminView;
