import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import BigButton from "components/big-button";
import TableActionCell from "components/table/action-cell";
import { FunctionComponent, useState } from "react";
import { Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";

interface AttributeGroupDeleteProps {
  name: string;
  onSubmit: () => void;
}

const AttributeGroupDelete: FunctionComponent<AttributeGroupDeleteProps> = ({
  name,
  onSubmit,
}) => {
  const [open, toggleOpen] = useState(false);

  const handleSubmit = async () => {
    toggleModal();
    onSubmit();
  };

  const toggleModal = () => {
    toggleOpen(!open);
  };

  return (
    <TableActionCell
      icon={faTrashAlt}
      onClick={() => toggleOpen(true)}
      style={{ borderRight: 0 }}
      header
    >
      <Modal size="lg" isOpen={open} toggle={() => toggleModal()}>
        <ModalHeader toggle={toggleModal}>Edit Attribute Group</ModalHeader>
        <ModalBody>
          <form>
            <Row>
              <Col>
                Are you sure you want to delete attribute group: ${name}?
              </Col>
            </Row>

            <Row style={{ marginTop: 20 }}>
              <Col>
                <BigButton
                  onClick={() => {
                    toggleModal();
                  }}
                >
                  Cancel
                </BigButton>
              </Col>
              <Col>
                <BigButton type="submit" onClick={() => handleSubmit()}>
                  Delete
                </BigButton>
              </Col>
            </Row>
          </form>
        </ModalBody>
      </Modal>
    </TableActionCell>
  );
};

export default AttributeGroupDelete;
