import _ from "lodash";
import { refreshOrganizationUnitData } from "modules/app/actions";
import { successNotification } from "modules/notifications/actions";
import { store } from "redux/store";

export enum AttributeGroupActions {
  ATTRIBUTE_GROUP_CREATE = "ATTRIBUTE_GROUP_CREATE",
  ATTRIBUTE_GROUP_CREATE_SUCCESS = "ATTRIBUTE_GROUP_CREATE_SUCCESS",
  ATTRIBUTE_GROUP_CREATE_FAILURE = "ATTRIBUTE_GROUP_CREATE_FAILURE",

  ATTRIBUTE_GROUP_UPDATE = "ATTRIBUTES_UPDATE_ATTRIBUTE_GROUP",
  ATTRIBUTE_GROUP_UPDATE_SUCCESS = "ATTRIBUTE_GROUP_UPDATE_SUCCESS",
  ATTRIBUTE_GROUP_UPDATE_FAILURE = "ATTRIBUTE_GROUP_UPDATE_FAILURE",

  ATTRIBUTE_GROUP_DELETE = "ATTRIBUTE_GROUP_DELETE",
  ATTRIBUTE_GROUP_DELETE_SUCCESS = "ATTRIBUTE_GROUP_DELETE_SUCCESS",
  ATTRIBUTE_GROUP_DELETE_FAILURE = "ATTRIBUTE_GROUP_DELETE_FAILURE",
}

export const attributeGroupCreate = (
  organizationUuid: string,
  organizationUnitUuid: string,
  dataObject: any
) => {
  const payload = dataObject;
  // inject unitId into payload
  payload.organizationUnitUuid = _.toString(organizationUnitUuid);
  payload.organizationUuid = _.toString(organizationUuid);

  store.dispatch({
    type: AttributeGroupActions.ATTRIBUTE_GROUP_CREATE,
    apiCall: {
      endpoint: `/attribute-groups`,
      payload,
      method: "post",
      success: () => {
        refreshOrganizationUnitData(organizationUnitUuid);
      },
    },
  });
};

export const attributeGroupUpdate = (
  organizationUnitUuid: string,
  uuid: string,
  dataObject: any
) => {
  store.dispatch({
    type: AttributeGroupActions.ATTRIBUTE_GROUP_UPDATE,
    apiCall: {
      endpoint: `/attribute-groups/${uuid}`,
      payload: dataObject,
      method: "patch",
      success: () => {
        refreshOrganizationUnitData(organizationUnitUuid);
        successNotification("Attribute Group Updated!");
      },
    },
  });
};

export const attributeGroupDelete = (
  organizationUnitUuid: string,
  uuid: string
) => {
  store.dispatch({
    type: AttributeGroupActions.ATTRIBUTE_GROUP_DELETE,
    apiCall: {
      endpoint: `/attribute-groups/${uuid}`,
      method: "delete",
      success: () => {
        refreshOrganizationUnitData(organizationUnitUuid);
      },
    },
  });
};
