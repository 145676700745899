import Badge from "components/badge";
import styled from "styled-components";

export const Container = styled.div`
  h6 {
    padding-bottom: 0px;
  }
`;

export const BadgeGroup = styled.div`
  margin-bottom: 15px;
`;

export const StyledBadge = styled(Badge)`
  font-size: 80% !important;
`;
