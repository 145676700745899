import _ from "lodash";
import { legacy_createStore as createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "@redux-devtools/extension";
import { RootReducer } from "./reducers";
import { middlewares } from "./middlewares";

export const store = createStore(
  RootReducer,
  composeWithDevTools(
    applyMiddleware(...middlewares)
    // other store enhancers if any
  )
);

export const getStore = () => {
  return store;
};

export const getValueFromStore = (pathSelector: string) => {
  try {
    // uses lodash to get from nested tree
    return _.get(store.getState(), pathSelector);
  } catch (error) {
    throw new Error("kek");
  }
};
