import _ from "lodash";
import { useFilterState } from "modules/filter/hook";
import { useUserState } from "modules/user/hook";
import { FunctionComponent, useEffect, useState } from "react";
import { Container, Row } from "reactstrap";
import { productListLoad } from "./actions";
import { useProductListState } from "./hook";
import { MainContainer, StyledCol } from "./product-list-view.styles";
import { Waypoint } from "react-waypoint";
import ProductCard from "modules/product-view/product-card";
import { useAppState } from "modules/app/hook";

const ProductListView: FunctionComponent = () => {
  const [numberOfProductsShown, toggleNumberOfProductsShown] = useState(15);
  const userState = useUserState();
  const appState = useAppState();
  const { data: userData } = userState;
  const productListState = useProductListState();
  const { products = [] } = productListState;
  const filterState = useFilterState();
  const { selectedAttributes } = filterState;

  useEffect(() => {
    if (appState.selectedOrganizationUnitUuid) {
      productListLoad(appState.selectedOrganizationUnitUuid);
    }
  }, [appState.selectedOrganizationUnitUuid]);

  if (userData === null) {
    return null;
  }

  const filterProducts = () => {
    if (selectedAttributes.length > 0) {
      const filtered = products.filter((product) => {
        const attributes = product.attributes.map(
          (attribute) => attribute.uuid
        );

        if (userData.filteringMethod === "OR") {
          // One of selected filters matches products filters
          if (_.intersection(selectedAttributes, attributes).length > 0) {
            return true;
          }
        }
        // AND
        // All of the selected filters has to match the products filters
        if (_.difference(selectedAttributes, attributes).length === 0) {
          return true;
        }

        return false;
      });
      return filtered;
    }

    // no filters, return all
    return products;
  };

  const loadMoreProducts = () => {
    toggleNumberOfProductsShown(numberOfProductsShown + 9);
  };

  const filteredProducts = filterProducts().slice(0, numberOfProductsShown);
  return (
    <MainContainer>
      <Container>
        <Row>
          {filteredProducts.map((product) => (
            <StyledCol key={product.uuid} xs="12" sm="6" xl="4">
              <ProductCard key={product.updatedAt} product={product} />
            </StyledCol>
          ))}
        </Row>
      </Container>

      <Waypoint onEnter={() => loadMoreProducts()} onLeave={() => {}} />
    </MainContainer>
  );
};

export default ProductListView;
