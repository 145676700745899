import { FunctionComponent, useState } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  BeforeToggleText,
  Container,
  StyledIcon,
  ToggledIconText,
} from "./icon-and-text.styles";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";

// styles

interface IconAndTextProps {
  children: any;
  icon?: IconProp;
  toggleText?: string;
  color?: string;
  iconSize?: string;
  textSize?: string;
  style?: any;
  className?: string;
  toggle?: boolean;
  onClick: () => void;
}

const IconAndText: FunctionComponent<IconAndTextProps> = ({
  onClick,
  toggle = false,
  icon = faPencilAlt,
  children,
  toggleText,
  color = "darkBlue",
  iconSize = "sm",
  textSize = "lg",
  style,
  className,
}) => {
  const [toggled, toggleToggled] = useState(false);

  const handleClick = () => {
    if (toggle === false) {
      onClick();
      return;
    }

    if (toggled) {
      onClick();
      toggleToggled(false);
      return;
    }

    toggleToggled(true);
  };

  return (
    <Container
      onClick={() => handleClick()}
      role="button"
      tabIndex={0}
      onKeyPress={() => {}}
      style={style}
      className={className}
    >
      <StyledIcon noIconBorder icon={icon} size={iconSize} />

      {toggled ? (
        <ToggledIconText color={color} size={textSize}>
          {toggleText}
        </ToggledIconText>
      ) : (
        <BeforeToggleText color={color} size={textSize}>
          {children}
        </BeforeToggleText>
      )}
    </Container>
  );
};

export default IconAndText;
