import { Button } from "reactstrap";
import styled from "styled-components";

export const StyledButton = styled(Button)`
  width: 100%;
  margin-top: 20px;
`;

export const ChildrenContainer = styled.div`
  padding-top: 10px !important;
  padding-bottom: 10px !important;
`;
