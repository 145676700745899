import { ThemeProvider } from "styled-components";
import theme from "theme/theme";
import moment from "moment";
import { Provider } from "react-redux";
import { store } from "redux/store";
import Router from "routes/router";

moment.locale("en");

const App = function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Router />
      </ThemeProvider>
    </Provider>
  );
};

export default App;
