import { FunctionComponent } from "react";
import AttributeGroupMultiselect from "./attribute-group-multiselect";
import AttributeGroupSelect from "./attribute-group-select";
import AttributeGroupTags from "./attribute-group-tags";
import { AttributeGroupModel } from "./model";

interface AttributeGroupProps {
  group: AttributeGroupModel;
}

const AttributeGroup: FunctionComponent<AttributeGroupProps> = ({ group }) => {
  switch (group.type) {
    case "multiselect":
      return <AttributeGroupMultiselect group={group} />;

    case "select":
      return <AttributeGroupSelect group={group} />;

    case "tags":
      return <AttributeGroupTags group={group} />;

    default:
      return <p>Unkown AttributeGroup</p>;
  }
};

export default AttributeGroup;
