import { Fragment, FunctionComponent, useEffect } from "react";
import { useCollectorState } from "./hook";
import { clear, loadImages } from "./actions";
import PageTitle from "components/page-title";
import { StyledContainer, StyledIconAndText } from "./collector-view.styles";
import LinkShare from "./link-share";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const CollectorView: FunctionComponent = () => {
  const collectorState = useCollectorState();
  const { images = [] } = collectorState;

  useEffect(() => {
    if (images.length > 0) {
      loadImages();
    }
  }, []);

  const renderPageTitle = () => {
    if (images.length === 0) {
      return (
        <PageTitle
          title="Share images"
          rightText="Share your selected images"
        />
      );
    }

    return (
      <PageTitle
        title={`Share ${images.length} images`}
        rightText={
          <div>
            <div>Share your selected images</div>
            <StyledIconAndText icon={faTrash} onClick={() => clear()}>
              Clear images
            </StyledIconAndText>
          </div>
        }
      />
    );
  };

  return (
    <Fragment>
      {renderPageTitle()}
      <StyledContainer>
        <LinkShare />
      </StyledContainer>
    </Fragment>
  );
};
export default CollectorView;
