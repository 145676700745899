import Loading from "components/loading";
import _ from "lodash";
import { FunctionComponent } from "react";
import { UploadModel } from "./model";
import { Container } from "./upload.styles";

interface UploadProps {
  instance: UploadModel;
}

const Upload: FunctionComponent<UploadProps> = ({ instance }) => {
  const { progress } = instance;
  return (
    <Container>
      <div>{_.ceil(progress)} %</div>
      <Loading />
    </Container>
  );
};

export default Upload;
