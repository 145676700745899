import { FunctionComponent } from "react";
import { Form as RSForm } from "reactstrap";

interface FormProps {
  children: any;
}

const Form: FunctionComponent<FormProps> = ({ children }) => {
  const handleSubmit = (e: any) => {
    e.preventDefault();
  };

  return <RSForm onSubmit={handleSubmit}>{children}</RSForm>;
};

export default Form;
