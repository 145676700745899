import { Container } from "reactstrap";
import styled from "styled-components";
import mixins from "theme/mixins";

export const Wrapper = styled.div`
  background-color: ${(props) => props.theme.primary};
`;

export const StyledContainer = styled(Container)`
  ${mixins.fontCoda()};

  color: ${(props) => props.theme.white};
  font-size: 12px;
  padding-top: 7px;
  padding-bottom: 7px;
`;

export const DropdownWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export const DropdownElement = styled.div`
  border-radius: 5px;

  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;

  background-color: rgba(0, 0, 0, 0.2);
  ${mixins.media.tablet` width: 100%;`};
`;
