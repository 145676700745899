import { FunctionComponent, useState } from "react";
import { Modal, ModalHeader, ModalBody, Row, Col } from "reactstrap";
import _ from "lodash";

// ui
import { OrganizationGroupModel } from "modules/organization-group/model";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import TableActionCell from "components/table/action-cell";
import BigButton from "components/big-button";

interface OrganizationGroupPermissionsProps {
  group: OrganizationGroupModel;
}

const OrganizationGroupPermissions: FunctionComponent<
  OrganizationGroupPermissionsProps
> = ({ group }) => {
  const [modalOpen, toggleModelOpen] = useState(false);

  return (
    <TableActionCell
      icon={faSearch}
      onClick={() => toggleModelOpen(true)}
      textAlign="center"
    >
      <Modal
        size="lg"
        isOpen={modalOpen}
        toggle={() => toggleModelOpen(!modalOpen)}
      >
        <ModalHeader toggle={() => toggleModelOpen(!modalOpen)}>
          {/* {`${_.get(data, "firstName", "")}
           ${_.get(data, "lastName", "")} - Access Rights`} */}
          Permissions (temp)
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <p>
                {_.map(group.permissions, (permission) => (
                  <p key={permission.uuid}>{permission.type}</p>
                ))}
              </p>
            </Col>
            <Col>
              <BigButton onClick={() => toggleModelOpen(false)}>
                Close
              </BigButton>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </TableActionCell>
  );
};

export default OrganizationGroupPermissions;
