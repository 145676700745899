import { FunctionComponent, useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { useProductImageEditorState } from "modules/product-image-editor/hook";
import { toggleItemInArray } from "utils/array";
import PageTitle from "components/page-title";
import BigButton from "components/big-button";
import ProductImageEditor from "modules/product-image-editor/editor";
import VerticalAttributeList from "components/vertical-attribute-list";
import { useAppState } from "modules/app/hook";
import { productAdd } from "./actions";
import { useNavigate } from "react-router";
import Input from "components/input";
import Label from "components/label";
import Form from "components/form";
import { ProductModel } from "modules/product/model";
import { sendRequest } from "services/request";

interface FormModel {
  title: string;
  description: string;
}

const NewProductView: FunctionComponent<any> = () => {
  const navigate = useNavigate();
  const [form, toggleForm] = useState<FormModel>({
    title: "",
    description: "",
  });
  const appState = useAppState();
  const editorState = useProductImageEditorState();
  const [selectedAttributes, toggleSelectedAttributes] = useState<string[]>([]);
  const [products, toggleProducts] = useState<ProductModel[]>([]);

  const {
    selectedOrganizationUuid,
    selectedOrganizationUnitUuid,
    selectedOrganizationUnitData,
  } = appState;

  useEffect(() => {
    if (selectedOrganizationUnitUuid) {
      const fetch = async () => {
        const products = await sendRequest(
          "get",
          `/organization-units/${selectedOrganizationUnitUuid}/products`
        );
        toggleProducts(products);
      };
      fetch();
    }
  }, [selectedOrganizationUnitUuid]);

  if (
    !selectedOrganizationUuid ||
    !selectedOrganizationUnitUuid ||
    !selectedOrganizationUnitData
  ) {
    return null;
  }

  const identicalNameExists =
    products.find((p) => p.title === form.title) !== undefined;

  const handleSubmitForm = () => {
    const values: any = { ...form, selectedAttributes };

    // check name
    if (identicalNameExists) {
      return;
    }

    // find added images from the state: productImageEditor.addedImages
    const newImages = editorState.addedImages.map((image) => ({
      publicId: image.publicId,
      type: image.type,
      format: image.format,
    }));
    values.images = newImages;

    // dispatch the action
    productAdd(
      selectedOrganizationUuid,
      selectedOrganizationUnitUuid,
      values,
      navigate
    );
  };

  const toggleAttribute = (attributeId: string) => {
    toggleSelectedAttributes(
      toggleItemInArray(selectedAttributes, attributeId)
    );
  };

  return (
    <Form>
      <PageTitle title="New Product" />
      <Row>
        <Col sm="12" md="6">
          <Label>Product title</Label>
          {identicalNameExists && (
            <div style={{ color: "red" }}>
              A product with identical name already exists!
            </div>
          )}
          <Input
            placeholder="Enter product title here"
            value={form.title}
            onChange={(title) => toggleForm({ ...form, title })}
          />
        </Col>
        <Col sm="12" md="6">
          <Label>Product description</Label>
          <Input
            placeholder="Enter product description here"
            value={form.description}
            onChange={(description) => toggleForm({ ...form, description })}
          />
        </Col>
      </Row>
      <Row>
        <Col />
      </Row>

      <Row>
        <Col>
          <Label>Product images and files</Label>
          <ProductImageEditor originalImages={[]} />
        </Col>
      </Row>

      <Row>
        <Col>
          <Label>Attributes</Label>
          <VerticalAttributeList
            onAttributeClick={(attributeId) => toggleAttribute(attributeId)}
            attributeGroups={selectedOrganizationUnitData.attributeGroups}
            selectedAttributes={selectedAttributes}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <BigButton
            type="submit"
            onClick={() => handleSubmitForm()}
            disabled={identicalNameExists}
          >
            Add new product
          </BigButton>
        </Col>
      </Row>
    </Form>
  );
};

export default NewProductView;
