import { Mobile } from "components";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router";
import { Button, PlusButtonContainer } from "./plus-button.styles";

const PlusButton: FunctionComponent = () => {
  const navigate = useNavigate();
  return (
    <Mobile>
      <PlusButtonContainer>
        <Button
          onClick={() => {
            navigate("/new");
          }}
        >
          +
        </Button>
      </PlusButtonContainer>
    </Mobile>
  );
};

export default PlusButton;
