import { FunctionComponent } from "react";
import {
  CellTextAlignType,
  CellVerticalAlignType,
  StyledCellContentAligner,
  StyledTd,
  StyledTh,
} from "./cell.styles";

interface TableCellProps {
  className?: string;
  children: any;
  colSpan?: number;
  header?: boolean;
  textAlign?: CellTextAlignType;
  verticalAlign?: CellVerticalAlignType;
  fullWidth?: boolean;
  width?: any;
  style?: any;
}

const TableCell: FunctionComponent<TableCellProps> = ({
  className,
  header = false,
  children,
  colSpan,
  width,
  fullWidth,
  style,
  verticalAlign = "top",
  textAlign = "left",
}) => {
  if (header) {
    return (
      <StyledTh
        colSpan={colSpan}
        fullWidth={fullWidth}
        verticalAlign={verticalAlign}
        className={`${className}`}
        width={width}
        style={{ ...style }}
      >
        <StyledCellContentAligner
          textAlign={textAlign}
          verticalAlign={verticalAlign}
        >
          {children}
        </StyledCellContentAligner>
      </StyledTh>
    );
  }

  return (
    <StyledTd
      colSpan={colSpan}
      fullWidth={fullWidth}
      verticalAlign={verticalAlign}
      className={`${className} `}
      style={{ ...style }}
    >
      <StyledCellContentAligner
        textAlign={textAlign}
        verticalAlign={verticalAlign}
      >
        {children}
      </StyledCellContentAligner>{" "}
    </StyledTd>
  );
};

export default TableCell;
