import { FunctionComponent } from "react";
import { Row, Col } from "reactstrap";

// styles
import UnitDropdown from "./unit-dropdown";
import OrganizationDropdown from "./organization-dropdown";
import { StyledContainer, Wrapper } from "./unit-selection.styles";

const UnitSelection: FunctionComponent<any> = () => {
  return (
    <Wrapper>
      <StyledContainer>
        <Row>
          <Col>
            <OrganizationDropdown />
          </Col>
          <Col>
            <UnitDropdown />
          </Col>
        </Row>
      </StyledContainer>
    </Wrapper>
  );
};

export default UnitSelection;
