import { FunctionComponent } from "react";
import { Row } from "reactstrap";
import {
  StyledContainer,
  StyledDescriptionCol,
  StyledTitleCol,
} from "./page-title.styles";

interface PageTitleProps {
  title: string;
  rightText?: string | any;
}

const PageTitle: FunctionComponent<PageTitleProps> = ({ title, rightText }) => {
  return (
    <StyledContainer>
      <Row>
        <StyledTitleCol xs="12" md="6">
          {title}
        </StyledTitleCol>
        <StyledDescriptionCol xs="12" md="6">
          {rightText && rightText}
        </StyledDescriptionCol>
      </Row>
    </StyledContainer>
  );
};

export default PageTitle;
