import { FunctionComponent } from "react";
import { Container, InnerBox } from "./checkbox.styles";

interface CheckboxProps {
  checked: boolean;
  onClick: () => void;
  style?: any;
}

const Checkbox: FunctionComponent<CheckboxProps> = ({
  checked,
  onClick,
  style,
}) => {
  const handleClick = () => {
    onClick();
  };

  return (
    <Container
      style={{ ...style }}
      onClick={() => handleClick()}
      role="button"
      tabIndex={0}
      onKeyPress={() => {}}
    >
      <InnerBox checked={checked}>{"\u00A0"}</InnerBox>
    </Container>
  );
};

export default Checkbox;
