import styled from "styled-components";
import mixins from "theme/mixins";

export const StyledImage = styled.div`
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
`;

export const StyledNumber = styled.div`
  ${mixins.fontCoda()};
  font-size: 90%;
  position: absolute;
  bottom: 11px;
  left: 11px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: ${(props) => props.theme.lightBlue};
  color: ${(props) => props.theme.white};
`;
