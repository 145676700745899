import Icon from "components/icon";
import styled from "styled-components";

// styles
export const StyledIcon = styled(Icon)`
  cursor: pointer;
  display: block;

  color: ${(props) => props.theme.gray700};
`;

export const StyledContent = styled.div`
  display: flex;
`;

export const StyledText = styled.div`
  margin-left: 10px;
`;
