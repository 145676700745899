import axios from "axios";
import _ from "lodash";

import { getCookie } from "services/cookies";
import { log } from "utils/log-utils";
import { apiUrl } from "../config";

const requestConfig = {
  token: "",
  url: apiUrl,
};

export const sendRequest = async (
  method: string,
  path: string,
  data: any = null,
  fileupload = false
) => {
  const baseURL = requestConfig.url;

  requestConfig.token = getCookie();

  let contentType = "application/json";
  if (method === "PATCH") contentType = "application/json-patch+json";
  if (fileupload) contentType = "multipart/form-data";

  const axiosOptions = {
    method, // default
    url: path,
    baseURL,
    data,
    headers: {
      sourceOrigin: window.location.origin,
      Authorization: requestConfig.token,
      "Access-Control-Allow-Origin": "*",
      "Content-Type": contentType, // typical request uses: application/json
    },
  };

  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    const serverErrorResponse = _.get(error, "response.data", error);
    log.error(
      { obj: serverErrorResponse },
      "TimberService sendRequest error response: "
    );
  }
};
