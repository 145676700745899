import mixins from "./mixins";

const mainColors: any = {
  darkBlue: "rgb(13, 103, 151)",
  heavyDarkBlue: "#094768",
  lightBlue: "#006699",
  orange: "#ed9c1a",
  brown: "#555555",
  white: "#fff",
  black: "#000",
  blue: "#2C3E50",
  indigo: "#6610f2",
  purple: "#6f42c1",
  pink: "#e83e8c",
  red: "#990000",
  redAlert: "#990000",
  yellow: "#F39C12",
  green: "#349966",
  teal: "#20c997",
  cyan: "#3498DB",
  grayBackground: "#fafafa",
  grayBorder: "#eeeeee",
  whatsappGreen: "#25D366",
};

mainColors.primary = mainColors.darkBlue;
mainColors.secondary = mainColors.white;

const grayShades = {
  gray100: "#f8f9fa",
  gray200: "#ecf0f1",
  gray300: "#dee2e6",
  gray400: "#ced4da",
  gray500: "#b4bcc2",
  gray600: "#95a5a6",
  gray700: "#7b8a8b",
  gray800: "#343a40",
  gray900: "#212529",
};

const theme = {
  ...mainColors,
  ...grayShades,
  primary: mainColors.darkBlue,
  secondary: mainColors.lightBlue,
  success: mainColors.green,
  info: mainColors.cyan,
  warning: mainColors.yellow,
  danger: mainColors.red,
  light: mainColors.gray200,
  dark: mainColors.gray700,
};

theme.mixins = mixins;

export default theme;
