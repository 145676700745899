import { FunctionComponent } from "react";

interface AttributeGroupSelectProps {
  group: any;
}

const AttributeGroupSelect: FunctionComponent<AttributeGroupSelectProps> = ({
  group,
}) => {
  return (
    <div>
      <p>{group.name}</p>
    </div>
  );
};

export default AttributeGroupSelect;
