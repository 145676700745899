import { FunctionComponent } from "react";

// ui
import { InputContainer, StyledContainer, StyledInput } from "./input.styles";
import InputIcon from "./input-icon";
import { useHover } from "utils/hover-hook";

interface InputProps {
  type?: string;
  name?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  noHoverIcon?: boolean;
  noBottomMargin?: boolean;
  className?: string;
  autoComplete?: string;

  // value control
  value: string;
  onChange?: (value: string) => Promise<void> | void;
}

const Input: FunctionComponent<InputProps> = ({
  type = "text",
  name,
  className,
  placeholder,
  required = false,
  noBottomMargin = false,
  noHoverIcon = false,
  disabled = false,
  readOnly = false,
  value = "",
  autoComplete,
  onChange = () => {},
}) => {
  const [hoverRef, isHovering] = useHover();

  const handleChange = (e: any) => {
    onChange(e.target.value);
  };

  return (
    <StyledContainer ref={hoverRef} noBottomMargin={noBottomMargin}>
      <InputContainer>
        <StyledInput
          className={className}
          type={type}
          name={name}
          required={required}
          readOnly={readOnly}
          disabled={disabled}
          placeholder={placeholder}
          autoComplete={autoComplete}
          value={value}
          onChange={(e: any) => handleChange(e)}
        />
        {!noHoverIcon && <InputIcon disabled={!isHovering} />}
      </InputContainer>
    </StyledContainer>
  );
};

export default Input;
