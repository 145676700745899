import { Navbar } from "reactstrap";
import styled from "styled-components";
import mixins from "theme/mixins";
import backgroundMesh from "images/timber-background-mesh_03.png";

export const BackgroundMesh = styled.div`
  background-image: url(${backgroundMesh});
  background-repeat: repeat-x;
`;

export const Header = styled.div`
  ${mixins.fontFira()};
  background-color: ${(props) => props.theme.white};
  a {
    font-weight: 300;
  }
`;

export const StyledNavbar = styled(Navbar)`
  padding-top: 10px !important;
  padding-bottom: 10px !important;
`;

export const TimberLogoContainer = styled.div`
  position: absolute;
  left: 30px;
  top: 0px;
  height: 60px;
  width: 120px;
  img {
    vertical-align: inherit;
  }
  div {
    vertical-align: inherit;
  }
`;

export const StyledSpacer = styled.div`
  background-color: ${(props) => props.theme.primary};
`;
