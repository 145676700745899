import _ from "lodash";
import { errorNotification } from "modules/notifications/actions";
import { FunctionComponent, useEffect, useState } from "react";
import { log } from "utils/log-utils";
import {
  addImage,
  imageUploadComplete,
  removeAddedImage,
  removeInitialImage,
  reset,
  setOriginalImages,
  updateUploadProgress,
} from "./actions";
import {
  Container,
  EmptyContainer,
  ImageContainer,
  SingleImageContainer,
  StyledDropzone,
} from "./editor.styles";
import { useProductImageEditorState } from "./hook";
import request from "superagent";
import SingleImage from "./image";
import Upload from "./upload";
import { useSystemSettingsState } from "modules/system-settings/hook";
import { useAppState } from "modules/app/hook";
import { SystemSettingsStateModel } from "modules/system-settings/reducer";
import { useDropzone } from "react-dropzone";
import { ProductImageModel } from "modules/product-image/model";
import { apiUrl } from "config";
import { getCookie } from "services/cookies";

const defineType = (
  fileFormat: string,
  systemSettingsState: SystemSettingsStateModel
) => {
  const { data } = systemSettingsState;
  if (data) {
    const acceptedImageTypesEntry = data.find(
      (d) => d.name === "acceptedImageTypes"
    );
    const acceptedRawypesEntry = data.find(
      (d) => d.name === "acceptedRawTypes"
    );

    const acceptedImageTypes = acceptedImageTypesEntry
      ? acceptedImageTypesEntry.value
      : [];
    const acceptedRawTypes = acceptedRawypesEntry
      ? acceptedRawypesEntry.value
      : [];

    if (acceptedImageTypes.includes(fileFormat)) {
      // image
      return "image";
    } else if (acceptedRawTypes.includes(fileFormat)) {
      return "raw";
    }
  }
  throw new Error("Unknown file type");
};

interface ProductImageEditorProps {
  originalImages: ProductImageModel[];
}

const ProductImageEditor: FunctionComponent<ProductImageEditorProps> = ({
  originalImages,
}) => {
  const editorState = useProductImageEditorState();
  const [dragHoverActive, toggleDragHoverActive] = useState(false);
  const { images, removedImages, addedImages, uploading } = editorState;
  const systemSettingsState = useSystemSettingsState();
  const appState = useAppState();
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (files) => {
      toggleDragHoverActive(false);
      handleImageDrop(files);
    },
  });

  useEffect(() => {
    // load original images
    setOriginalImages(originalImages);

    // reset on unmount
    return () => {
      reset();
    };
  }, []);

  const { selectedOrganizationData } = appState;
  if (!selectedOrganizationData) {
    return null;
  }

  const handleImageDrop = async (droppedFiles: any[]) => {
    // associate identifier with uploaded filesz
    droppedFiles.forEach((file) => {
      // generate an identifier
      const identifier = Math.random().toString(36).slice(2);

      // parse format, very likely to break down :[]
      const format = file.name.split(".").pop();
      const size = file.size;

      let type: string;
      try {
        type = defineType(format, systemSettingsState);
      } catch (error: any) {
        console.log(error);
        errorNotification(error.message);
        return null;
      }

      // generate a dummy image
      const imageInstance: ProductImageModel = {
        uuid: identifier,
        publicId: "",
        format,
        type,
        size,
      };

      // add image to redux
      addImage(imageInstance);

      const upload = async () => {
        try {
          const response = await request
            .post(apiUrl + "/upload")
            .set("Authorization", getCookie())
            .on("progress", (event) => {
              if (event.direction === "upload") {
                // dispatch action to update progress
                updateUploadProgress(identifier, _.get(event, "percent", 0));
              }
            })
            .field("file", file);

          // dispatch the complete action
          imageUploadComplete(identifier, response.body);
        } catch (error) {
          log.error({ obj: error }, "Upload failed with error: ");
          // pieActions.imageUploadFailure(file.identifier);
          // throw some notification (and details)

          // fire prop action
        }
      };
      upload();
    });
  };

  const renderImages = () => {
    return (
      <ImageContainer>
        {/* loop over currently attached images */}
        {images.map((image) => {
          if (removedImages.find((i) => i.uuid === image.uuid)) {
            return null;
          }

          return (
            <SingleImageContainer key={image.uuid}>
              <SingleImage
                onDeleteClick={() => {
                  removeInitialImage(image);
                }}
                image={image}
              />
            </SingleImageContainer>
          );
        })}

        {/* loop over newly added images */}
        {addedImages.map((image) => {
          if (uploading.find((u) => u.identifier === image.uuid)) {
            // do not show image, if still uploading
            return null;
          }
          return (
            <SingleImageContainer key={image.publicId}>
              <SingleImage
                onDeleteClick={(publicId) => {
                  removeAddedImage(publicId);
                }}
                image={image}
              />
            </SingleImageContainer>
          );
        })}

        {/* loop over currently uploaded images */}
        {uploading.map((instance) => (
          <SingleImageContainer key={instance.identifier}>
            <Upload instance={instance} />
          </SingleImageContainer>
        ))}

        {renderEmpty()}
      </ImageContainer>
    );
  };

  const renderEmpty = () => (
    <EmptyContainer>
      <div>DRAG HERE</div>
      <div>OR CLICK</div>
      <div>TO UPLOAD</div>
    </EmptyContainer>
  );

  const isEmpty = images.length === 0;

  const emptyDropzoneStyles: any = {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    heigth: "100%",
  };

  return (
    <Container hover={dragHoverActive}>
      <StyledDropzone
        {...getRootProps()}
        style={isEmpty ? emptyDropzoneStyles : {}}
        onDragEnter={() => {
          toggleDragHoverActive(true);
        }}
        onDragLeave={() => {
          toggleDragHoverActive(false);
        }}
      >
        <input {...getInputProps()} />
        {renderImages()}
      </StyledDropzone>
    </Container>
  );
};

export default ProductImageEditor;
