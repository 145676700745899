import _ from "lodash";
import { OrganizationUnitModel } from "modules/organization-unit/model";
import { OrganizationModel } from "modules/organization/model";
import { AppActions } from "./actions";

export interface AppStateModel {
  showFilter: boolean;
  filters: any;
  loading: boolean;
  error: any;
  currentLocation: string | null;
  selectedAttributeFilters: string[];
  selectedOrganizationUuid: string | null;
  selectedOrganizationUnitUuid: string | null;
  selectedOrganizationData: OrganizationModel | null;
  selectedOrganizationUnitData: OrganizationUnitModel | null;
  systemSettings: any;
}

console.warn("TODO: eriytä täältä selectedXXX jutut omiksi reducereikseen");
const initialState: AppStateModel = {
  showFilter: false,
  filters: {} as any,
  loading: false,
  error: null,
  currentLocation: null,
  selectedAttributeFilters: [],
  selectedOrganizationUuid: null,
  selectedOrganizationUnitUuid: null,
  selectedOrganizationData: null,
  selectedOrganizationUnitData: null,
  systemSettings: null,
};

export const appReducer = (state = initialState, action: any = {}) => {
  switch (action.type) {
    case AppActions.APP_CLEAR: {
      return {
        ...state,
        selectedAttributeFilters: [],
        selectedOrganizationUuid: null,
        selectedOrganizationData: "",
      };
    }

    case AppActions.APP_LOAD_SYSTEM_SETTINGS_SUCCESS: {
      const systemSettingsArray = action.payload;

      const settings = _.keyBy(systemSettingsArray, "name");

      return {
        ...state,
        systemSettings: settings,
      };
    }

    case AppActions.APP_SELECT_ORGANIZATION: {
      return {
        ...state,
        selectedOrganizationUuid: action.payload.uuid,
      };
    }

    case AppActions.APP_LOAD_ORGANIZATION_DATA_SUCCESS: {
      return {
        ...state,
        selectedOrganizationData: action.payload,
      };
    }

    case AppActions.APP_SELECT_ORGANIZATION_UNIT_SUCCESS: {
      return {
        ...state,
        selectedOrganizationUnitUuid: action.payload.uuid,
        selectedOrganizationUnitData: action.payload,
      };
    }

    case AppActions.APP_REFRESH_ORGANIZATION_UNIT_DATA_SUCCESS: {
      return {
        ...state,
        selectedOrganizationUnitData: action.payload,
      };
    }

    case AppActions.APP_REFRESH_ORGANIZATION_DATA_SUCCESS: {
      return {
        ...state,
        selectedOrganizationData: action.payload,
      };
    }

    case AppActions.APP_FILTER_CONSTRUCTOR:
      return {
        ...state,
        filters: _.merge({}, state.filters, action.payload),
      };

    case AppActions.APP_FILTER_SHOW:
      return { ...state, showFilter: !state.showFilter };

    case AppActions.APP_FILTER_ADD_ITEM: {
      const { id } = action.payload;
      const currentActiveIds = state.filters[action.payload.name].active;

      const exists = currentActiveIds.indexOf(action.payload.uuid);
      if (exists === -1) currentActiveIds.push(id);
      else currentActiveIds.splice(exists, 1);

      return {
        ...state,

        filters: {
          ...state.filters,
          [action.payload.name]: {
            ...state.filters[action.payload.name],
            active: currentActiveIds,
          },
        },
      };
    }

    case AppActions.APP_ADD_ATTRIBUTE_FILTER:
      return {
        ...state,
        selectedAttributeFilters: _.xor(state.selectedAttributeFilters, [
          action.payload,
        ]),
      };

    case AppActions.APP_FILTER_CLEAR:
      return {
        ...state,
        selectedAttributeFilters: [],
      };

    default:
      return state;
  }
};
