import { ProductViewActions } from "./actions";
import { ProductModel } from "../product/model";

export interface ProductViewStateModel {
  data: ProductModel | null;
  loading: boolean;
  error: any | null;
}

const initialState: ProductViewStateModel = {
  data: null,
  loading: false,
  error: null,
};

export const productViewReducer = (state = initialState, action: any = {}) => {
  switch (action.type) {
    case ProductViewActions.PRODUCT_VIEW_LOAD:
      return {
        ...state,
        data: null,
        loading: true,
      };

    case ProductViewActions.PRODUCT_VIEW_LOAD_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };

    case ProductViewActions.PRODUCT_VIEW_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        data: null,
      };

    default:
      return state;
  }
};
