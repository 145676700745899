// utils
import { toggleItemInArray } from "utils/array";
import { FilterActions } from "./actions";

export interface FilterStateModel {
  loading: boolean;
  error: any | null;
  selectedAttributes: string[];
  showFilter: boolean;
  showFilterSettings: boolean;
}

// Initial state
const initialState: FilterStateModel = {
  loading: false,
  error: null,
  selectedAttributes: [],
  showFilter: true,
  showFilterSettings: false,
};

// Reducer
export const filterReducer = (state = initialState, action: any = {}) => {
  switch (action.type) {
    case FilterActions.SHOW_FILTER_SETTINGS:
      return {
        ...state,
        showFilterSettings: true,
      };

    case FilterActions.HIDE_FILTER_SETTINGS:
      return {
        ...state,
        showFilterSettings: false,
      };

    case FilterActions.TOGGLE_FILTER_SETTINGS:
      return {
        ...state,
        showFilterSettings: !state.showFilterSettings,
      };

    case FilterActions.FILTER_ADD_ATTRIBUTE:
      return {
        ...state,
        selectedAttributes: toggleItemInArray(
          state.selectedAttributes,
          action.payload
        ),
      };

    case FilterActions.FILTER_REMOVE_ATTRIBUTE:
      return {
        ...state,
        selectedAttributes: toggleItemInArray(
          state.selectedAttributes,
          action.payload
        ),
      };

    case FilterActions.FILTER_CLEAR:
      return {
        ...state,
        selectedAttributes: [],
        error: null,
        loading: false,
      };

    case FilterActions.FILTER_SHOW:
      return {
        ...state,
        showFilter: true,
      };

    case FilterActions.FILTER_HIDE:
      return {
        ...state,
        showFilter: false,
      };

    default:
      return state;
  }
};
