import styled, { css } from "styled-components";
import mixins from "theme/mixins";

export const StyledTh = styled.th<any>`
  ${mixins.fontCoda()};
  text-transform: uppercase;
  color: ${(props) => props.theme.darkBlue};
  font-size: 90%;
  border-top: 0px !important;
  border-right: 1px solid ${(props) => props.theme.grayBorder};
  vertical-align: top;
  cursor: default;
  padding: 0.5rem 0.75rem 0.25rem 0.75rem !important;
  height: 40px;
  vertical-align: middle;
  &:last-child {
    border-right: 0px;
  }

  &[nowrap] {
    white-space: nowrap;
  }

  ${(props) =>
    props.fullWidth &&
    css`
      width: 100%;
    `};
`;

export const StyledTd = styled.td<any>`
  ${mixins.fontCoda()};
  font-size: 90%;
  border-top: 0px !important;
  border-right: 1px solid ${(props) => props.theme.grayBorder};
  vertical-align: top;
  cursor: default;
  padding: 0.5rem 0.75rem 0.25rem 0.75rem !important;
  height: 40px;
  vertical-align: middle;

  &:last-child {
    border-right: 0px;
  }

  &[nowrap] {
    white-space: nowrap;
  }

  ${(props) =>
    props.fullWidth &&
    css`
      width: 100%;
    `};
`;

export type CellTextAlignType = "left" | "center" | "right";
export type CellVerticalAlignType = "top" | "middle" | "bottom";

interface Props {
  textAlign: CellTextAlignType;
  verticalAlign: CellVerticalAlignType;
}

export const StyledCellContentAligner = styled.div<Props>`
  display: flex;

  ${(props) =>
    props.textAlign &&
    css`
      justify-content: ${props.textAlign};
    `}

  ${(props) =>
    props.verticalAlign &&
    css`
      align-items: ${props.verticalAlign};
    `}
`;
