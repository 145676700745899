import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";

// only global style files
import "./theme/bootstrap.scss";
import "./theme/DatePicker.scss";

// create react root
const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
