import { ProductModel } from "modules/product/model";
import { ProductEditActions } from "./actions";

export interface ProductEditStateModel {
  loading: boolean;
  data: ProductModel | null;
  error: null;
}

const initialState: ProductEditStateModel = {
  loading: false,
  data: null,
  error: null,
};

// Reducer
export const productEditReducer = (state = initialState, action: any = {}) => {
  switch (action.type) {
    case ProductEditActions.PRODUCT_EDIT_LOAD:
      return {
        ...state,
        data: null,
        loading: true,
      };

    case ProductEditActions.PRODUCT_EDIT_LOAD_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };

    case ProductEditActions.PRODUCT_DELETE:
      return {
        ...state,
        loading: true,
      };

    case ProductEditActions.PRODUCT_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case ProductEditActions.PRODUCT_DELETE_FAILURE:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};
