import { FunctionComponent } from "react";
import { Link, useLocation } from "react-router-dom";
import { NavItem } from "reactstrap";
import { StyledNavLink } from "./custom-nav-item.styles";

interface CustomNavItemProps {
  to: string;
  children: any;
  color?: string;
  matchPath: string;
}

const CustomNavItem: FunctionComponent<CustomNavItemProps> = ({
  to,
  children,
  color = null,
  matchPath,
}) => {
  const currentPath = useLocation().pathname;
  const selected = currentPath.includes(matchPath);

  return (
    <NavItem>
      <StyledNavLink selected={selected} color={color} tag={Link} to={to}>
        {children}
      </StyledNavLink>
    </NavItem>
  );
};

export default CustomNavItem;
