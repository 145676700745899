import { faEdit } from "@fortawesome/free-solid-svg-icons";
import BigButton from "components/big-button";
import Label from "components/label";
import Select from "components/select";
import TableActionCell from "components/table/action-cell";
import TableBodyCell from "components/table/body-cell";
import { userChangeGroup } from "modules/organization-user/actions";
import { OrganizationModel } from "modules/organization/model";
import { UserDataModel } from "modules/user/models";
import { FunctionComponent, useState } from "react";
import { Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";

interface OrganizationUserGroupProps {
  organization: OrganizationModel;
  user: UserDataModel;
}

const OrganizationUserGroup: FunctionComponent<OrganizationUserGroupProps> = ({
  organization,
  user,
}) => {
  const { organizationGroups = [] } = organization;
  const [modalOpen, toggleModalOpen] = useState(false);
  const group = user.organizationGroups.find(
    (g) => g.organizationUuid === organization.uuid
  );

  if (!group) {
    return <TableBodyCell>sysadmin</TableBodyCell>;
  }

  // state of units user is in (in this organization)
  const [groupUuid, toggleGroupUuid] = useState(group.uuid);

  const userFullName = `${user.firstName} ${user.lastName}`;

  const handleSave = () => {
    userChangeGroup(organization.uuid, user.uuid, groupUuid);
    toggleModalOpen(false);
  };

  return (
    <TableActionCell
      textAlign="left"
      text={group.name}
      icon={faEdit}
      onClick={() => toggleModalOpen(true)}
    >
      <Modal
        size="lg"
        isOpen={modalOpen}
        toggle={() => toggleModalOpen(!modalOpen)}
      >
        <ModalHeader toggle={() => toggleModalOpen(!modalOpen)}>
          User: {userFullName}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Label>Group</Label>
              <Select
                onChange={(groupUuid) => toggleGroupUuid(groupUuid)}
                value={groupUuid}
              >
                {organizationGroups.map((group) => {
                  return (
                    <option key={group.uuid} value={group.uuid}>
                      {group.name}
                    </option>
                  );
                })}
              </Select>
            </Col>
          </Row>
          <Row>
            <Col>
              <BigButton onClick={() => toggleModalOpen(false)}>
                Cancel
              </BigButton>
            </Col>
            <Col>
              <BigButton onClick={() => handleSave()}>Save group</BigButton>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </TableActionCell>
  );
};

export default OrganizationUserGroup;
