import { refreshOrganizationData } from "modules/app/actions";
import { store } from "redux/store";

export enum OrganizationGroupActions {
  ORGANIZATION_GROUP_UPDATE = "ORGANIZATION_GROUP_UPDATE",
  ORGANIZATION_GROUP_UPDATE_SUCCESS = "ORGANIZATION_GROUP_UPDATE_SUCCESS",
  ORGANIZATION_GROUP_UPDATE_FAILURE = "ORGANIZATION_GROUP_UPDATE_FAILURE",

  ORGANIZATION_GROUP_CREATE = "ORGANIZATION_GROUP_CREATE",
  ORGANIZATION_GROUP_CREATE_SUCCESS = "ORGANIZATION_GROUP_CREATE_SUCCESS",
  ORGANIZATION_GROUP_CREATE_FAILURE = "ORGANIZATION_GROUP_CREATE_FAILURE",

  ORGANIZATION_GROUP_DELETE = "ORGANIZATION_GROUP_DELETE",
  ORGANIZATION_GROUP_DELETE_SUCCESS = "ORGANIZATION_GROUP_DELETE_SUCCESS",
  ORGANIZATION_GROUP_DELETE_FAILURE = "ORGANIZATION_GROUP_DELETE_FAILURE",
}

export const organizationGroupUpdate = (
  organizationUuid: string,
  uuid: string,
  dataObject: any
) => {
  store.dispatch({
    type: OrganizationGroupActions.ORGANIZATION_GROUP_UPDATE,
    apiCall: {
      endpoint: `/organization-groups/${uuid}`,
      payload: dataObject,
      method: "patch",
      success: () => {
        refreshOrganizationData(organizationUuid);
      },
    },
  });
};

export const organizationGroupCreate = (
  organizationUuid: string,
  dataObject: any
) => {
  const payload = { ...dataObject, organizationUuid };

  store.dispatch({
    type: OrganizationGroupActions.ORGANIZATION_GROUP_CREATE,
    apiCall: {
      endpoint: `/organization-groups/`,
      payload,
      method: "post",
      success: () => {
        refreshOrganizationData(organizationUuid);
      },
    },
  });
};

export const organizationGroupDelete = (
  organizationUuid: string,
  uuid: string
) => {
  store.dispatch({
    type: OrganizationGroupActions.ORGANIZATION_GROUP_DELETE,
    apiCall: {
      endpoint: `/organization-groups/${uuid}`,
      method: "delete",
      success: () => {
        refreshOrganizationData(organizationUuid);
      },
    },
  });
};
