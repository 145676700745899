import { FunctionComponent, useEffect, useState } from "react";
import { ProductImageModel } from "modules/product-image/model";
import { generateUrl } from "components/image";
import { Container, StyledNumber } from "./image.styles";
import DeleteIcon from "components/delete-icon";

// image, pdf, raw
interface SingleImageProps {
  image: ProductImageModel;
  uploading?: boolean;
  onDeleteClick: (publicId: string) => void;
}

const SingleImage: FunctionComponent<SingleImageProps> = ({
  image,
  onDeleteClick,
}) => {
  const [url, toggleUrl] = useState("");

  useEffect(() => {
    const newUrl = generateUrl(image.publicId);
    toggleUrl(newUrl);
  }, []);

  return (
    <Container style={{ position: "relative" }}>
      <img src={url} alt="" width="100%" />
      {image.number && <StyledNumber>#{image.number}</StyledNumber>}
      <DeleteIcon
        style={{ position: "absolute", bottom: "10px", right: "10px" }}
        onClickAction={() => {
          onDeleteClick(image.publicId);
        }}
      />
    </Container>
  );
};

export default SingleImage;
