import { Fragment, FunctionComponent } from "react";
import { Collapse, Container, Row, Col } from "reactstrap";
import FilterBottomRow from "./filter-bottom-row";
import { BlueBackground, StyledRow } from "./filter.styles";
import { useAppState } from "modules/app/hook";
import { useFilterState } from "./hook";
import AttributeGroup from "modules/attribute-group/attribute-group";

const Filter: FunctionComponent<any> = () => {
  const appState = useAppState();
  const filterState = useFilterState();
  const { selectedOrganizationUnitData } = appState;

  if (!selectedOrganizationUnitData) {
    return null;
  }

  const renderFilters = () => {
    const { attributeGroups = [] } = selectedOrganizationUnitData;

    return (
      <Fragment>
        {attributeGroups.map((group: any) => (
          <div key={group.uuid} className="col-sm-4">
            <AttributeGroup group={group} />
          </div>
        ))}
      </Fragment>
    );
  };

  const { showFilter } = filterState;
  return (
    <BlueBackground>
      <Container>
        <Collapse isOpen={showFilter}>
          <StyledRow>{renderFilters()}</StyledRow>
        </Collapse>
        <Row>
          <Col>
            <FilterBottomRow />
          </Col>
        </Row>
      </Container>
    </BlueBackground>
  );
};

export default Filter;
