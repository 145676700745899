import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FunctionComponent } from "react";
import { StyledIcon } from "./input-icon.styles";

interface InputIconProps {
  disabled?: boolean;
}

const InputIcon: FunctionComponent<InputIconProps> = ({ disabled = false }) => {
  return <StyledIcon icon={faPencilAlt} disabled={disabled} />;
};

export default InputIcon;
