import React, { FunctionComponent, useState } from "react";
import { Nav, NavItem, NavLink, TabPane } from "reactstrap";

import { useUserState } from "modules/user/hook";
import { Container, StyledTabContent } from "./profile-view.styles";
import PageTitle from "components/page-title";
import ProfileTab from "./profile-tab";
import PasswordTab from "./password-tab";

const ProfileView: FunctionComponent<any> = () => {
  const userState = useUserState();
  const { data } = userState;
  const [activeTab, toggleActiveTab] = useState("1");

  if (!data) {
    return null;
  }

  const renderTabHeader = (title: string, id: string, active: string) => (
    <NavItem>
      <NavLink active={id === active} onClick={() => toggleActiveTab(id)}>
        {title}
      </NavLink>
    </NavItem>
  );

  return (
    <React.Fragment>
      <PageTitle
        title={`My Profile - ${data.firstName} ${data.lastName}`}
        rightText=""
      />

      <Container>
        <Nav tabs>
          {renderTabHeader("Profile", "1", activeTab)}
          {renderTabHeader("Password", "2", activeTab)}
        </Nav>

        <StyledTabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <ProfileTab />
          </TabPane>

          <TabPane tabId="2">
            <PasswordTab />
          </TabPane>
        </StyledTabContent>
      </Container>
    </React.Fragment>
  );
};

export default ProfileView;
