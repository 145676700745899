import Button from "components/button";
import { Col, Container } from "reactstrap";
import styled, { css } from "styled-components";
import mixins from "theme/mixins";

export const StyledContainer = styled(Container)``;

export const ExpiresContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledExpireText = styled.p`
  margin: 0;
  margin-left: 10px;
  margin-right: 20px;
`;

export const StyledModule = styled.div`
  background-color: ${(props) => props.theme.white};
  padding: 20px;
`;

export const StyledShareButton = styled(Button)<any>`
  padding: 10px !important;
  margin-right: 15px;
  width: 100%;
  justify-content: center;

  div {
    word-wrap: break-word;
    white-space: normal;
  }

  ${(props) =>
    props.backgroundColor &&
    css`
      background-color: ${props.theme[props.backgroundColor]} !important;
      border: 0 !important;
    `};
`;

export const StyledExpireDesc = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  ${mixins.fontCoda()};
  ${mixins.media.tablet` margin-top: 10px; justify-content: flex-start;`};
`;
