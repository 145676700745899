import { faEdit } from "@fortawesome/free-solid-svg-icons";
import BigButton from "components/big-button";
import Form from "components/form";
import Input from "components/input";
import Label from "components/label";
import Select from "components/select";
import TableActionCell from "components/table/action-cell";
import { FunctionComponent, useState } from "react";
import { Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";

interface AttributeGroupEditProps {
  pristine?: boolean;
  name: string;
  sorting: number;
  type: string;
  onSubmit: (name: string, sorting: number, type: string) => void;
}

interface FormModel {
  name: string;
  sorting: string;
  type: string;
}

const AttributeGroupEdit: FunctionComponent<AttributeGroupEditProps> = ({
  pristine = false,
  name,
  sorting,
  type,
  onSubmit,
}) => {
  const [open, toggleOpen] = useState(false);
  const [form, toggleForm] = useState<FormModel>({
    name,
    sorting: sorting.toString(),
    type,
  });

  const handleSubmit = async () => {
    toggleModal();
    onSubmit(form.name, parseFloat(form.sorting), form.type);
  };

  const toggleModal = () => {
    toggleOpen(!open);
  };

  return (
    <TableActionCell
      icon={faEdit}
      onClick={() => toggleOpen(true)}
      style={{ borderRight: 0 }}
      header
    >
      <Modal size="lg" isOpen={open} toggle={() => toggleModal()}>
        <ModalHeader toggle={toggleModal}>Edit Attribute Group</ModalHeader>
        <ModalBody>
          <Form>
            <Row>
              <Col>
                <Label>Name:</Label>
                <Input
                  value={form.name}
                  required
                  placeholder="Attribute Group Name"
                  type="text"
                  onChange={(name) => toggleForm({ ...form, name })}
                />
              </Col>

              <Col>
                <Label>Type:</Label>
                <Select
                  value={form.type}
                  onChange={(type) => toggleForm({ ...form, type })}
                >
                  <option value="tags">Tags</option>
                  <option value="multiselect">Multiselect</option>
                </Select>
              </Col>
            </Row>

            <Row>
              <Col>
                <Label>Sorting:</Label>
                <Input
                  value={form.sorting}
                  required
                  placeholder="Attribute Group Sorting"
                  type="text"
                  onChange={(sorting) => toggleForm({ ...form, sorting })}
                />
              </Col>
            </Row>

            <Row style={{ marginTop: 20 }}>
              <Col>
                <BigButton
                  onClick={() => {
                    toggleModal();
                  }}
                >
                  Cancel
                </BigButton>
              </Col>
              <Col>
                <BigButton
                  disabled={pristine}
                  type="submit"
                  onClick={() => handleSubmit()}
                >
                  Save changes
                </BigButton>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </TableActionCell>
  );
};

export default AttributeGroupEdit;
