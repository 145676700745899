import { refreshOrganizationUnitData } from "modules/app/actions";
import { ProductModel } from "modules/product/model";
import { NavigateFunction } from "react-router";
import { store } from "redux/store";

export enum NewProductActions {
  PRODUCT_ADD = "PRODUCT_ADD",
}

export const productAdd = (
  organizationUuid: string,
  organizationUnitUuid: string,
  values: any,
  navigate: NavigateFunction
) => {
  values.organizationUuid = organizationUuid;
  values.organizationUnitUuid = organizationUnitUuid;

  store.dispatch({
    type: NewProductActions.PRODUCT_ADD,
    apiCall: {
      endpoint: `/products`,
      method: "post",
      payload: values,
      success: (createdProduct: ProductModel) => {
        refreshOrganizationUnitData(organizationUnitUuid);
        navigate(`/products/${createdProduct.uuid}`);
      },
      failure: () => {},
    },
  });
};
