import {
  faExclamationCircle,
  faThumbsUp,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import { FunctionComponent } from "react";
import { useNotificationState } from "modules/notifications/hook";
import Icon from "./icon";
import {
  Message,
  NotificationContainer,
  StyledNotification,
  Title,
  TitleContainer,
} from "./notifications.styles";

interface NotificationMapModel {
  [key: string]: {
    icon: IconDefinition;
    color: string;
  };
}

// style definitions
export const NotificationMap: NotificationMapModel = {
  SUCCESS: {
    icon: faThumbsUp,
    color: "green",
  },
  INFO: {
    icon: faExclamationCircle,
    color: "orange",
  },
  ERROR: {
    icon: faExclamationCircle,
    color: "redAlert",
  },
};

const Notifications: FunctionComponent = () => {
  const notifications = useNotificationState();
  return (
    <NotificationContainer>
      {notifications.items.map((notification) => {
        const { uuid, type, title, message } = notification;
        return (
          <StyledNotification key={uuid} type={type}>
            <TitleContainer>
              <Icon icon={NotificationMap[type].icon} />
              <Title>{title}</Title>
            </TitleContainer>
            {message && <Message>{message}</Message>}
          </StyledNotification>
        );
      })}
    </NotificationContainer>
  );
};

export default Notifications;
