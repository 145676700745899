import { FunctionComponent, useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import _ from "lodash";
import { AttributeModel } from "modules/attribute/model";
import { useNavigate, useParams } from "react-router";
import { productDelete, productEditLoad, productEditSave } from "./actions";
import { reset } from "modules/product-image-editor/actions";
import IconAndText from "components/icon-and-text";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import BigButton from "components/big-button";
import PageTitle from "components/page-title";
import { InputWrapper } from "./product-edit-view.styles";
import Input from "components/input";
import { useProductEditState } from "./hook";
import { pickChangedValues, toggleItemInArray } from "utils/array";
import { useAppState } from "modules/app/hook";
import PageElement from "components/page-element";
import VerticalAttributeList from "components/vertical-attribute-list";
import ProductImageEditor from "modules/product-image-editor/editor";
import { useProductImageEditorState } from "modules/product-image-editor/hook";
import Form from "components/form";
import { useProductLinkEditorState } from "modules/product-link-editor/hook";
import ProductLinkEditor from "modules/product-link-editor/editor";

interface FormModel {
  title: string;
  description: string;
}

const ProductEditView: FunctionComponent = () => {
  const { uuid } = useParams();
  const [form, toggleForm] = useState<FormModel>({
    title: "",
    description: "",
  });
  const navigate = useNavigate();
  const appState = useAppState();
  const selectedOrganizationUnitData = appState.selectedOrganizationUnitData;

  const productEditState = useProductEditState();
  const imageEditorState = useProductImageEditorState();
  const linkEditorState = useProductLinkEditorState();
  const { data } = productEditState;
  // keep a list of attributes that actually changed their status
  // the backend will handle on/off logic based on that
  const [toggledAttributes, setToggledAttributes] = useState<string[]>([]);

  useEffect(() => {
    if (uuid) {
      productEditLoad(uuid);
    }

    return () => {
      // reset product image editor on unmount
      reset();
    };
  }, [uuid]);

  useEffect(() => {
    if (data) {
      const { title, description } = data;
      toggleForm({ title, description });
    }
  }, [data]);

  const { selectedOrganizationUnitUuid } = appState;

  // if no data, exit
  if (
    !data ||
    !uuid ||
    !selectedOrganizationUnitData ||
    !selectedOrganizationUnitUuid
  ) {
    return null;
  }

  const { attributeGroups } = selectedOrganizationUnitData;

  const handleSubmitForm = async () => {
    const { removedImages, addedImages } = imageEditorState;
    const { removedLinks, addedLinks } = linkEditorState;

    // find which elements where changed
    const changedObject = pickChangedValues(form, productEditState.data);

    if (removedImages.length > 0) {
      changedObject.removedImages = removedImages;
    }

    if (removedLinks.length > 0) {
      changedObject.removedLinks = removedLinks;
    }

    if (addedImages.length > 0) {
      changedObject.addedImages = _.map(addedImages, (image) => ({
        publicId: image.publicId,
        type: image.type,
        format: image.format,
        size: image.size,
      }));
    }

    if (addedLinks.length > 0) {
      changedObject.addedLinks = _.map(addedLinks, (link) => ({
        name: link.name,
        url: link.url,
      }));
    }

    if (toggledAttributes.length > 0) {
      changedObject.toggledAttributes = toggledAttributes;
    }

    // dispatch the action
    productEditSave(
      selectedOrganizationUnitUuid,
      data.uuid,
      changedObject,
      navigate,
    );
  };

  let attributes = _.map(
    data.attributes as AttributeModel[],
    (attribute) => attribute.uuid,
  );
  attributes = _.xor(attributes, toggledAttributes);

  return (
    <Form>
      <PageTitle title="Edit Product" />

      <Row>
        <Col>
          <InputWrapper>
            <Input
              name="title"
              value={form.title}
              noBottomMargin
              placeholder="Enter product title here"
              type="text"
              onChange={(title) => toggleForm({ ...form, title })}
            />
          </InputWrapper>
        </Col>
        <Col>
          <InputWrapper>
            <Input
              name="description"
              value={form.description}
              noBottomMargin
              placeholder="Enter product description here (not mandatory)"
              type="text"
              onChange={(description) => toggleForm({ ...form, description })}
            />
          </InputWrapper>
        </Col>
      </Row>

      <Row>
        <Col />
      </Row>

      <PageElement>
        <h5>Product images and files</h5>
        <ProductImageEditor originalImages={data.images} />
      </PageElement>

      <PageElement>
        <h5 style={{ marginBottom: 20 }}>Attributes</h5>

        <VerticalAttributeList
          onAttributeClick={(attributeId) =>
            setToggledAttributes(
              toggleItemInArray(toggledAttributes, attributeId),
            )
          }
          attributeGroups={attributeGroups}
          selectedAttributes={attributes}
        />
      </PageElement>

      <PageElement>
        <h5>Product links</h5>
        <ProductLinkEditor originalLinks={data.links} />
      </PageElement>

      <PageElement>
        <BigButton type="button" onClick={() => handleSubmitForm()}>
          Submit changes
        </BigButton>
      </PageElement>

      <PageElement>
        <IconAndText
          color="red"
          icon={faTrashAlt}
          toggle
          toggleText="Are you sure?"
          onClick={() =>
            productDelete(selectedOrganizationUnitUuid, uuid, navigate)
          }
        >
          Delete product
        </IconAndText>
      </PageElement>
    </Form>
  );
};

export default ProductEditView;
