import styled from "styled-components";
import mixins from "theme/mixins";
import { Input as ReactstrapInput } from "reactstrap";

export const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 100%;
`;

export const InputContainer = styled.div`
  ${mixins.fontCoda()};
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledReactstrapInput = styled(ReactstrapInput)`
  padding: 5px !important;
`;

export const SaveAndCancel = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-betwen;

  padding-right: 10px;
  padding-left: 20px;
`;

export const Cancel = styled.div`
  color: ${(props) => props.theme.red};

  ${mixins.hoverUnderline()};
`;

export const Save = styled.div`
  color: ${(props) => props.theme.darkBlue};
  padding-right: 20px;

  ${mixins.hoverUnderline()};
`;
