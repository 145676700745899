import { FunctionComponent } from "react";
import { imageUrl } from "config";
import { StyledImage, StyledNumber } from "./image.styles";

export const generateUrl = (publicId: string) => {
  const finalUrl = [imageUrl, publicId].join("/");
  return finalUrl;
};

export const generateThumbnailUrl = (publicId: string) => {
  const finalUrl = [imageUrl, publicId.replace(".", "_thumbnail.")].join("/");
  return finalUrl;
};

interface ImageProps {
  publicId: string;
  format?: string;
  number?: number;
  thumbnail?: boolean;
}

const Image: FunctionComponent<ImageProps> = ({
  publicId,
  format,
  number,
  thumbnail,
}) => {
  if (format === "pdf") {
    return (
      <iframe
        src={generateUrl(publicId)}
        style={{ border: 0, width: "100%" }}
      />
    );
  }

  return (
    <StyledImage
      style={{
        backgroundImage: `url(${
          thumbnail ? generateThumbnailUrl(publicId) : generateUrl(publicId)
        })`,
      }}
    >
      {number && <StyledNumber>#{number}</StyledNumber>}
    </StyledImage>
  );
};

export default Image;
