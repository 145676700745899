import { RouteObject } from "react-router";
import LoginView from "modules/login/login-view";
import ProductEditView from "modules/product-edit/product-edit-view";
import ProductListView from "modules/product-list/product-list-view";
import ProductView from "modules/product-view/product-view";
import ProfileView from "modules/profile/profile-view";
import OrganizationGroupListView from "modules/organization-group-list/organization-group-list-view";
import OrganizationUnitListView from "modules/organization-unit-list/organization-unit-list-view";
import OrganizationUserListView from "modules/organization-user-list/organization-user-list-view";
import OrganizationUserAddView from "modules/organization-user-add/organization-user-add-view";
import NewProductView from "modules/new-product/new-product-view";
import AttributeListView from "modules/attribute-list/attribute-list-view";
import CollectorView from "modules/collector/collector-view";
import LogoutView from "modules/logout/logout-view";
import SysadminView from "modules/sysadmin/sysadmin-view";
import OrganizationEditView from "modules/sysadmin/organization-edit-view";
import PublicCollector from "modules/collector/public-collector";

export const privateRoutes: RouteObject[] = [
  {
    path: "/",
    element: <ProductListView />,
  },
  {
    path: "/products/:uuid",
    element: <ProductView />,
  },
  {
    path: "/products/:uuid/edit",
    element: <ProductEditView />,
  },
  {
    path: "/profile",
    element: <ProfileView />,
  },
  {
    path: "/groups",
    element: <OrganizationGroupListView />,
  },
  {
    path: "/units",
    element: <OrganizationUnitListView />,
  },
  {
    path: "/users",
    element: <OrganizationUserListView />,
  },
  {
    path: "/add-user",
    element: <OrganizationUserAddView />,
  },
  {
    path: "/new",
    element: <NewProductView />,
  },
  {
    path: "/attributes",
    element: <AttributeListView />,
  },
  {
    path: "/share",
    element: <CollectorView />,
  },
  { path: "/logout", element: <LogoutView /> },
  { path: "/sysadmin", element: <SysadminView /> },
  {
    path: "/organizations/:uuid/edit",
    element: <OrganizationEditView />,
  },
];

export const publicRoutes: RouteObject[] = [
  { path: "/login", element: <LoginView /> },
  { path: "/login/:token", element: <LoginView /> },
  { path: "/collector/:collectorLink", element: <PublicCollector /> },
];
