import { OrganizationUnitActions } from "./actions";
import { OrganizationUnitModel } from "./model";

export interface OrganizationUnitsStateModel {
  data: OrganizationUnitModel[];
  loading: boolean;
  error: null;
}

// Initial state
const initialState: OrganizationUnitsStateModel = {
  data: [],
  loading: false,
  error: null, // not used
};

// Reducer
export const unitsReducer = (state = initialState, action: any = {}) => {
  switch (action.type) {
    case OrganizationUnitActions.ORGANIZATION_UNIT_LOAD_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };

    case OrganizationUnitActions.ORGANIZATION_UNIT_UPDATE:
      return {
        ...state,
        loading: true,
      };

    case OrganizationUnitActions.ORGANIZATION_UNIT_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case OrganizationUnitActions.ORGANIZATION_UNIT_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case OrganizationUnitActions.ORGANIZATION_UNIT_CREATE:
      return {
        ...state,
        loading: true,
      };

    case OrganizationUnitActions.ORGANIZATION_UNIT_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case OrganizationUnitActions.ORGANIZATION_UNIT_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case OrganizationUnitActions.ORGANIZATION_UNIT_DELETE:
      return {
        ...state,
        loading: true,
      };

    case OrganizationUnitActions.ORGANIZATION_UNIT_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case OrganizationUnitActions.ORGANIZATION_UNIT_DELETE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
