import { FunctionComponent } from "react";
import _ from "lodash";

import { useFilterState } from "modules/filter/hook";
import { Container } from "./attribute-group-tags.styles";
import { filterAddAttribute } from "modules/filter/actions";
import Badge from "components/badge";

interface AttributeGroupTagsProps {
  group: any;
}

const AttributeGroupTags: FunctionComponent<AttributeGroupTagsProps> = ({
  group,
}) => {
  const filterState = useFilterState();
  return (
    <Container>
      {_.map(group.attributes, (attribute) => {
        const { selectedAttributes } = filterState;
        let selected = false;

        if (_.includes(selectedAttributes, attribute.uuid)) selected = true;
        return (
          <Badge
            key={attribute.uuid}
            color="white"
            backgroundColor={selected ? "orange" : ""}
            onClick={() => filterAddAttribute(attribute.uuid)}
          >
            {attribute.name}
          </Badge>
        );
      })}
    </Container>
  );
};

export default AttributeGroupTags;
