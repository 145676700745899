import styled from "styled-components";
import mixins from "theme/mixins";

export const Container = styled.div`
  width: 150px;
  height: 150px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledNumber = styled.div`
  ${mixins.fontCoda()};
  font-size: 90%;
  position: absolute;
  bottom: 11px;
  left: 11px;
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: ${(props) => props.theme.lightBlue};
  color: ${(props) => props.theme.white};
`;
