import { refreshOrganizationData } from "modules/app/actions";
import { store } from "redux/store";
import { OrganizationUnitModel } from "./model";

export enum OrganizationUnitActions {
  ORGANIZATION_UNIT_LOAD = "ORGANIZATION_UNIT_LOAD",
  ORGANIZATION_UNIT_LOAD_SUCCESS = "ORGANIZATION_UNIT_LOAD_SUCCESS",

  ORGANIZATION_UNIT_UPDATE = "ORGANIZATION_UNIT_UPDATE",
  ORGANIZATION_UNIT_UPDATE_SUCCESS = "ORGANIZATION_UNIT_UPDATE_SUCCESS",
  ORGANIZATION_UNIT_UPDATE_FAILURE = "ORGANIZATION_UNIT_UPDATE_FAILURE",

  ORGANIZATION_UNIT_CREATE = "ORGANIZATION_UNIT_CREATE",
  ORGANIZATION_UNIT_CREATE_SUCCESS = "ORGANIZATION_UNIT_CREATE_SUCCESS",
  ORGANIZATION_UNIT_CREATE_FAILURE = "ORGANIZATION_UNIT_CREATE_FAILURE",

  ORGANIZATION_UNIT_DELETE = "ORGANIZATION_UNIT_DELETE",
  ORGANIZATION_UNIT_DELETE_SUCCESS = "ORGANIZATION_UNIT_DELETE_SUCCESS",
  ORGANIZATION_UNIT_DELETE_FAILURE = "ORGANIZATION_UNIT_DELETE_FAILURE",
}

export const loadUnits = (organizationUuid: string) => {
  store.dispatch({
    type: OrganizationUnitActions.ORGANIZATION_UNIT_LOAD,
    apiCall: {
      endpoint: `/organization/${organizationUuid}/units`,
      method: "get",
      success: () => {},
    },
  });
};

export const updateUnit = (
  organizationUuid: string,
  organizationUnitUuid: string,
  dataObject: Partial<OrganizationUnitModel>
) => {
  store.dispatch({
    type: OrganizationUnitActions.ORGANIZATION_UNIT_UPDATE,
    apiCall: {
      endpoint: `/organization-units/${organizationUnitUuid}`,
      payload: dataObject,
      method: "patch",
      success: () => {
        loadUnits(organizationUuid);
        refreshOrganizationData(organizationUuid);
      },
    },
  });
};

export const createUnit = (
  organizationUuid: string,
  dataObject: Partial<OrganizationUnitModel>
) => {
  const payload = { ...dataObject, organizationUuid };

  store.dispatch({
    type: OrganizationUnitActions.ORGANIZATION_UNIT_CREATE,
    apiCall: {
      endpoint: `/organization-units/`,
      payload,
      method: "post",
      success: () => {
        loadUnits(organizationUuid);
        refreshOrganizationData(organizationUuid);
      },
    },
  });
};

export const deleteUnit = (
  organizationUuid: string,
  organizationUnitUuid: string
) => {
  store.dispatch({
    type: OrganizationUnitActions.ORGANIZATION_UNIT_DELETE,
    apiCall: {
      endpoint: `/organization-units/${organizationUnitUuid}`,
      method: "delete",
      success: () => {
        loadUnits(organizationUnitUuid);
        refreshOrganizationData(organizationUuid);
      },
    },
  });
};
