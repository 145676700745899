import { Input } from "reactstrap";
import styled from "styled-components";

export const StyledContainer = styled.div<any>`
  margin-bottom: ${(props) => (props.noBottomMargin ? "0px" : "25px")};
`;

export const InputContainer = styled.div`
  position: relative;
`;

export const StyledInput = styled(Input)<any>`
  box-shadow: none;
  outline: none;
  border: none;
  color: rgb(123, 138, 139);
  padding: 10px;
  font-size: 0.82rem;

  &:focus {
    box-shadow: none;
    outline: none;
    border: none;
    color: rgb(123, 138, 139);
  }
`;
