import { FunctionComponent, useEffect } from "react";
import { logUserOut } from "modules/user/actions";
import { useNavigate } from "react-router";

const LogoutView: FunctionComponent = () => {
  const navigate = useNavigate();

  useEffect(() => {
    logUserOut(navigate);
  }, []);

  return null;
};

export default LogoutView;
