import { getValueFromStore, store } from "redux/store";

export enum CollectorActions {
  COLLECTOR_ADD_PRODUCT = "COLLECTOR_ADD_PRODUCT",

  COLLECTOR_ADD_IMAGE = "COLLECTOR_ADD_IMAGE",
  COLLECTOR_REMOVE_IMAGE = "COLLECTOR_REMOVE_IMAGE",

  COLLECTOR_LOAD_IMAGES = "COLLECTOR_LOAD_IMAGES",
  COLLECTOR_LOAD_IMAGES_SUCCESS = "COLLECTOR_LOAD_IMAGES_SUCCESS",

  COLLECTOR_CLEAR = "COLLECTOR_CLEAR",
  COLLECTOR_CLEAR_LINK = "COLLECTOR_CLEAR_LINK",

  COLLECTOR_GENERATE_LINK = "COLLECTOR_GENERATE_LINK",
  COLLECTOR_GENERATE_LINK_SUCCESS = "COLLECTOR_GENERATE_LINK_SUCCESS",
}

export const clearLink = () => {
  store.dispatch({
    type: CollectorActions.COLLECTOR_CLEAR_LINK,
  });
};

export const addImage = (id: string) => {
  // clear link if generated
  clearLink();

  store.dispatch({
    type: CollectorActions.COLLECTOR_ADD_IMAGE,
    payload: id,
  });
};

export const loadImages = () => {
  store.dispatch({
    type: CollectorActions.COLLECTOR_LOAD_IMAGES,
    apiCall: {
      endpoint: `/products/images/search`,
      method: "post",
      payload: {
        uuids: getValueFromStore("collector.images"),
      },
      success: () => {},
      failure: () => {},
    },
  });
};

export const removeImage = (uuid: string) => {
  // clear link if generated
  clearLink();

  store.dispatch({
    type: CollectorActions.COLLECTOR_REMOVE_IMAGE,
    payload: uuid,
  });

  loadImages();
};

export const clear = () => {
  store.dispatch({
    type: CollectorActions.COLLECTOR_CLEAR,
  });

  loadImages();
};

export const generateLink = (expiryDate: string, images: any[]) => {
  const payload = {
    expiryDate,
    images,
    type: "link",
  };

  store.dispatch({
    type: CollectorActions.COLLECTOR_GENERATE_LINK,
    apiCall: {
      endpoint: `/collector/`,
      method: "post",
      payload,
      success: () => {},
      failure: () => {},
    },
  });
};
