import { FunctionComponent } from "react";
import { ChildrenContainer, StyledButton } from "./big-button.styles";

interface BigButtonProps {
  children: any;
  onClick: () => void;
  color?: string;
  disabled?: boolean;
  style?: any;
  type?: string;
}

const BigButton: FunctionComponent<BigButtonProps> = ({
  children,
  onClick,
  color = "primary",
  disabled = false,
  style = {},
  type = "button",
}) => {
  return (
    <StyledButton
      type={type}
      style={style}
      disabled={disabled}
      onClick={onClick}
      color={color}
    >
      <ChildrenContainer>{children}</ChildrenContainer>
    </StyledButton>
  );
};

export default BigButton;
