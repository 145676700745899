import styled from "styled-components";

// styles
import mixins from "theme/mixins";

interface BadgeProps {
  clickable: boolean;
  color: string;
  backgroundColor: string;
}

export const Container = styled.div<BadgeProps>`
  ${mixins.badgeDefaults()}
  ${mixins.fontCoda()}
  cursor: ${(props) => (props.clickable ? "pointer" : "default")};

  color: ${(props) => props.theme[props.color]};

  background-color: ${(props) => props.theme[props.backgroundColor]};
`;
