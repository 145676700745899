import { combineReducers } from "redux";
import { userReducer } from "modules/user/reducer";
import { appReducer } from "modules/app/reducer";
import { notificationsReducer } from "modules/notifications/reducer";
import { collectorReducer } from "modules/collector/reducer";
import { filterReducer } from "modules/filter/reducer";
import { productListReducer } from "modules/product-list/reducer";
import { productViewReducer } from "modules/product-view/reducer";
import { productEditReducer } from "modules/product-edit/reducer";
import { productImageEditorReducer } from "modules/product-image-editor/reducer";
import { systemSettingsReducer } from "modules/system-settings/reducer";
import { unitsReducer } from "modules/organization-unit/reducer";
import { organizationUsersReducer } from "modules/organization-user/reducer";
import { sysadminReducer } from "modules/sysadmin/reducer";
import { productLinkEditorReducer } from "modules/product-link-editor/recucer";
/*
import { productNewReducer } from "modules/product/product-new-redux";
import { attributesReducer } from "modules/attributes/attributes-redux";
import { groupsReducer } from "modules/organization-groups/organization-groups-redux";
 */
const importedReducers = {
  app: appReducer,
  collector: collectorReducer,
  filter: filterReducer,
  notifications: notificationsReducer,
  organizationUsers: organizationUsersReducer,
  sysadmin: sysadminReducer,
  productList: productListReducer,
  productView: productViewReducer,
  productEdit: productEditReducer,
  productImageEditor: productImageEditorReducer,
  productLinkEditor: productLinkEditorReducer,
  systemSettings: systemSettingsReducer,
  units: unitsReducer,
  user: userReducer,
  /*  
  attributes: attributesReducer,
  productNew: productNewReducer,
  groups: groupsReducer,
 */
};

export const RootReducer = combineReducers(importedReducers);

export type RootState = ReturnType<typeof RootReducer>;
