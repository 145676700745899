import { Card, CardBody } from "reactstrap";
import styled from "styled-components";
import mixins from "theme/mixins";

export const StyledCard = styled(Card)`
  box-shadow: 0px 0px 50px #ccc;
  cursor: pointer;
  border: 0px;
  flex-grow: 1;
  width: 100%;
  min-height: 100px;
  margin-bottom: 20px;
`;

export const CardHeader = styled(CardBody)`
  display: flex;
  justify-content: space-between;
`;

export const CardImage = styled(CardBody)`
  display: flex;
  min-height: 211px;
  padding: 12px !important;
  padding-top: 0px !important;
  position: relative;
`;

export const StyledImage = styled.img`
  width: 100%;
  max-width: 262px;
`;

export const StyledNumber = styled.div`
  ${mixins.fontCoda()};
  font-size: 90%;
  position: absolute;
  bottom: 24px;
  left: 24px;
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: ${(props) => props.theme.lightBlue};
  color: ${(props) => props.theme.white};
`;
