import styled from "styled-components";
import { Container, Row, Col } from "reactstrap";
import mixins from "theme/mixins";

export const StyledContainer = styled(Container)`
  ${mixins.fontCoda()};
  color: ${(props) => props.theme.white};
  font-size: 12px;
  letter-spacing: 0.05em;
  text-align: center;

  background-color: ${(props) => props.theme.darkBlue};

  min-height: 60px;
  margin-top: 60px;

  padding-left: 30px;
  padding-right: 30px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  text-transform: uppercase;

  a {
    color: ${(props) => props.theme.white};

    &:hover {
      color: ${(props) => props.theme.white};
    }
  }
`;

export const StyledContainerRow = styled(Row)`
  display: flex;
  align-items: center;
  /* margin-top: 15px;
  margin-bottom: 15px; */
`;

export const StyledRow = styled(Row)`
  display: flex;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
`;

export const StyledCol = styled(Col)`
  display: flex;
  justify-content: center;
`;

export const Logo = styled.img`
  /* margin-top: 15px;
  margin-bottom: 15px; */
  height: 20px;
  filter: brightness(0) invert(1);
`;
