import _ from "lodash";
import { ProductImageModel } from "modules/product-image/model";
import { ProductImageEditorActions } from "./actions";
import { UploadModel } from "./model";

export interface ProductImageEditorStateModel {
  // original images
  images: ProductImageModel[];

  // images added or marked as removed
  addedImages: ProductImageModel[];
  removedImages: ProductImageModel[];

  // map of upload progresses
  uploading: UploadModel[];
}

const initialState: ProductImageEditorStateModel = {
  images: [],
  uploading: [],
  addedImages: [],
  removedImages: [],
};

export const productImageEditorReducer = (
  state = initialState,
  action: any = {}
) => {
  switch (action.type) {
    case ProductImageEditorActions.PIE_INIT:
      return {
        ...state,
      };

    case ProductImageEditorActions.PIE_RESET:
      return {
        ...state,
        images: [],
        uploading: [],
        addedImages: [],
        removedImages: [],
      };

    case ProductImageEditorActions.PIE_SET_ORIGINAL_IMAGES:
      return { ...state, images: action.payload };

    case ProductImageEditorActions.PIE_ADD_IMAGE:
      return {
        ...state,
        addedImages: [...state.addedImages, action.payload],
        uploading: [
          ...state.uploading,
          { identifier: action.payload.uuid, progress: 0 },
        ],
      };

    case ProductImageEditorActions.PIE_REMOVE_ADDED_IMAGE:
      // needed variable
      const publicId = action.payload;
      const { addedImages } = state;

      // loop over addedImages, and remove the image which matches
      const updatedAddedImages = _.filter(addedImages, (value) => {
        if (value.publicId !== publicId) return true;
      });

      return {
        ...state,
        addedImages: updatedAddedImages,
      };

    case ProductImageEditorActions.PIE_REMOVE_INITIAL_IMAGE:
      // needed variable
      const image = action.payload;
      const { removedImages } = state;

      return {
        ...state,
        removedImages: [...removedImages, image],
      };

    // update the progess on one of the images
    case ProductImageEditorActions.PIE_UPDATE_UPLOAD_PROGRESS:
      const { progress } = action.payload;
      let currentlyUploading = state.uploading;

      // find the object, and update the prgoress
      currentlyUploading = _.map(currentlyUploading, (file) => {
        if (file.identifier === action.payload.identifier) {
          file.progress = progress;
          return file;
        }
        return file;
      });

      return {
        ...state,
        uploading: currentlyUploading,
      };

    // remove from uploading queue, and move to added images
    case ProductImageEditorActions.PIE_IMAGE_UPLOAD_COMPLETE:
      const { identifier, responseData } = action.payload;
      const newState = { ...state };

      // find the needle, attach publicId to it
      const uploadInstance = state.uploading.find(
        (u) => u.identifier === identifier
      );

      if (uploadInstance) {
        const uploadedImage = newState.addedImages.find(
          (i) => i.uuid === identifier
        );
        if (uploadedImage) {
          // we are just changing the attributes inside the existing item
          uploadedImage.publicId = responseData.publicId;
        }
      }

      // Remove from uploading array
      newState.uploading = [
        ...newState.uploading.filter(
          (instance) => instance.identifier !== identifier
        ),
      ];
      return newState;

    default:
      return state;
  }
};
