import { FunctionComponent } from "react";
import { Container } from "./badge.styles";

interface BadgeProps {
  children: any;
  color?: string;
  backgroundColor?: string;
  onClick?: () => void;
}

const Badge: FunctionComponent<BadgeProps> = ({
  children,
  onClick,
  color = "primary",
  backgroundColor = "",
}) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <Container
      onClick={handleClick}
      clickable={onClick !== undefined}
      backgroundColor={backgroundColor}
      color={color}
      onKeyPress={() => {}}
      role="presentation"
    >
      {children}
    </Container>
  );
};

export default Badge;
