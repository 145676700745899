import {
  createLogger,
  ConsoleFormattedStream,
  INFO,
  stdSerializers,
} from "browser-bunyan";

export const log = createLogger({
  name: "timber",
  streams: [
    {
      level: INFO, // or use the string 'info'
      stream: new ConsoleFormattedStream(),
    },
  ],
  serializers: stdSerializers,
  src: true,
});
