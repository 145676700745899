import { store } from "redux/store";
import { generateUUID } from "utils/uuid";

export enum NotificationActions {
  ADD_NOTIFICATION = "ADD_NOTIFICATION",
  HIDE_NOTIFICATION = "HIDE_NOTIFICATION",
}

// constants
export enum NotificationType {
  SUCCESS = "SUCCESS",
  INFO = "INFO",
  ERROR = "ERROR",
}

// Action Creators
export const addNotification = (
  type: NotificationType,
  title: string,
  message?: string,
) => {
  const uuid = generateUUID();

  const payload: any = {
    uuid,
    type,
    title,
  };

  if (message) payload.message = message;

  store.dispatch({
    type: NotificationActions.ADD_NOTIFICATION,
    payload,
  });

  setTimeout(() => {
    store.dispatch({
      type: NotificationActions.HIDE_NOTIFICATION,
      payload: uuid,
    });
  }, 4000);
};

export const successNotification = (title: string, message?: string) =>
  addNotification(NotificationType.SUCCESS, title, message);

export const errorNotification = (title: string, message?: string) =>
  addNotification(NotificationType.ERROR, title, message);

export const infoNotification = (title: string, message?: string) =>
  addNotification(NotificationType.INFO, title, message);
