import { Col } from "reactstrap";
import styled from "styled-components";

export const StyledCol = styled(Col)`
  margin-bottom: 1.5rem;
`;

export const MainContainer = styled.div`
  margin-top: 20px;
`;
