import BigButton from "components/big-button";
import Form from "components/form";
import Input from "components/input";
import Label from "components/label";
import PageElement from "components/page-element";
import PageTitle from "components/page-title";
import { FunctionComponent, useEffect, useState } from "react";
import { useParams } from "react-router";
import { Col, Container, Row } from "reactstrap";
import { organizationEditLoad, organizationEditSave } from "./actions";
import { useSysadminState } from "./hook";

interface FormModel {
  name: string;
  website: string;
  businessId: string;
  vatId: string;
}

const OrganizationEditView: FunctionComponent = () => {
  const sysadminState = useSysadminState();
  const { organization } = sysadminState;
  const { uuid } = useParams();
  const [form, toggleForm] = useState<FormModel>({
    name: "",
    website: "",
    businessId: "",
    vatId: "",
  });

  useEffect(() => {
    if (uuid) {
      organizationEditLoad(uuid);
    }
  }, [uuid]);

  useEffect(() => {
    if (organization) {
      const { name, website, businessId, vatId } = organization;
      toggleForm({ name, website, businessId, vatId });
    }
  }, [organization]);
  if (!organization || !uuid) {
    return null;
  }

  const handleSubmitForm = async () => {
    const values: any = { ...form };

    // dispatch the action
    organizationEditSave(uuid, values);
  };

  return (
    <Container>
      <Form>
        <PageTitle title={`Edit Organization: ${organization.name}`} />

        <Row>
          <Col>
            <Label>Organization name:</Label>
            <Input
              value={form.name}
              onChange={(name) => toggleForm({ ...form, name })}
            />
          </Col>
          <Col>
            <Label>Website:</Label>
            <Input
              value={form.website}
              onChange={(website) => toggleForm({ ...form, website })}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Label>Business ID:</Label>
            <Input
              value={form.businessId}
              onChange={(businessId) => toggleForm({ ...form, businessId })}
            />
          </Col>
          <Col>
            <Label>Vat ID:</Label>
            <Input
              value={form.vatId}
              onChange={(vatId) => toggleForm({ ...form, vatId })}
            />
          </Col>
        </Row>

        <PageElement>
          <BigButton type="submit" onClick={() => handleSubmitForm()}>
            Submit changes
          </BigButton>
        </PageElement>
      </Form>
    </Container>
  );
};

export default OrganizationEditView;
