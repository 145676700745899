import AddInput from "components/add-input";
import TableBody from "components/table/body";
import TableBodyCell from "components/table/body-cell";
import TableRow from "components/table/row";
import { ProductLinkModel } from "modules/product-link/model";
import { FunctionComponent, useEffect } from "react";
import { Table } from "reactstrap";
import LinkEdit from "./link-edit";
import LinkDelete from "./link-delete";
import {
  addLink,
  removeAddedLink,
  removeInitialLink,
  reset,
  setOriginalLinks,
  updateAddedLink,
  updateInitialLink,
} from "./actions";
import { useProductLinkEditorState } from "./hook";

interface ProductLinkEditorProps {
  originalLinks: ProductLinkModel[];
}

const ProductLinkEditor: FunctionComponent<ProductLinkEditorProps> = ({
  originalLinks = [],
}) => {
  const linkEditorState = useProductLinkEditorState();

  useEffect(() => {
    setOriginalLinks(originalLinks);
    return () => {
      reset();
    };
  }, []);

  const { links, addedLinks } = linkEditorState;
  return (
    <Table style={{ tableLayout: "auto" }}>
      <TableBody>
        {[...links, ...addedLinks].map((link) => {
          return (
            <TableRow>
              <TableBodyCell textAlign="left" verticalAlign="middle">
                {link.name}
              </TableBodyCell>
              <TableBodyCell textAlign="left" fullWidth verticalAlign="middle">
                {link.url}
              </TableBodyCell>
              <LinkEdit
                name={link.name}
                url={link.url}
                onSubmit={(name, url) => {
                  if (link.uuid) {
                    updateInitialLink(link.uuid, name, url);
                  } else {
                    updateAddedLink(link.name, name, url);
                  }
                }}
              />

              <LinkDelete
                name={link.name}
                onSubmit={() => {
                  if (link.uuid) {
                    removeInitialLink(link.uuid);
                  } else {
                    removeAddedLink(link.name);
                  }
                }}
              />
            </TableRow>
          );
        })}
        <TableRow>
          <TableBodyCell colSpan={4} fullWidth textAlign="left">
            <AddInput
              text="Add new link"
              placeholder="Enter a name for the new link"
              onSave={(name) => {
                addLink({ uuid: "", name, url: "" });
              }}
            />
          </TableBodyCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default ProductLinkEditor;
