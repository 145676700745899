import {
  selectOrganization,
  selectOrganizationUnit,
} from "modules/app/actions";
import { errorNotification } from "modules/notifications/actions";
import { OrganizationUnitModel } from "modules/organization-unit/model";
import { OrganizationModel } from "modules/organization/model";
import { NavigateFunction } from "react-router";
import { getValueFromStore, store } from "redux/store";
import { getCookie, removeCookie, setCookie } from "services/cookies";
import { log } from "utils/log-utils";

export enum UserActions {
  USER_LOGIN = "USER_LOGIN",
  USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS",
  USER_LOGIN_FAILURE = "USER_LOGIN_FAILURE",

  USER_LOGOUT = "USER_LOGOUT",

  USER_LOAD = "USER_LOAD",
  USER_LOAD_SUCCESS = "USER_LOAD_SUCCESS",
  USER_LOAD_FAILURE = "USER_LOAD_FAILURE",

  USER_REFRESH_DATA = "USER_REFRESH_DATA",
  USER_REFRESH_DATA_SUCCESS = "USER_REFRESH_DATA_SUCCESS",
  MY_USER_REFRESH_DATA = "MY_USER_REFRESH_DATA",
  MY_USER_REFRESH_DATA_SUCCESS = "MY_USER_REFRESH_DATA_SUCCESS",

  USER_DELETE = "USER_DELETE",
}

export const logUserOut = (navigate: NavigateFunction) => {
  try {
    removeCookie();
    log.info("token removed");
  } catch (error) {
    log.error(error as any);
  }

  store.dispatch({ type: "APP_CLEAR" });
  store.dispatch({ type: UserActions.USER_LOGOUT });
  navigate("/");
};

export const loginUser = async (
  email: string,
  password: string,
  navigate: NavigateFunction,
) => {
  try {
    // remove cookie in the first place
    removeCookie();
  } catch (error) {
    return false;
  }

  store.dispatch({
    type: UserActions.USER_LOGIN,
    apiCall: {
      endpoint: "/token",
      method: "post",
      payload: { email, password },
      noAuth: true,
      async success(tokenData: any) {
        await setCookie(tokenData.token);
        navigate("/");
      },
      failure() {
        errorNotification(`Login failed. Please check username and password.`);
      },
    },
  });
};

export const loadMyUser = (navigate: NavigateFunction) => {
  const token = getCookie();

  if (token === "") {
    navigate("/login");
  }

  store.dispatch({
    type: UserActions.USER_LOAD,
    apiCall: {
      endpoint: `/my/user`,
      method: "get",
      payload: {},
      success: (userData: any) => {
        const { organizations, organizationUnits } = userData;

        // get current organization
        const selectedOrganizationUuid = getValueFromStore(
          "app.selectedOrganizationUuid",
        );
        const hasAccessToSelected = organizations.find(
          (o: OrganizationModel) => o.uuid === selectedOrganizationUuid,
        );
        if (!selectOrganizationUnit || !hasAccessToSelected) {
          // choose the first
          const firstOrganization = organizations[0];
          selectOrganization(firstOrganization.uuid);

          // in this case, also change the unit
          const unit = organizationUnits.find(
            (ou: OrganizationUnitModel) =>
              ou.organizationUuid === firstOrganization.uuid,
          );
          if (unit) {
            selectOrganizationUnit(unit.uuid);
          }
        }
      },
      failure: () => {
        navigate("/login");
      },
    },
  });
};

export const deleteUser = (userUuid: string) => {
  store.dispatch({
    type: UserActions.USER_DELETE,
    apiCall: {
      endpoint: `/users/${userUuid}`,
      method: "delete",
      payload: {},
      success: () => {
        log.info("User deleted succesfully");
      },
      failure: () => {},
    },
  });
};

export const userRefreshData = (uuid: string) => {
  store.dispatch({
    type: UserActions.USER_REFRESH_DATA,
    apiCall: {
      endpoint: `/users/${uuid}`,
      method: "get",
      payload: {},
      success: () => {},
      failure: () => {},
    },
  });
};

export const myUserRefreshData = () => {
  store.dispatch({
    type: UserActions.MY_USER_REFRESH_DATA,
    apiCall: {
      endpoint: `/my/user`,
      method: "get",
      payload: {},
      success: () => {},
      failure: () => {},
    },
  });
};
