import { FunctionComponent, useEffect } from "react";
import { useAppState } from "modules/app/hook";
import PageTitle from "components/page-title";
import Table from "components/table/table";
import TableRow from "components/table/row";
import TableHead from "components/table/head";
import {
  createUnit,
  deleteUnit,
  loadUnits,
  updateUnit,
} from "modules/organization-unit/actions";
import TableHeadCell from "components/table/head-cell";
import { useOrganizationUnitsState } from "modules/organization-unit/hook";
import AddInput from "components/add-input";
import _ from "lodash";
import TableBody from "components/table/body";
import TableBodyCell from "components/table/body-cell";
import OrganizationUnitDelete from "modules/organization-unit/organization-unit-delete";
import OrganizationUnitEdit from "modules/organization-unit/organization-unit-edit";

const OrganizationUnitListView: FunctionComponent<any> = () => {
  const appState = useAppState();
  const { selectedOrganizationUuid } = appState;
  const unitsState = useOrganizationUnitsState();
  const { data: units } = unitsState;

  useEffect(() => {
    if (selectedOrganizationUuid) {
      loadUnits(selectedOrganizationUuid);
    }
  }, [selectedOrganizationUuid]);

  if (!selectedOrganizationUuid) {
    return null;
  }

  const sortedUnits = _.sortBy(units, "sorting");

  return (
    <div>
      <PageTitle
        title="Organization Units"
        rightText="Add, edit and organize your organization units"
      />

      <Table>
        <TableHead>
          <TableRow>
            <TableHeadCell colSpan={3}>Organization unit</TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedUnits.map((unit) => {
            return (
              <TableRow>
                <TableBodyCell fullWidth textAlign="left">
                  {unit.name} [sorting = {unit.sorting}]
                </TableBodyCell>

                <OrganizationUnitEdit
                  name={unit.name}
                  sorting={unit.sorting}
                  onSubmit={(name, sorting) =>
                    updateUnit(selectedOrganizationUuid, unit.uuid, {
                      name,
                      sorting,
                    })
                  }
                />

                <OrganizationUnitDelete
                  name={unit.name}
                  onSubmit={() => {
                    deleteUnit(selectedOrganizationUuid, unit.uuid);
                  }}
                />
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      <div style={{ marginTop: 20 }}>
        <AddInput
          text="Add new unit"
          placeholder="Enter a name for the new unit"
          onSave={(name: string) => {
            createUnit(selectedOrganizationUuid, { name });
          }}
        />
      </div>
    </div>
  );
};

export default OrganizationUnitListView;
