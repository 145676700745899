import { FunctionComponent, useState } from "react";
import timberLogoWhite from "images/Timber_Logo_White_1000x374.png";
import {
  LogoContainer,
  MainContainer,
  StyledContainer,
  StyledFormContainer,
  StyledFormGroup,
  StyledInput,
  StyledSubmitButton,
} from "./login-view.styles";
import Form from "components/form";
import { loginUser } from "modules/user/actions";
import { useNavigate } from "react-router";
import Notifications from "components/notifications";

interface FormModel {
  email: string;
  password: string;
}

// main class
const LoginView: FunctionComponent<any> = () => {
  const navigate = useNavigate();

  const [form, toggleForm] = useState<FormModel>({
    email: "",
    password: "",
  });

  // localhost: timo.kallela@gmail.com / demo

  const handleSubmit = async () => {
    const { email, password } = form;
    loginUser(email, password, navigate);
  };

  return (
    <MainContainer>
      <StyledContainer>
        <StyledFormContainer>
          <LogoContainer>
            <img src={timberLogoWhite} alt="" />
          </LogoContainer>
          <Form>
            <StyledFormGroup>
              <StyledInput
                name="email"
                placeholder="Email"
                noHoverIcon
                type="text"
                autoComplete="username"
                value={form.email}
                onChange={(email: string) => toggleForm({ ...form, email })}
              />
              <StyledInput
                name="password"
                noHoverIcon
                placeholder="Password"
                type="password"
                autoComplete="current-password"
                value={form.password}
                onChange={(password: string) =>
                  toggleForm({ ...form, password })
                }
              />
            </StyledFormGroup>

            <StyledSubmitButton
              disabled={false}
              color="primary"
              onClick={handleSubmit}
            >
              {"LET'S TIMBER"}
            </StyledSubmitButton>
          </Form>
        </StyledFormContainer>
      </StyledContainer>
      <Notifications />
    </MainContainer>
  );
};

/* class LoginView extends Component {
  componentDidMount() {
    // we check if user attribute is provided in the url
    const { location } = this.props;
    const query = parse(location.search.substr(1));
    if (_.has(query, "user")) {
      // dispatch change action for email field
      this.props.dispatch(change(reduxFormName, "email", query.user));
    }
  }


  render() {
    const { handleSubmit } = this.props;

  }
}
 */

export default LoginView;
