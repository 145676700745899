import { ProductImageModel } from "modules/product-image/model";
import { store } from "redux/store";

export enum ProductImageEditorActions {
  PIE_INIT = "PIE_INIT",
  PIE_RESET = "PIE_RESET",
  PIE_SET_ORIGINAL_IMAGES = "PIE_SET_ORIGINAL_IMAGES",
  PIE_ADD_IMAGE = "PIE_ADD_IMAGE",
  PIE_REMOVE_ADDED_IMAGE = "PIE_REMOVE_ADDED_IMAGE",
  PIE_REMOVE_INITIAL_IMAGE = "PIE_REMOVE_INITIAL_IMAGE",
  PIE_UPDATE_UPLOAD_PROGRESS = "PIE_UPDATE_UPLOAD_PROGRESS",
  PIE_IMAGE_UPLOAD_COMPLETE = "PIE_IMAGE_UPLOAD_COMPLETE",
}

export const reset = () => {
  store.dispatch({
    type: ProductImageEditorActions.PIE_RESET,
  });
};

export const setOriginalImages = (images: ProductImageModel[]) => {
  store.dispatch({
    type: ProductImageEditorActions.PIE_SET_ORIGINAL_IMAGES,
    payload: images,
  });
};

export const addImage = (image: ProductImageModel) => {
  // file is: { identifier, file, progress}
  store.dispatch({
    type: ProductImageEditorActions.PIE_ADD_IMAGE,
    payload: image,
  });
};

export const removeAddedImage = (publicId: string) => {
  store.dispatch({
    type: ProductImageEditorActions.PIE_REMOVE_ADDED_IMAGE,
    payload: publicId,
  });
};

export const removeInitialImage = (image: ProductImageModel) => {
  store.dispatch({
    type: ProductImageEditorActions.PIE_REMOVE_INITIAL_IMAGE,
    payload: image,
  });
};

export const updateUploadProgress = (identifier: string, progress: number) => {
  store.dispatch({
    type: ProductImageEditorActions.PIE_UPDATE_UPLOAD_PROGRESS,
    payload: { identifier, progress },
  });
};

export const imageUploadComplete = (
  identifier: string,
  responseData: string
) => {
  store.dispatch({
    type: ProductImageEditorActions.PIE_IMAGE_UPLOAD_COMPLETE,
    payload: { identifier, responseData },
  });
};
