import { refreshOrganizationUnitData } from "modules/app/actions";
import { ProductModel } from "modules/product/model";
import { NavigateFunction } from "react-router";
import { store } from "redux/store";

export enum ProductEditActions {
  PRODUCT_EDIT_LOAD = "PRODUCT_EDIT_LOAD",
  PRODUCT_EDIT_LOAD_SUCCESS = "PRODUCT_EDIT_LOAD_SUCCESS",

  PRODUCT_EDIT_SAVE = "PRODUCT_EDIT_SAVE",
  // const PRODUCT_EDIT_SAVE_SUCCESS = "PRODUCT_EDIT_SAVE_SUCCESS";
  // const PRODUCT_EDIT_SAVE_FAILURE = "PRODUCT_EDIT_SAVE_FAILURE";

  PRODUCT_DELETE = "PRODUCT_DELETE",
  PRODUCT_DELETE_SUCCESS = "PRODUCT_DELETE_SUCCESS",
  PRODUCT_DELETE_FAILURE = "PRODUCT_DELETE_FAILURE",
}

export const productEditLoad = (uuid: string) => {
  store.dispatch({
    type: ProductEditActions.PRODUCT_EDIT_LOAD,
    apiCall: {
      endpoint: `/products/${uuid}`,
      method: "get",
    },
  });
};

export const productEditSave = (
  organizationUnitUuid: string,
  uuid: string,
  values: Partial<ProductModel>,
  navigate: NavigateFunction
) => {
  store.dispatch({
    type: ProductEditActions.PRODUCT_EDIT_SAVE,
    apiCall: {
      endpoint: `/products/${uuid}`,
      method: "patch",
      payload: values,
      success: () => {
        refreshOrganizationUnitData(organizationUnitUuid);
        navigate(`/products/${uuid}`);
      },
    },
  });
};

export const productDelete = (organizationUnitUuid: string, uuid: string, navigate: NavigateFunction) => {
  store.dispatch({
    type: ProductEditActions.PRODUCT_DELETE,
    apiCall: {
      endpoint: `/products/${uuid}`,
      method: "delete",
      success: () => {
        refreshOrganizationUnitData(organizationUnitUuid);
        navigate("/");
      },
    },
  });
};
