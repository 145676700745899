import styled from "styled-components";
import TableBodyCell from "./table/body-cell";

export const Container = styled.div`
  h6 {
    padding-bottom: 0px;
  }
`;

export const StyledTableBodyCell = styled(TableBodyCell)`
  min-width: 150px;
  vertical-align: middle !important;
`;
