import ScrollToTop from "components/scroll-top-top";
import { FunctionComponent } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { privateRoutes, publicRoutes } from "./routes";
import Guard from "./guard";

const Router: FunctionComponent<any> = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        {/* first open routes */}
        {publicRoutes.map((route) => {
          const { path, element } = route;
          return <Route key={path} path={path} element={element} />;
        })}
        {/* protected routes guarded */}
        {privateRoutes.map((route) => {
          const { path, element } = route;
          return (
            <Route key={path} path={path} element={<Guard>{element}</Guard>} />
          );
        })}
        {/* protected routes guarded */}
        <Route path="*" element={<div>404</div>} />;
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
