import { productListLoad } from "modules/product-list/actions";
import { store } from "redux/store";

export enum AppActions {
  APP_CLEAR = "APP_CLEAR",

  APP_ADD_ATTRIBUTE_FILTER = "APP_ADD_ATTRIBUTE_FILTER",

  APP_FILTER_SHOW = "APP_FILTER_SHOW",
  APP_FILTER_ADD_ITEM = "APP_FILTER_ADD_ITEM",
  APP_FILTER_CLEAR = "APP_FILTER_CLEAR",

  APP_FILTER_CONSTRUCTOR = "APP_FILTER_CONSTRUCTOR",

  APP_SELECT_ORGANIZATION = "APP_SELECT_ORGANIZATION",

  APP_LOAD_ORGANIZATION_DATA = "APP_LOAD_ORGANIZATION_DATA",
  APP_LOAD_ORGANIZATION_DATA_SUCCESS = "APP_LOAD_ORGANIZATION_DATA_SUCCESS",

  APP_SELECT_ORGANIZATION_UNIT = "APP_SELECT_ORGANIZATION_UNIT",
  APP_SELECT_ORGANIZATION_UNIT_SUCCESS = "APP_SELECT_ORGANIZATION_UNIT_SUCCESS",

  APP_REFRESH_ORGANIZATION_UNIT_DATA = "APP_REFRESH_ORGANIZATION_UNIT_DATA",
  APP_REFRESH_ORGANIZATION_UNIT_DATA_SUCCESS = "APP_REFRESH_ORGANIZATION_UNIT_DATA_SUCCESS",

  APP_REFRESH_ORGANIZATION_DATA = "APP_REFRESH_ORGANIZATION_DATA",
  APP_REFRESH_ORGANIZATION_DATA_SUCCESS = "APP_REFRESH_ORGANIZATION_DATA_SUCCESS",

  APP_LOAD_SYSTEM_SETTINGS = "APP_LOAD_SYSTEM_SETTINGS",
  APP_LOAD_SYSTEM_SETTINGS_SUCCESS = "APP_LOAD_SYSTEM_SETTINGS_SUCCESS",
}

export const selectOrganizationUnit = (organizationUnitUuid: string) => {
  store.dispatch({
    type: AppActions.APP_SELECT_ORGANIZATION_UNIT,
    apiCall: {
      endpoint: `/organization-units/${organizationUnitUuid}`,
      method: "get",
      success: () => {
        productListLoad(organizationUnitUuid);
      },
    },
  });
};

export const refreshOrganizationUnitData = (organizationUnitUuid: string) => {
  store.dispatch({
    type: AppActions.APP_REFRESH_ORGANIZATION_UNIT_DATA,
    apiCall: {
      endpoint: `/organization-units/${organizationUnitUuid}`,
      method: "get",
    },
  });
};

export const refreshOrganizationData = (organizationUuid: string) => {
  store.dispatch({
    type: AppActions.APP_REFRESH_ORGANIZATION_DATA,
    apiCall: {
      endpoint: `/organizations/${organizationUuid}`,
      method: "get",
    },
  });
};

export const loadSystemSettings = () => {
  store.dispatch({
    type: AppActions.APP_LOAD_SYSTEM_SETTINGS,
    apiCall: {
      endpoint: `/system-settings/`,
      method: "get",
    },
  });
};

export const selectOrganization = (organizationUuid: string) => {
  store.dispatch({
    type: AppActions.APP_SELECT_ORGANIZATION,
    payload: {
      uuid: organizationUuid,
    },
  });

  loadSystemSettings();

  store.dispatch({
    type: AppActions.APP_LOAD_ORGANIZATION_DATA,
    apiCall: {
      endpoint: `/organizations/${organizationUuid}`,
      method: "get",
    },
  });
};

export const showFilter = () => ({
  type: AppActions.APP_FILTER_SHOW,
});

export const addNewFilter = (filter: any) => ({
  type: AppActions.APP_FILTER_CONSTRUCTOR,
  payload: filter,
});

export const filterClear = () => ({
  type: AppActions.APP_FILTER_CLEAR,
});

export const addNewAttributeFilter = (id: string) => ({
  type: AppActions.APP_ADD_ATTRIBUTE_FILTER,
  payload: id,
});

export const addNewFilterItem = (filter: any) => ({
  type: AppActions.APP_FILTER_ADD_ITEM,
  payload: filter,
});
