import jwtDecode from "jwt-decode";
import { getCookie, setCookie, removeCookie } from "./cookies";
import { log } from "utils/log-utils";
import moment from "moment";

export interface TokenModel {
  uuid: string;
  exp: number;
  scope: string;
}

const getTokenExpirationDate = (token: string) => {
  try {
    const decoded: any = jwtDecode(token);

    if (!decoded.exp) {
      // no exp defined, null
      return null;
    }

    const date = moment(decoded.exp).utc();
    return date;
  } catch (error: any) {
    log.error(error);
    // probably no token at all, null
    return null;
  }
};

const isTokenExpired = (token: string) => {
  const date = getTokenExpirationDate(token);
  if (!date) {
    // for sure expired
    return true;
  }

  // compare to present
  return date.isBefore(moment());
};

export const isAuthenticated = () => {
  // Checks if there is a saved token and it's still valid
  const token = getToken();

  if (token) {
    return !isTokenExpired(token);
  }

  // no token --> not authenticated
  return false;
};

export const isAdmin = () => {
  const token = getToken();
  const decoded: TokenModel = jwtDecode(token);
  return decoded.scope === "admin";
};

export const setToken = async (token: string) => {
  await setCookie(token);
};

export const getToken = () => {
  // Retrieves the user token from cookier
  const cookie = getCookie();
  return cookie as string;
};

export const logout = () => {
  removeCookie();
};

/*   static _checkStatus(response) {
    // raises an error in case response status is not a success
    if (response.status >= 200 && response.status < 300) {
      return response;
    }
    const error = new Error(response.statusText);
    error.response = response;
    return error;
  }
 */
