import { FunctionComponent, useState } from "react";
import { Col, Row, Popover, PopoverBody } from "reactstrap";
import copyToClipboard from "copy-to-clipboard";
import moment from "moment";

import _ from "lodash";
import { useCollectorState } from "./hook";
import {
  ExpiresContainer,
  StyledContainer,
  StyledExpireDesc,
  StyledExpireText,
  StyledModule,
  StyledShareButton,
} from "./link-share.styles";
import { generateLink, removeImage } from "./actions";
import { Desktop } from "components";
import Button from "components/button";
import DatePicker from "components/datepicker";
import ProductCard from "./product-card";
import { faEnvelope, faPaperclip } from "@fortawesome/free-solid-svg-icons";

// main class
const LinkShare: FunctionComponent = () => {
  const collectorState = useCollectorState();
  const { loadedImages = [], collectorData } = collectorState;
  const [expireDate, toggleExpireDate] = useState(moment().add(30, "days"));
  const [showCopyPopover, toggleShowCopyPopover] = useState(false);

  const onCopyToClipboardClick = (text: string) => {
    copyToClipboard(text);

    toggleShowCopyPopover(true);

    setTimeout(() => toggleShowCopyPopover(false), 2000);
  };

  const doGenerateLink = () => {
    const date = expireDate.format();

    const imagesMap = loadedImages.map((image) => image.uuid);
    generateLink(date, imagesMap);
  };

  // helper constants, ugly
  const collectorLinkId = _.get(collectorData, "link", null);
  const linkGenerated = !_.isNil(collectorLinkId);
  const collectorLinkUrl = `${window.location.origin}/collector/${collectorLinkId}`;
  const hasImages = _.size(loadedImages) > 0;

  return (
    <StyledContainer>
      <Row>
        {_.size(loadedImages) > 0 ? (
          _.map(loadedImages, (image) => (
            <Col key={image.uuid} xs="12" sm="6" md="4">
              <ProductCard
                image={image}
                product={image.product}
                onRemove={() => removeImage(image.uuid)}
              />
            </Col>
          ))
        ) : (
          <Col>
            <h3>No images selected</h3>
          </Col>
        )}
      </Row>

      <StyledModule style={{ marginTop: 30 }}>
        <Row>
          <Col xs="12" md="6">
            <ExpiresContainer>
              <StyledExpireText>Link expires on</StyledExpireText>
              <DatePicker
                initialDate={expireDate}
                onDateChange={(date) => toggleExpireDate(date)}
              />
              <Desktop>
                <StyledExpireText>{expireDate.fromNow()}</StyledExpireText>
              </Desktop>
            </ExpiresContainer>
          </Col>
          <StyledExpireDesc xs="12" md="6">
            Choose an expiry date or the sharing link (30 days by defaul).
          </StyledExpireDesc>
        </Row>
        <hr />
        <Row>
          <Col>
            {collectorLinkId ? (
              <Button
                color="orange"
                onClick={() => window.open(collectorLinkUrl, "_blank")}
              >
                {collectorLinkUrl}
              </Button>
            ) : (
              <StyledShareButton
                backgroundColor="orange"
                style={{ marginTop: 10, marginBottom: 10 }}
                onClick={() => doGenerateLink()}
                disabled={!hasImages}
              >
                Click here to generate your sharing link
              </StyledShareButton>
            )}
          </Col>
        </Row>
        <hr />
        {linkGenerated && (
          <Row>
            <Col xs="12" md="4">
              <StyledShareButton
                iconPosition="left"
                icon={faPaperclip}
                onClick={() => onCopyToClipboardClick(collectorLinkUrl)}
                id="sharecopylinklol"
              >
                COPY LINK TO CLIPBOARD
              </StyledShareButton>
              <Popover
                placement="top"
                isOpen={showCopyPopover}
                target="sharecopylinklol"
                toggle={() => toggleShowCopyPopover(!showCopyPopover)}
              >
                <PopoverBody>Copied!</PopoverBody>
              </Popover>
            </Col>
            <Col xs="12" md="4">
              <a
                href={`mailto:?subject=${"Images shared from Timber"}&body=${`Link: ${collectorLinkUrl}`}`}
              >
                <StyledShareButton
                  backgroundColor="orange"
                  iconPosition="left"
                  icon={faEnvelope}
                  onClick={() => {}}
                >
                  SEND LINK VIA EMAIL
                </StyledShareButton>
              </a>
            </Col>
            <Col xs="12" md="4">
              <a href={`whatsapp://send?text=${collectorLinkUrl}`}>
                <StyledShareButton
                  backgroundColor="whatsappGreen"
                  iconPosition="left"
                  icon={["fab", "whatsapp"]}
                  onClick={() => {}}
                  active={linkGenerated}
                >
                  {"WHATSAPP"}
                </StyledShareButton>
              </a>
            </Col>
          </Row>
        )}
      </StyledModule>
    </StyledContainer>
  );
};

/*
  initialValues: {
    url: `${"https://my.timbergrades.com/"}${"jfew8f3wv08"}`,
    shareId: `${"jfew8f3wv08"}`,
  },
*/

export default LinkShare;
