import BackgroundMesh from "components/background-mesh";
import Footer from "components/footer";
import Notifications from "components/notifications";
import PlusButton from "components/plus-button";
import TopNavigation from "components/top-navigation";
import UnitSelection from "components/unit-selection";
import Filter from "modules/filter/filter";
import { list } from "modules/system-settings/actions";
import { loadMyUser } from "modules/user/actions";
import { useUserState } from "modules/user/hook";
import { Fragment, FunctionComponent, useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { isAuthenticated } from "services/auth";
import { log } from "utils/log-utils";

const Guard: FunctionComponent<any> = ({ children }) => {
  const location = useLocation();
  const userState = useUserState();
  const navigate = useNavigate();

  useEffect(() => {
    // load system settings
    list();

    // fetch user data
    loadMyUser(navigate);
  }, [navigate]);

  // check authentication
  const authenticated = isAuthenticated();
  log.info(`authenticated: ${authenticated}`);

  if (!authenticated) {
    // redirect user to login page
    return <Navigate to={"/login"} replace state={{ from: location }} />;
  }

  // show nothing, if no data yet
  if (userState.data === null) {
    return <span>loading...</span>;
  }

  // render private app layout
  return (
    <Fragment>
      <TopNavigation />
      <UnitSelection />
      {location.pathname === "/" && <Filter />}
      <BackgroundMesh>
        <Container>
          <Row>
            <Col>{children}</Col>
          </Row>
        </Container>
      </BackgroundMesh>
      {location.pathname === "/" && <PlusButton />}
      <Footer />
      <Notifications />
    </Fragment>
  );
};

export default Guard;
