import { Desktop } from "components";
import _ from "lodash";
import {
  selectOrganization,
  selectOrganizationUnit,
} from "modules/app/actions";
import { useAppState } from "modules/app/hook";
import { useUserState } from "modules/user/hook";
import { FunctionComponent } from "react";
import NavigationDropdown, { ItemModel } from "./navigation-dropdown";
import { DropdownElement, DropdownWrapper } from "./unit-selection.styles";

const OrganizationDropdown: FunctionComponent<any> = () => {
  const { selectedOrganizationData } = useAppState();
  const { data: userData } = useUserState();

  if (!userData || !selectedOrganizationData) {
    return null;
  }

  return (
    <DropdownWrapper>
      <Desktop>
        <div style={{ marginRight: 10, marginTop: 3 }}>Using as:</div>
      </Desktop>
      <DropdownElement>
        <NavigationDropdown
          title={selectedOrganizationData.name}
          items={userData.organizations.map((organization) => {
            const item: ItemModel = {
              text: organization.name,
              onClick: () => {
                // select chose org
                selectOrganization(organization.uuid);

                const newUnits = organization.organizationUnits.filter(
                  (unit) => {
                    if (userData.systemRole === "sysadmin") {
                      // accept all if sysadmin
                      return true;
                    }

                    // check users access to the unit
                    const hasAccess =
                      userData.organizationUnits.find(
                        (ou) => ou.uuid === unit.uuid
                      ) !== undefined;
                    return hasAccess;
                  }
                );

                const firstUnit = _.first(newUnits);
                if (firstUnit) {
                  selectOrganizationUnit(firstUnit.uuid);
                }
              },
            };
            return item;
          })}
        />
      </DropdownElement>
    </DropdownWrapper>
  );
};

export default OrganizationDropdown;
