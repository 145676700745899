import { FunctionComponent } from "react";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { StyledFontAwesomeIcon } from "./icon.styles";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface IconProps {
  className?: string;
  icon: IconProp;
  size?: "xs" | "sm" | "lg" | "1x" | "2x";
  onClick?: () => Promise<void> | void;
  disabled?: boolean;
  cursor?: string;
}

const Icon: FunctionComponent<IconProps> = ({
  className,
  onClick,
  cursor = "",
  icon = faSearch,
  size = "sm",
  disabled = false,
}) => {
  const handleOnClick = () => {
    if (onClick) {
      onClick();
    }
  };

  if (disabled) return null;

  return (
    <StyledFontAwesomeIcon
      cursor={cursor}
      onClick={handleOnClick}
      icon={icon}
      size={size}
      className={className}
    />
  );
};

export default Icon;
