import { store } from "redux/store";

export enum ProductViewActions {
  PRODUCT_VIEW_LOAD = "PRODUCT_VIEW_LOAD",
  PRODUCT_VIEW_LOAD_SUCCESS = "PRODUCT_VIEW_LOAD_SUCCESS",
  PRODUCT_VIEW_LOAD_FAILURE = "PRODUCT_VIEW_LOAD_FAILURE",
}

// action creators
export const productViewLoad = (uuid: string) => {
  store.dispatch({
    type: ProductViewActions.PRODUCT_VIEW_LOAD,
    apiCall: {
      endpoint: `/products/${uuid}`,
      method: "get",
      success: () => {},
      failure: () => {},
    },
  });
};
