import { Col } from "reactstrap";
import styled from "styled-components";
import mixins from "theme/mixins";

export const StyledContainer = styled.div`
  ${mixins.fontFira()};
  padding-top: 30px;
  padding-bottom: 30px;
`;

export const StyledTitleCol = styled(Col)`
  font-size: 140%;
  color: ${(props) => props.theme.darkBlue};
`;

export const StyledDescriptionCol = styled(Col)`
  ${mixins.media.tablet` text-align: left;`};

  ${mixins.fontCoda()};
  color: ${(props) => props.theme.gray800};
  font-size: 80%;
  text-transform: uppercase;
  display: flex;
  align-items: end;
  justify-content: right;
`;
