import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import BigButton from "components/big-button";
import Form from "components/form";
import TableActionCell from "components/table/action-cell";
import { FunctionComponent, useState } from "react";
import { Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";

interface OrganizationDeleteProps {
  name: string;
  onSubmit: () => void;
}

const OrganizationDeleteCell: FunctionComponent<OrganizationDeleteProps> = ({
  name,
  onSubmit,
}) => {
  const [open, toggleOpen] = useState(false);

  const handleSubmit = async () => {
    toggleModal();
    onSubmit();
  };

  const toggleModal = () => {
    toggleOpen(!open);
  };

  return (
    <TableActionCell icon={faTrashAlt} onClick={() => toggleOpen(true)}>
      <Modal size="lg" isOpen={open} toggle={() => toggleModal()}>
        <ModalHeader toggle={toggleModal}>Confirm delete</ModalHeader>
        <ModalBody>
          <Form>
            <Row>
              <Col>Are you sure you want to organization: {name}</Col>
            </Row>

            <Row style={{ marginTop: 20 }}>
              <Col>
                <BigButton
                  onClick={() => {
                    toggleModal();
                  }}
                >
                  Cancel
                </BigButton>
              </Col>
              <Col>
                <BigButton type="submit" onClick={() => handleSubmit()}>
                  Delete
                </BigButton>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </TableActionCell>
  );
};

export default OrganizationDeleteCell;
