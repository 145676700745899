import { faEdit } from "@fortawesome/free-solid-svg-icons";
import BigButton from "components/big-button";
import Checkbox from "components/checkbox";
import TableActionCell from "components/table/action-cell";
import TableBody from "components/table/body";
import TableBodyCell from "components/table/body-cell";
import TableHead from "components/table/head";
import TableHeadCell from "components/table/head-cell";
import TableRow from "components/table/row";
import Table from "components/table/table";
import _ from "lodash";
import { userToggleUnits } from "modules/organization-user/actions";
import { OrganizationModel } from "modules/organization/model";
import { UserDataModel } from "modules/user/models";
import { FunctionComponent, useState } from "react";
import { Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";

interface OrganizationUserUnitsProps {
  organization: OrganizationModel;
  user: UserDataModel;
}

const OrganizationUserUnits: FunctionComponent<OrganizationUserUnitsProps> = ({
  organization,
  user,
}) => {
  const { organizationUnits = [] } = organization;
  const [modalOpen, toggleModalOpen] = useState(false);

  // state of units user is in (in this organization)
  const [unitUuids, toggleUnitUuids] = useState(
    user.organizationUnits
      .filter((unit) => unit.organizationUuid === organization.uuid)
      .map((unit) => unit.uuid)
  );

  const handleCheckboxClick = (unitUuid: string) => {
    if (unitUuids.includes(unitUuid)) {
      toggleUnitUuids([...unitUuids].filter((uuid) => uuid !== unitUuid));
    } else {
      toggleUnitUuids([...unitUuids, unitUuid]);
    }
  };

  const userFullName = `${user.firstName} ${user.lastName}`;

  const handleSave = () => {
    userToggleUnits(organization.uuid, user.uuid, unitUuids);
    toggleModalOpen(false);
  };

  const names = user.organizationUnits
    .filter((unit) => unit.organizationUuid === organization.uuid)
    .map((unit) => unit.name)
    .join(", ");
  return (
    <TableActionCell
      textAlign="left"
      text={names}
      icon={faEdit}
      onClick={() => toggleModalOpen(true)}
    >
      <Modal
        size="lg"
        isOpen={modalOpen}
        toggle={() => toggleModalOpen(!modalOpen)}
      >
        <ModalHeader toggle={() => toggleModalOpen(!modalOpen)}>
          User: {userFullName}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <p>
                Grant access rights to different organization units for this
                user.
              </p>
              <p>
                With an access to a specific organization unit, user is able to
                see that particular organization unit and add, modify and share
                products of the unit.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table style={{ border: 1 }}>
                <TableHead>
                  <TableRow>
                    <TableHeadCell>Organization Unit</TableHeadCell>
                    <TableHeadCell textAlign="center">Access?</TableHeadCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {_.map(organizationUnits, (unit) => {
                    const hasAccess = unitUuids.includes(unit.uuid);

                    return (
                      <TableRow key={unit.uuid}>
                        <TableBodyCell textAlign="left">
                          {unit.name}
                        </TableBodyCell>

                        <TableBodyCell
                          textAlign="center"
                          verticalAlign="middle"
                        >
                          <Checkbox
                            checked={hasAccess}
                            style={{ margin: "auto" }}
                            onClick={() => handleCheckboxClick(unit.uuid)}
                          />
                        </TableBodyCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col>
              <BigButton onClick={() => toggleModalOpen(false)}>
                Cancel
              </BigButton>
            </Col>
            <Col>
              <BigButton onClick={() => handleSave()}>Save units</BigButton>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </TableActionCell>
  );
};

export default OrganizationUserUnits;
