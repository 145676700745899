import Badge from "components/badge";
import { Card } from "reactstrap";
import styled from "styled-components";
import mixins from "theme/mixins";

export const CountContainer = styled.div`
  ${mixins.fontCoda()};
  font-size: 90%;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 23px;
  margin-top: 12px;
  z-index: 9;
`;

export const Count = styled.div`
  margin-bottom: 5px;
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: ${(props) => props.theme.lightBlue};
  color: ${(props) => props.theme.white};
`;

export const StyledCard = styled(Card)`
  cursor: pointer;
  border: 0;
  flex-grow: 1;
  width: 100%;
  min-height: 100px;
  &:hover {
    box-shadow: 0px 0px 20px #d6d6d6;
  }
`;

export const StyledBadge = styled(Badge)`
  font-size: 90% !important;
`;

interface ImageProps {
  url: string;
}

export const StyledEmptyImage = styled.div<ImageProps>`
  width: "100%";
  padding-top: "66.66%";
  background-image: url(${(props) => props.url});
  background-repeat: no-repeat;
  background-size: contain;
`;
