import { FunctionComponent } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import Icon from "./icon";
import { Container } from "./small-badge.styles";

const RenderIcon: FunctionComponent<any> = ({ icon }) => {
  const style = {
    marginLeft: 10,
    display: "flex",
    marginTop: -1,
    cursor: "pointer",
  };

  return (
    <div style={style}>
      <Icon icon={icon} />
    </div>
  );
};

const RenderIconImage: FunctionComponent<any> = ({ image }) => {
  const style: any = {
    marginLeft: 10,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: -3,
  };

  return (
    <div style={style}>
      <img src={image} alt="moi" />
    </div>
  );
};

interface SmallBadgeProps {
  children: any;
  onClick: () => void;
  color: string;
  icon?: IconProp;
  iconImage?: string;
  backgroundColor?: string;
  onIconClick?: () => void;
}

const SmallBadge: FunctionComponent<SmallBadgeProps> = ({
  children,
  onClick,
  color = "orange",
  icon,
  iconImage,
  backgroundColor,
  onIconClick,
}) => {
  return (
    <Container
      color={color}
      backgroundColor={backgroundColor}
      onClick={onClick}
      role="presentation"
    >
      {children}
      <div role="presentation" onClick={onIconClick}>
        {iconImage ? (
          <RenderIconImage image={iconImage} />
        ) : (
          <RenderIcon icon={icon} />
        )}
      </div>
    </Container>
  );
};

export default SmallBadge;
