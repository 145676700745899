import { ProductImageModel } from "modules/product-image/model";
import { Fragment, FunctionComponent, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import IconAndText from "./icon-and-text";
import RequireRole from "./require-role";
import {
  ChooseImageContainer,
  ImageContainer,
  StyledDownload,
  StyledImage,
  StyledImageWrapper,
  StyledNumber,
} from "./simple-slider.styles";
import emptyImage from "images/no-image-330-230.png";
import { generateUrl } from "./image";
import { addImage } from "modules/collector/actions";
import { faCheck, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useCollectorState } from "modules/collector/hook";
import _ from "lodash";
import ImageGallery, { ReactImageGalleryItem } from "react-image-gallery";
import FsLightbox from "fslightbox-react";
import { saveAs } from "file-saver";

interface SimpleSliderProps {
  images: ProductImageModel[];
}

const SimpleSlider: FunctionComponent<SimpleSliderProps> = ({ images }) => {
  const [imgs, toggleImgs] = useState<ReactImageGalleryItem[]>([]);
  const [lightboxOpen, toggleLightboxOpen] = useState(false);
  const [currentImage, toggleCurrentImage] = useState(0);
  const navigate = useNavigate();
  const collectorState = useCollectorState();

  useEffect(() => {
    // add at least one
    if (images.length === 0) {
      toggleImgs([{ original: emptyImage }]);
    }

    // generate proper images
    const newImgs = images.map((image) => {
      const original = generateUrl(image.publicId);
      return { original };
    });
    toggleImgs(newImgs);
  }, []);

  const renderImage = (
    image: ReactImageGalleryItem,
    imageData: ProductImageModel
  ) => {
    return (
      <StyledImageWrapper>
        <StyledImage
          src={image.original}
          alt=""
          width="100%"
          onClick={() => toggleLightboxOpen(true)}
          onKeyPress={() => {}}
        />
        {imageData.number && <StyledNumber>#{imageData.number}</StyledNumber>}
      </StyledImageWrapper>
    );
  };

  const renderPdf = (image: ReactImageGalleryItem) => {
    return <iframe src={image.original} width="100%" height="400" />;
  };

  const renderItem = (
    image: ReactImageGalleryItem,
    imageData: ProductImageModel,
    inCollector: boolean
  ) => {
    const { format } = imageData;
    return (
      <ImageContainer className="image-gallery-image">
        {format === "pdf" && renderPdf(image)}
        {format !== "pdf" && renderImage(image, imageData)}

        <RequireRole requiredRole="user">
          <ChooseImageContainer>
            {inCollector ? (
              <IconAndText onClick={() => navigate("/share")} icon={faCheck}>
                Image in the collector
              </IconAndText>
            ) : (
              <IconAndText
                onClick={() => {
                  addImage(imageData.uuid);
                }}
                icon={faPlus}
              >
                Choose this image for sharing
              </IconAndText>
            )}
          </ChooseImageContainer>
          {!inCollector && images.length > 1 && (
            <ChooseImageContainer>
              <IconAndText
                onClick={() => {
                  images.forEach((image) => addImage(image.uuid));
                }}
                icon={faPlus}
              >
                Choose all ({images.length}) images for sharing
              </IconAndText>
            </ChooseImageContainer>
          )}
        </RequireRole>
      </ImageContainer>
    );
  };

  const gotoPrevious = () => {
    let newPlace = currentImage - 1;
    if (newPlace < 0) {
      newPlace = imgs.length - 1;
    }
    toggleCurrentImage(newPlace);
  };

  const gotoNext = () => {
    let newPlace = currentImage + 1;
    if (newPlace > imgs.length - 1) {
      newPlace = 0;
    }
    toggleCurrentImage(newPlace);
  };

  const { images: collectorImages } = collectorState;

  const currentImageData = images[currentImage];
  const currentImageUuid = _.get(currentImageData, "uuid", 0);

  const isImageInCollector = _.includes(collectorImages, currentImageUuid);

  const handleDownload = () => {
    saveAs(imgs[currentImage].original);
  };

  return (
    <div>
      <ImageGallery
        items={imgs}
        showPlayButton={false}
        showFullscreenButton={false}
        showThumbnails
        onThumbnailClick={(_event: any, index: number) => {
          toggleCurrentImage(index);
        }}
        renderItem={(item) =>
          renderItem(item, currentImageData, isImageInCollector)
        }
        renderRightNav={(onClick: any) => (
          <div
            className="image-gallery-right-nav"
            onClick={() => {
              onClick();
              gotoNext();
            }}
            role="button"
            tabIndex={0}
          >
            {">"}
          </div>
        )}
        renderLeftNav={(onClick: any) => (
          <div
            className="image-gallery-left-nav"
            onClick={() => {
              onClick();
              gotoPrevious();
            }}
            role="button"
            tabIndex={0}
          >
            {"<"}
          </div>
        )}
      />
      {imgs[currentImage] && lightboxOpen && (
        <Fragment>
          <FsLightbox
            toggler={true}
            openOnMount
            sources={[imgs[currentImage].original]}
            onClose={() => {
              toggleLightboxOpen(false);
            }}
          />

          <StyledDownload onClick={() => handleDownload()}>
            Download
          </StyledDownload>
        </Fragment>
      )}
    </div>
  );
};

export default SimpleSlider;
