import styled from "styled-components";

export const PlusButtonContainer = styled.div`
  flex-direction: row;
  position: fixed;
  bottom: 10px;
  right: 10px;
  justify-content: flex-end;
  cursor: pointer;
`;

export const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 460%;
  color: white;
  background-color: ${(props) => props.theme.darkBlue};
  border-radius: 50%;
  width: 60px;
  height: 60px;
`;
