import BigButton from "components/big-button";
import Form from "components/form";
import Input from "components/input";
import _ from "lodash";
import {
  errorNotification,
  successNotification,
} from "modules/notifications/actions";
import { myUserRefreshData } from "modules/user/actions";
import { useUserState } from "modules/user/hook";
import { FunctionComponent, useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { sendRequest } from "services/request";
import { log } from "utils/log-utils";
import {
  RoundedInitials,
  RoundedInitialsContainer,
} from "./profile-tab.styles";

interface FormModel {
  email: string;
  firstName: string;
  lastName: string;
}

const ProfileTab: FunctionComponent<any> = () => {
  const userState = useUserState();
  const [form, toggleForm] = useState<FormModel>({
    email: "",
    firstName: "",
    lastName: "",
  });
  const { data } = userState;

  useEffect(() => {
    if (data) {
      const { email, firstName, lastName } = data;
      toggleForm({ email, firstName, lastName });
    }
  }, [data]);

  const handleSubmit = async () => {
    try {
      const response = await sendRequest("patch", `/my/user`, form);

      if (!_.isEmpty(response)) {
        log.info({ obj: response }, "Profile update success ");
        successNotification("Profile updated!");
        myUserRefreshData();
        return;
      }
      errorNotification("Error during profile update, please try again!");
    } catch (error: any) {
      errorNotification(
        _.get(error.response.data.error, "message", "Unkown error happened.")
      );
    }
  };

  const dirty =
    form.email === "" || form.firstName === "" || form.lastName === "";

  const getInitials = () => {
    const initials = [];
    if (form.firstName) {
      initials.push(form.firstName[0]);
    }
    if (form.lastName) {
      initials.push(form.lastName[0]);
    }
    return initials.join("");
  };
  return (
    <Form>
      <Container>
        <Row>
          <Col sm="8">
            <Row>
              <Col sm="6">
                <Input
                  onChange={(firstName) => toggleForm({ ...form, firstName })}
                  value={form.firstName}
                  placeholder="First name"
                  required
                  type="text"
                />
              </Col>
              <Col sm="6">
                <Input
                  onChange={(lastName) => toggleForm({ ...form, lastName })}
                  value={form.lastName}
                  placeholder="Last name"
                  required
                  type="text"
                />
              </Col>
            </Row>
            <Row>
              <Col sm="6">
                <Input
                  onChange={(email) => toggleForm({ ...form, email })}
                  value={form.email}
                  placeholder="erkki@esimerkki.fi"
                  required
                  type="text"
                />
              </Col>
              <Col sm="6" />
            </Row>
          </Col>
          <Col sm="4">
            <RoundedInitialsContainer>
              <RoundedInitials>{getInitials()}</RoundedInitials>
            </RoundedInitialsContainer>
          </Col>
        </Row>
      </Container>
      <BigButton disabled={dirty} onClick={() => handleSubmit()}>
        Save profile changes
      </BigButton>
    </Form>
  );
};

export default ProfileTab;
