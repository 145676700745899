import styled from "styled-components";
import mixins from "theme/mixins";
import { rgba } from "polished";

export const Container = styled.div<any>`
  width: 100%;
  min-height: 190px;
  display: flex;
  justify-content: center;
  margin-bottom: 25px;

  border: 1px dashed ${(props) => props.theme.darkBlue};
  color: ${(props) => props.theme.darkBlue};
  cursor: pointer;
  ${mixins.fontCoda()};

  background-color: ${(props) =>
    props.hover && rgba(props.theme.darkBlue, 0.2)};
`;

export const ImageContainer = styled.div`
  padding-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

export const SingleImageContainer = styled.div`
  width: 150px;
  height: 150px;
  border: 1px dashed ${(props) => props.theme.darkBlue}; // 0.5

  margin-right: 20px;
  margin-bottom: 20px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const EmptyContainer = styled.div`
  width: 150px;
  height: 150px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledDropzone = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
`;
