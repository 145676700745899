import Cookies from "universal-cookie";

const cookieName = "token";

export const getCookie = () => {
  const cookies = new Cookies();
  const cookie = cookies.get(cookieName) || "";
  return cookie;
};

export const setCookie = async (value: string) => {
  const cookies = new Cookies();
  const expiryDate = new Date();
  expiryDate.setDate(expiryDate.getDate() + 14);
  return cookies.set(cookieName, value, { path: "/", expires: expiryDate });
};

export const removeCookie = async () => {
  const cookies = new Cookies();
  cookies.remove(cookieName, { path: "/" });
};
