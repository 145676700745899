import PageTitle from "components/page-title";
import TableBody from "components/table/body";
import TableBodyCell from "components/table/body-cell";
import TableHead from "components/table/head";
import TableHeadCell from "components/table/head-cell";
import TableRow from "components/table/row";
import Table from "components/table/table";
import EditInput from "components/edit-input";
import _ from "lodash";
import { useAppState } from "modules/app/hook";
import { organizationGroupUpdate } from "modules/organization-group/actions";
import { FunctionComponent } from "react";
import OrganizationGroupPermissions from "./group-permissions";

const OrganizationGroupListView: FunctionComponent<any> = () => {
  const appState = useAppState();
  const { selectedOrganizationData, selectedOrganizationUuid } = appState;

  if (selectedOrganizationData === null || selectedOrganizationUuid === null) {
    return null;
  }

  const { organizationGroups } = selectedOrganizationData;

  return (
    <div>
      <PageTitle
        title="Groups and Permissions"
        rightText="Add, edit and organize your organization groups/permissions."
      />

      {_.map(organizationGroups, (organizationGroup) => {
        return (
          <Table key={organizationGroup.uuid}>
            <TableHead>
              <TableRow>
                <TableHeadCell
                  style={{ borderRight: 0 }}
                  fullWidth
                  colSpan={2}
                  textAlign="left"
                >
                  <EditInput
                    text="Edit"
                    placeholder="Enter group name"
                    value={organizationGroup.name}
                    onSave={(name) => {
                      organizationGroupUpdate(
                        selectedOrganizationUuid,
                        organizationGroup.uuid,
                        {
                          name,
                        }
                      );
                    }}
                  />
                </TableHeadCell>

                <OrganizationGroupPermissions group={organizationGroup} />
              </TableRow>
            </TableHead>

            <TableBody>
              {_.map(organizationGroup.users, (user, userIndex) => {
                return (
                  <TableRow key={user.uuid}>
                    <TableBodyCell
                      style={{ minWidth: 50 }}
                      textAlign="center"
                      verticalAlign="middle"
                    >
                      {userIndex + 1}
                    </TableBodyCell>

                    <TableBodyCell
                      textAlign="left"
                      fullWidth
                      verticalAlign="middle"
                    >
                      {user.firstName} {user.lastName}
                    </TableBodyCell>

                    <TableBodyCell verticalAlign="middle" textAlign="center">
                      {/*                       <Modal
                        modalHeader="Confirm delete"
                        modalBody={`Are you sure you want to delete user: ${user.lastName}`}
                        confirmButton="Delete!"
                        confirmAction={() => console.log("nono")}
                        cancelButton="Cancel"
                      >
                        <StyledIcon icon={faTrashAlt} />
                      </Modal>
 */}
                    </TableBodyCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        );
      })}
    </div>
  );
};

export default OrganizationGroupListView;
