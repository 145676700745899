import styled from "styled-components";
import mixins from "theme/mixins";
import { Button, Container, FormGroup } from "reactstrap";
import timberBackground from "images/Timber_Splash_Background_1920x1229.png";
import Input from "components/input";

export const StyledContainer = styled(Container)`
  min-height: 100%; /* Fallback for browsers do NOT support vh unit */
  min-height: 100vh; /* These two lines are counted as one :-)       */

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const LogoContainer = styled.div`
  width: 210px;

  img {
    display: block;
    width: 100%;
    height: auto;
  }
`;

export const MainContainer = styled.div`
  background-color: #2c91c0;

  background: url(${timberBackground}) no-repeat center center fixed;
  background-size: cover;
`;

export const StyledFormGroup = styled(FormGroup)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledSubmitButton = styled(Button)`
  ${mixins.fontCoda()};

  width: 100%;
  margin-top: 40px;
  height: 80px;
  background-color: ${(props) => props.theme.lightBlue};
  border: none;
  color: ${(props) => props.theme.white};
  font-size: 20px;

  &:active,
  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.white};
    background-color: ${(props) => props.theme.lightBlue};
  }
`;

export const StyledFormContainer = styled(FormGroup)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 330px;

  form {
    width: 100%;
  }
`;

export const StyledInput = styled(Input)`
  ${mixins.fontCoda()};
  text-transform: inherit;
  font-size: 24px;
  margin-top: 60px;
  text-align: center;
  background-color: transparent;
  color: white;
  border-bottom: 1px solid white;
  border-top: none;
  border-right: none;
  border-left: none;
  outline: none;
  box-shadow: none;

  &:focus {
    color: white;
    background-color: transparent;
    border-bottom: 1px solid white;
    border-top: none;
    border-right: none;
    border-left: none;
    outline: none;
    box-shadow: none;
  }

  &::-webkit-input-placeholder {
    color: ${(props) => props.theme.white} !important;
  }
`;
