import { FunctionComponent, useState } from "react";
import { Modal, ModalHeader, ModalBody, Row, Col } from "reactstrap";
import _ from "lodash";
import { useFilterState } from "./hook";
import { useUserState } from "modules/user/hook";
import { changeFilteringMethod, hideSettings, toggleSettings } from "./actions";
import { log } from "utils/log-utils";
import Select from "components/select";
import BigButton from "components/big-button";
import { useNavigate } from "react-router";
import Form from "components/form";

interface FormModel {
  filteringMethod: any;
}

// main component
const FilterSettingsModal: FunctionComponent<any> = () => {
  const navigate = useNavigate();
  const [values, toggleValues] = useState<FormModel>({ filteringMethod: "" });

  const filterState = useFilterState();
  const userState = useUserState();

  if (!userState || !userState.data) {
    return null;
  }

  const handleSubmit = async () => {
    log.info({ obj: values }, "Form data: ");

    if (!_.isNil(values.filteringMethod)) {
      changeFilteringMethod(values.filteringMethod, navigate);
    }
  };

  return (
    <Modal
      size="lg"
      isOpen={filterState.showFilterSettings}
      toggle={() => toggleSettings()}
    >
      <Form>
        <ModalHeader>Filtering Settings</ModalHeader>
        <ModalBody>
          <Row>
            <Col md="6">
              <p>Friendly Description</p>
            </Col>

            <Col md="6">
              <h4>Filtering Method</h4>

              <Select
                value={values.filteringMethod}
                onChange={(filteringMethod) =>
                  toggleValues({ ...values, filteringMethod })
                }
              >
                <option value="AND">AND</option>
                <option value="OR">OR</option>
              </Select>
              <br />
            </Col>
          </Row>
          <Row>
            <Col>
              <BigButton
                onClick={() => {
                  hideSettings();
                }}
              >
                Cancel
              </BigButton>
            </Col>
            <Col>
              <BigButton onClick={() => handleSubmit()}>
                Save settings
              </BigButton>
            </Col>
          </Row>
        </ModalBody>
      </Form>
    </Modal>
  );
};

export default FilterSettingsModal;
