import { ProductModel } from "modules/product/model";
import { ProductListActions } from "./actions";

export interface ProductListStateModel {
  loading: boolean;
  products: ProductModel[];
  error: any | null;
}

// Initial state
const initialState: ProductListStateModel = {
  loading: false,
  products: [],
  error: null,
};

// Reducer
export const productListReducer = (state = initialState, action: any = {}) => {
  switch (action.type) {
    case ProductListActions.PRODUCT_LIST_LOAD_SUCCESS:
      return {
        ...state,
        products: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};
