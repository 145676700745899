import BigButton from "components/big-button";
import Form from "components/form";
import Input from "components/input";
import Label from "components/label";
import PageTitle from "components/page-title";
import Select from "components/select";
import { useAppState } from "modules/app/hook";
import { organizationUserManualAdd } from "modules/organization-user/actions";
import { FunctionComponent, useState } from "react";
import { useNavigate } from "react-router";
import { Col, Row } from "reactstrap";

interface FormModel {
  firstName: string;
  lastName: string;
  email: string;
  passwordHash: string;
  organizationGroupUuid: string | null;
}

const OrganizationUserAddView: FunctionComponent<any> = () => {
  const navigate = useNavigate();
  const [form, toggleForm] = useState<FormModel>({
    firstName: "",
    lastName: "",
    email: "",
    passwordHash: "",
    organizationGroupUuid: null,
  });

  const appState = useAppState();
  const { selectedOrganizationData } = appState;

  if (!selectedOrganizationData) {
    return null;
  }

  const { organizationGroups } = selectedOrganizationData;
  const handleSubmit = async () => {
    organizationUserManualAdd(selectedOrganizationData.uuid, form, navigate);
  };

  return (
    <Form>
      <PageTitle title="Add user manually" />
      <Row>
        <Col sm="6">
          <Label>Email address:</Label>
          <Input
            type="text"
            placeholder="erkki@esimerkki.fi"
            value={form.email}
            onChange={(email) => toggleForm({ ...form, email })}
          />
        </Col>
        <Col sm="6">
          <Label>Temporary password:</Label>
          <Input
            type="text"
            placeholder="Temporary password"
            value={form.passwordHash}
            onChange={(passwordHash) => toggleForm({ ...form, passwordHash })}
          />
        </Col>
      </Row>
      <Row>
        <Col sm="6">
          <Label>First name:</Label>
          <Input
            type="text"
            placeholder="First Name"
            value={form.firstName}
            onChange={(firstName) => toggleForm({ ...form, firstName })}
          />
        </Col>
        <Col sm="6">
          <Label>Last name:</Label>
          <Input
            type="text"
            placeholder="Last Name"
            value={form.lastName}
            onChange={(lastName) => toggleForm({ ...form, lastName })}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <Select
            label="Role"
            onChange={(organizationGroupUuid: string) =>
              toggleForm({ ...form, organizationGroupUuid })
            }
          >
            <option>--</option>
            {organizationGroups.map((group) => (
              <option key={group.uuid} value={group.uuid}>
                {group.name}
              </option>
            ))}
          </Select>
        </Col>
      </Row>

      <Row style={{ marginTop: 30 }}>
        <Col>
          <BigButton onClick={() => handleSubmit()}>
            Add new user
          </BigButton>
        </Col>
      </Row>
    </Form>
  );
};

export default OrganizationUserAddView;
