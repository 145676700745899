import { SystemSettingsActions } from "./actions";
import { SystemSettingsModel } from "./model";

export interface SystemSettingsStateModel {
  data: SystemSettingsModel[] | null;
  loading: boolean;
  error: null;
}

// Initial state
const initialState: SystemSettingsStateModel = {
  data: null,
  loading: false,
  error: null, // not used
};

// Reducer
export const systemSettingsReducer = (
  state = initialState,
  action: any = {}
) => {
  switch (action.type) {
    case SystemSettingsActions.SYSTEM_SETTINGS_LIST:
      return {
        ...state,
        loading: true,
      };

    case SystemSettingsActions.SYSTEM_SETTINGS_LIST_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};
