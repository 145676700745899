import { FunctionComponent } from "react";
import _ from "lodash";
import { Desktop } from "components";
import crossRed from "images/cross-red.png";
import whiteCross from "images/cross-white.png";
import { Container, LeftSide, RightSide } from "./filter-bottom-row.styles";
import { useFilterState } from "modules/filter/hook";
import { useAppState } from "modules/app/hook";
import {
  filterClear,
  filterHide,
  filterRemoveAttribute,
  filterShow,
  showSettings,
} from "modules/filter/actions";
import SmallBadge from "components/small-badge";
import FilterSettingsModal from "./settings-modal";
import {
  faAngleDown,
  faAngleUp,
  faSlidersH,
} from "@fortawesome/free-solid-svg-icons";

const FilterBottomRow: FunctionComponent<any> = () => {
  const filterState = useFilterState();
  const appState = useAppState();

  const { selectedOrganizationUnitData } = appState;

  if (!selectedOrganizationUnitData) {
    return null;
  }

  const allAttributes = _.flatMap(
    selectedOrganizationUnitData.attributeGroups,
    (group) => group.attributes
  );

  const activeAttributes = _.filter(allAttributes, (attribute) => {
    return filterState.selectedAttributes.includes(attribute.uuid);
  });

  const filtersActive = filterState.selectedAttributes.length > 0;

  return (
    <Container>
      <LeftSide>
        {_.map(activeAttributes, (attribute) => (
          <SmallBadge
            key={`active-attribute-${attribute.uuid}`}
            iconImage={crossRed}
            color="white"
            backgroundColor="orange"
            onClick={() => filterRemoveAttribute(attribute.uuid)}
          >
            {attribute.name}
          </SmallBadge>
        ))}
        {filtersActive && (
          <Desktop>
            <SmallBadge
              iconImage={whiteCross}
              color="white"
              backgroundColor="red"
              onClick={() => filterClear()}
            >
              Clear All Filters
            </SmallBadge>
          </Desktop>
        )}
      </LeftSide>
      <RightSide>
        <SmallBadge
          icon={faSlidersH}
          color="white"
          onClick={() => showSettings()}
        >
          Filter Settings
        </SmallBadge>

        <FilterSettingsModal />

        {filterState.showFilter ? (
          <SmallBadge
            icon={faAngleUp}
            color="white"
            onClick={() => filterHide()}
          >
            Hide filters
          </SmallBadge>
        ) : (
          <SmallBadge
            icon={faAngleDown}
            color="white"
            backgroundColor="transparent"
            onClick={() => filterShow()}
          >
            Show filters
          </SmallBadge>
        )}
      </RightSide>
    </Container>
  );
};

export default FilterBottomRow;
