import styled from "styled-components";
import mixins from "theme/mixins";
import Icon from "./icon";

export const iconSizeList: any = {
  xs: {
    font: "10px",
    dimension: "16px",
  },
  sm: {
    font: "11px",
    dimension: "18px",
  },
  lg: {
    font: "12px",
    dimension: "24px",
  },
  "2x": {
    font: "13px",
    dimension: "24px",
  },
};

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  ${mixins.fontCoda()};

  outline: none;

  color: ${(props) => props.theme.darkBlue};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const StyledIcon = styled(Icon)<any>`
  width: ${(props) => iconSizeList[props.size as any].dimension} !important;
  height: ${(props) => iconSizeList[props.size as any].dimension} !important;
  border: ${(props) => ((props.noIconBorder as any) ? "0px" : "1px")} solid
    ${(props) => props.theme[props.color as any]};
  border-radius: 50%;
`;

export const BeforeToggleText = styled.div<any>`
  padding-left: 10px;
  font-size: ${(props) => iconSizeList[props.size as any].font} !important;
  color: ${(props) => props.theme[props.color as any]};
  margin-bottom: -4px;
`;

export const ToggledIconText = styled.div<any>`
  font-size: ${(props) => iconSizeList[props.size as any].font} !important;
  color: ${(props) => props.theme[props.color as any]};
`;
