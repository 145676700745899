import BigButton from "components/big-button";
import Form from "components/form";
import Input from "components/input";
import Label from "components/label";
import _ from "lodash";
import {
  errorNotification,
  successNotification,
} from "modules/notifications/actions";
import { FunctionComponent, useState } from "react";
import { useNavigate } from "react-router";
import { Col, Container } from "reactstrap";
import { setCookie } from "services/cookies";
import { sendRequest } from "services/request";
import { CenteredRow } from "./password-tab.styles";

interface FormModel {
  oldPassword: string;
  newPassword: string;
  newPasswordConfirmed: string;
}

const PasswordTab: FunctionComponent<any> = () => {
  const navigate = useNavigate();
  const [form, toggleForm] = useState<FormModel>({
    oldPassword: "",
    newPassword: "",
    newPasswordConfirmed: "",
  });

  const handleSubmit = async () => {
    try {
      const response = await sendRequest("post", `/my/password`, form);

      if (_.get(response, "token", false)) {
        await setCookie(response.token);
        successNotification("Password changed!");
        navigate(`/profile`);
        return;
      }

      errorNotification("Error during password change, please try again!");
    } catch (error: any) {
      errorNotification(
        _.get(error.response.data.error, "message", "Unkown error happened.")
      );
    }
  };

  return (
    <div>
      <Form>
        <Container>
          <CenteredRow>
            <Col sm="6">
              <Label>Current Password:</Label>
              <Input
                name="oldPassword"
                required
                placeholder="Current Password"
                type="password"
                value={form.oldPassword}
                onChange={(oldPassword) => toggleForm({ ...form, oldPassword })}
              />
            </Col>
            <Col sm="6">
              <Label>New Password:</Label>
              <Input
                name="newPassword"
                required
                placeholder="New Password"
                type="password"
                value={form.newPassword}
                onChange={(newPassword) => toggleForm({ ...form, newPassword })}
              />
            </Col>
          </CenteredRow>
          <CenteredRow>
            <Col sm="6">
              <Label>Confirm New Password:</Label>
              <Input
                name="newPasswordConfirmed"
                required
                placeholder="Confirm New Password"
                type="password"
                value={form.newPasswordConfirmed}
                onChange={(newPasswordConfirmed) =>
                  toggleForm({ ...form, newPasswordConfirmed })
                }
              />
            </Col>
          </CenteredRow>
        </Container>
        <BigButton onClick={handleSubmit}>Change Password</BigButton>
      </Form>
    </div>
  );
};

export default PasswordTab;
