// styles
import { Collapse, Navbar } from "reactstrap";
import styled from "styled-components";
import mixins from "theme/mixins";

export const StyledNavbar = styled(Navbar)`
  padding-top: 10px !important;
  padding-bottom: 10px !important;
`;

export const OrganizationLogo = styled.div`
  position: absolute;
  left: 30px;
  top: 0px;
  height: 60px;
  width: 120px;

  img {
    vertical-align: inherit;
  }

  div {
    vertical-align: inherit;
  }
`;

export const StyledContainer = styled.div`
  ${mixins.fontFira()};
  background-color: ${(props) => props.theme.white};
  a {
    font-weight: 300;
  }
`;

export const StyledCollapse = styled(Collapse)`
  justify-content: right;

  li {
    display: flex;
    align-items: center;
  }
`;
