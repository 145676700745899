import { NavLink } from "reactstrap";
import styled, { css } from "styled-components";

export const StyledNavLink = styled(NavLink)`
  color: ${(props) => props.theme.lightBlue} !important;
  font-size: 90%;
  margin-right: 10px;

  ${(props) =>
    props.selected &&
    css`
      border-bottom: 1px solid ${props.theme.lightBlue} !important;
    `};

  ${(props) =>
    props.color &&
    css`
      color: ${props.theme[props.color]} !important;
      border-bottom-color: ${props.theme[props.color]} !important;
    `};
`;
