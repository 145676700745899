import { useEffect, Fragment, FunctionComponent } from "react";
import { useLocation } from "react-router";

const ScrollToTop: FunctionComponent<any> = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    // every time location changes, scroll to top
    window.scrollTo(0, 0);
  }, [location]);

  return <Fragment>{children}</Fragment>;
};

export default ScrollToTop;
