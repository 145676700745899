import { refreshOrganizationData } from "modules/app/actions";
import { successNotification } from "modules/notifications/actions";
import { store } from "redux/store";

export enum SysadminActions {
  ORGANIZATIONS_FETCH = "ORGANIZATIONS_FETCH",
  ORGANIZATIONS_FETCH_SUCCESS = "ORGANIZATIONS_FETCH_SUCCESS",
  ORGANIZATIONS_FETCH_FAILURE = "ORGANIZATIONS_FETCH_FAILURE",

  ORGANIZATION_UPDATE = "ORGANIZATION_UPDATE",
  ORGANIZATION_UPDATE_SUCCESS = "ORGANIZATION_UPDATE_SUCCESS",
  ORGANIZATION_UPDATE_FAILURE = "ORGANIZATION_UPDATE_FAILURE",

  ORGANIZATION_CREATE = "ORGANIZATION_CREATE",
  ORGANIZATION_CREATE_SUCCESS = "ORGANIZATION_CREATE_SUCCESS",
  ORGANIZATION_CREATE_FAILURE = "ORGANIZATION_CREATE_FAILURE",

  ORGANIZATION_DELETE = "ORGANIZATION_DELETE",
  ORGANIZATION_DELETE_SUCCESS = "ORGANIZATION_DELETE_SUCCESS",
  ORGANIZATION_DELETE_FAILURE = "ORGANIZATION_DELETE_FAILURE",

  ORGANIZATION_EDIT_LOAD = "ORGANIZATION_EDIT_LOAD",
  ORGANIZATION_EDIT_LOAD_SUCCESS = "ORGANIZATION_EDIT_LOAD_SUCCESS",
  ORGANIZATION_EDIT_LOAD_FAILURE = "ORGANIZATION_EDIT_LOAD_FAILURE",

  ORGANIZATION_EDIT_SAVE = "ORGANIZATION_EDIT_SAVE",
  ORGANIZATION_EDIT_SAVE_SUCCESS = "ORGANIZATION_EDIT_SAVE_SUCCESS",
  ORGANIZATION_EDIT_SAVE_FAILURE = "ORGANIZATION_EDIT_SAVE_FAILURE",
}

export const organizationsFetch = () => {
  store.dispatch({
    type: SysadminActions.ORGANIZATIONS_FETCH,
    apiCall: {
      endpoint: `/organizations/`,
      method: "get",
    },
  });
};

export const organizationUpdate = (uuid: string, dataObject: any) => {
  store.dispatch({
    type: SysadminActions.ORGANIZATION_UPDATE,
    apiCall: {
      endpoint: `/organizations/${uuid}`,
      payload: dataObject,
      method: "patch",
      success: () => {
        refreshOrganizationData(uuid);
      },
    },
  });
};

export const organizationCreate = (dataObject: any) => {
  const payload = dataObject;

  store.dispatch({
    type: SysadminActions.ORGANIZATION_CREATE,
    apiCall: {
      endpoint: `/organizations/`,
      payload,
      method: "post",
      success: () => {
        organizationsFetch();
      },
    },
  });
};

export const organizationDelete = (uuid: string) => {
  store.dispatch({
    type: SysadminActions.ORGANIZATION_DELETE,
    apiCall: {
      endpoint: `/organizations/${uuid}`,
      method: "delete",
      success: () => {
        organizationsFetch();
      },
    },
  });
};

export const organizationEditLoad = (organizationUuid: string) => {
  store.dispatch({
    type: SysadminActions.ORGANIZATION_EDIT_LOAD,
    apiCall: {
      endpoint: `/organizations/${organizationUuid}`,
      method: "get",
    },
  });
};

export const organizationEditSave = (
  organizationUuid: string,
  dataObject: any
) => {
  const payload = dataObject;

  store.dispatch({
    type: SysadminActions.ORGANIZATION_EDIT_SAVE,
    apiCall: {
      endpoint: `/organizations/${organizationUuid}`,
      payload,
      method: "patch",
      success: () => {
        successNotification("Organization updated");
        organizationEditLoad(organizationUuid);
      },
    },
  });
};
