import { FunctionComponent } from "react";
import { useAppState } from "modules/app/hook";
import { useUserState } from "modules/user/hook";
import { UserRoleType } from "modules/user/models";

interface RequireRoleProps {
  children: any;
  requiredRole: UserRoleType;
}

const RequireRole: FunctionComponent<RequireRoleProps> = ({
  requiredRole,
  children,
}) => {
  const user = useUserState();
  const app = useAppState();
  const { data } = user;

  if (!data) {
    // no data available ==> no access
    return null;
  }

  // sysadmin gets returned immediately
  if (data.systemRole === "sysadmin") {
    return children;
  }

  // if admin/user is required && user is admin
  if (
    data.organizations.filter((org) => {
      if (org.uuid === app.selectedOrganizationUuid) {
        // user is required, but we are admins
        if (requiredRole === "user") {
          if (["user", "admin"].includes(org.userRole)) {
            return true;
          }
        }

        if (requiredRole === "admin" && org.userRole === "admin") {
          return true;
        }
      }

      // no match by default
      return false;
    }).length > 0
  ) {
    return children;
  }

  // not allowed
  return null;
};

export default RequireRole;
