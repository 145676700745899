import { Fragment, FunctionComponent, useEffect, useState } from "react";
import _ from "lodash";
import { batchUpdate, list } from "modules/system-settings/actions";
import { useSystemSettingsState } from "modules/system-settings/hook";
import PageTitle from "components/page-title";
import Form from "components/form";
import { Col, Row } from "reactstrap";
import BigButton from "components/big-button";
import Input from "components/input";
import Label from "components/label";

const SystemSettingsTab: FunctionComponent = () => {
  const systemSettingsState = useSystemSettingsState();
  const { data } = systemSettingsState;
  const [form, toggleForm] = useState<any>({});

  useEffect(() => {
    if (!data) {
      // fetch settings, if not available
      list();
    } else {
      // create initial data
      const newForm: any = {};
      data.forEach((setting) => {
        newForm[setting.uuid] = setting.value;
      });
      toggleForm(newForm);
    }
  }, [data]);

  if (!data) {
    return null;
  }

  const handleSubmit = async () => {
    batchUpdate(form);
  };

  return (
    <Fragment>
      <PageTitle title="System Settings" rightText="" />
      <Form>
        {_.map(data, (systemSetting) => (
          <Row key={systemSetting.uuid}>
            <Col>
              <Label>
                {systemSetting.name} [{systemSetting.type}]
              </Label>
              <Input
                value={JSON.stringify(systemSetting.value)}
                onChange={(value) =>
                  toggleForm({ ...form, [systemSetting.uuid]: value })
                }
              />
            </Col>
          </Row>
        ))}

        <Row>
          <Col>
            <BigButton onClick={() => handleSubmit()}>Save settings</BigButton>
          </Col>
        </Row>
      </Form>
    </Fragment>
  );
};

export default SystemSettingsTab;
