import { NotificationActions } from "./actions";

export interface NotificationModel {
  uuid: string;
  type: "SUCCESS" | "INFO" | "ERROR";
  title: string;
  message?: string;
}

export interface NotificationStateModel {
  items: NotificationModel[];
}

const initialState: NotificationStateModel = {
  items: [],
};

// Reducer
export const notificationsReducer = (
  state = initialState,
  action: any = {}
) => {
  switch (action.type) {
    case NotificationActions.ADD_NOTIFICATION: {
      const { items } = state;
      return {
        ...state,
        items: [...items, action.payload],
      };
    }

    case NotificationActions.HIDE_NOTIFICATION: {
      const uuid = action.payload;
      const newArray = state.items.filter(
        (notification) => notification.uuid !== uuid
      );

      return {
        ...state,
        items: newArray,
      };
    }

    default:
      return state;
  }
};
