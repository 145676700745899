import { Desktop } from "components";
import { selectOrganizationUnit } from "modules/app/actions";
import { useAppState } from "modules/app/hook";
import { useUserState } from "modules/user/hook";
import { FunctionComponent } from "react";
import NavigationDropdown, { ItemModel } from "./navigation-dropdown";
import { DropdownElement, DropdownWrapper } from "./unit-selection.styles";

const UnitDropdown: FunctionComponent<any> = () => {
  const appState = useAppState();
  const userState = useUserState();
  const { selectedOrganizationData, selectedOrganizationUnitData } = appState;
  const { data: userData } = userState;

  if (!userData || !selectedOrganizationData || !selectedOrganizationUnitData) {
    return null;
  }

  // parse units
  let units = [];
  if (userData.systemRole === "sysadmin") {
    // parse all units
    const { organizationUnits } = selectedOrganizationData;
    units = organizationUnits;
  } else {
    // only unit of selected organization
    units = userData.organizationUnits.filter((unit) => {
      if (unit.organizationUuid === selectedOrganizationData.uuid) {
        return true;
      }
    });
  }

  return (
    <DropdownWrapper>
      <Desktop>
        <div style={{ marginRight: 10, marginTop: 3 }}>Organization Unit:</div>
      </Desktop>
      <DropdownElement>
        <NavigationDropdown
          title={selectedOrganizationUnitData.name}
          items={units.map((unit: any) => {
            const item: ItemModel = {
              text: unit.name,
              onClick: () => {
                selectOrganizationUnit(unit.uuid);
              },
            };
            return item;
          })}
        />
      </DropdownElement>
    </DropdownWrapper>
  );
};

export default UnitDropdown;
