// styles
import styled from "styled-components";
import mixins from "theme/mixins";

export const Container = styled.div<any>`
  ${mixins.fontCoda()};
  min-width: 70px;
  color: ${(props) => props.theme.darkBlue};
  margin-bottom: 12px;
`;
