import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import BigButton from "components/big-button";
import Form from "components/form";
import IconAndText from "components/icon-and-text";
import Input from "components/input";
import Label from "components/label";
import RequireRole from "components/require-role";
import TableActionCell from "components/table/action-cell";
import {
  deleteUser,
  organizationUserUpdate,
  removeUserFromOrganization,
} from "modules/organization-user/actions";
import { OrganizationModel } from "modules/organization/model";
import { UserDataModel } from "modules/user/models";
import { FunctionComponent, useState } from "react";
import { Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";

interface OrganizationUserEditProps {
  organization: OrganizationModel;
  user: UserDataModel;
}

interface FormModel {
  firstName: string;
  lastName: string;
  email: string;
}

const OrganizationUserEdit: FunctionComponent<OrganizationUserEditProps> = ({
  organization,
  user,
}) => {
  const [modalOpen, toggleModalOpen] = useState(false);

  // form state
  const [form, toggleForm] = useState<FormModel>({
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
  });

  const handleSubmit = async () => {
    const { firstName, lastName, email } = form;
    organizationUserUpdate(organization.uuid, user.uuid, {
      firstName,
      lastName,
      email,
    });
  };

  const valid =
    form.email !== "" && form.firstName !== "" && form.lastName !== "";

  return (
    <TableActionCell
      textAlign="center"
      icon={faEdit}
      onClick={() => toggleModalOpen(true)}
    >
      <Modal
        size="lg"
        isOpen={modalOpen}
        toggle={() => toggleModalOpen(!modalOpen)}
      >
        <ModalHeader toggle={() => toggleModalOpen(!modalOpen)}>
          Edit user
        </ModalHeader>
        <ModalBody>
          <Form>
            <Row>
              <Col sm="6">
                <Label>First name:</Label>
                <Input
                  type="text"
                  placeholder="First Name"
                  value={form.firstName}
                  onChange={(firstName) => toggleForm({ ...form, firstName })}
                />
              </Col>
              <Col sm="6">
                <Label>Last name:</Label>
                <Input
                  type="text"
                  placeholder="Last Name"
                  value={form.lastName}
                  onChange={(lastName) => toggleForm({ ...form, lastName })}
                />
              </Col>
            </Row>
            <Row>
              <Col sm="6">
                <Label>Email:</Label>
                <Input
                  type="text"
                  placeholder="erkki@esimerkki.fi"
                  value={form.email}
                  onChange={(email) => toggleForm({ ...form, email })}
                />
              </Col>
              <Col sm="6"></Col>
            </Row>
            <Row>
              <Col>
                <BigButton onClick={() => toggleModalOpen(false)}>
                  Cancel
                </BigButton>
              </Col>
              <Col>
                <BigButton disabled={!valid} onClick={() => handleSubmit()}>
                  Save changes
                </BigButton>
              </Col>
            </Row>
            <Row>
              <Col>
                <RequireRole requiredRole="sysadmin">
                  <IconAndText
                    color="red"
                    icon={faTrashAlt}
                    toggle
                    toggleText="Are you sure?"
                    onClick={() => {
                      deleteUser(organization.uuid, user.uuid);
                      toggleModalOpen(false);
                    }}
                  >
                    Remove User completely? (sysadmin action)
                  </IconAndText>
                  <br />
                </RequireRole>
                <IconAndText
                  color="red"
                  icon={faTrashAlt}
                  toggle
                  toggleText="Are you sure?"
                  onClick={() => {
                    removeUserFromOrganization(organization.uuid, user.uuid);
                    toggleModalOpen(false);
                  }}
                >
                  Remove User from the organization?
                </IconAndText>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </TableActionCell>
  );
};

export default OrganizationUserEdit;
