import styled from "styled-components";
import mixins from "theme/mixins";

export const Wrapper = styled.div`
  margin-top: 30px;
  background-color: white;
  padding: 30px;
  ${mixins.fontCoda()};
  h4 {
    color: ${(props) => props.theme.darkBlue};
  }
`;

export const StyledTitle = styled.h4`
  ${mixins.media.tablet` padding-top: 20px;`};
`;
