import { refreshOrganizationUnitData } from "modules/app/actions";
import { successNotification } from "modules/notifications/actions";
import { store } from "redux/store";

export enum AttributeActions {
  ATTRIBUTE_CREATE = "ATTRIBUTE_CREATE",

  ATTRIBUTE_UPDATE = "ATTRIBUTE_UPDATE",
  ATTRIBUTE_UPDATE_SUCCESS = "ATTRIBUTE_UPDATE_SUCCESS",
  ATTRIBUTE_UPDATE_FAILURE = "ATTRIBUTE_UPDATE_FAILURE",

  ATTRIBUTE_DELETE = "ATTRIBUTE_DELETE",
  ATTRIBUTE_DELETE_SUCCESS = "ATTRIBUTE_DELETE_SUCCESS",
  ATTRIBUTE_DELETE_FAILURE = "ATTRIBUTE_DELETE_FAILURE",
}

export const attributeUpdate = (organizationUnitUuid: string, uuid: string, dataObject: any) => {
  store.dispatch({
    type: AttributeActions.ATTRIBUTE_UPDATE,
    apiCall: {
      endpoint: `/attributes/${uuid}`,
      payload: dataObject,
      method: "patch",
      success: () => {
        successNotification("Attribute updated");
        refreshOrganizationUnitData(organizationUnitUuid);
      },
    },
  });
};

export const attributeCreate = (
  organizationUnitUuid: string,
  attributeGroupUuid: string,
  dataObject: any
) => {
  const payload = dataObject;
  // inject unitId into payload
  payload.attributeGroupUuid = attributeGroupUuid;

  store.dispatch({
    type: AttributeActions.ATTRIBUTE_CREATE,
    apiCall: {
      endpoint: `/attributes`,
      payload,
      method: "post",
      success: () => {
        successNotification("Attribute created");
        refreshOrganizationUnitData(organizationUnitUuid);
      },
    },
  });
};

export const attributeDelete = (organizationUnitUuid: string, uuid: string) => {
  store.dispatch({
    type: AttributeActions.ATTRIBUTE_DELETE,
    apiCall: {
      endpoint: `/attributes/${uuid}`,
      method: "delete",
      success: () => {
        successNotification("Attribute successfully deleted!");
        refreshOrganizationUnitData(organizationUnitUuid);
      },
    },
  });
};
