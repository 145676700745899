import { FunctionComponent } from "react";
import { Col, Container } from "reactstrap";
import {
  Logo,
  StyledCol,
  StyledContainer,
  StyledContainerRow,
  StyledRow,
} from "./footer.styles";
import timberLogoText from "images/timber-logo-text.png";
import moment from "moment";

const Footer: FunctionComponent = () => {
  return (
    <StyledContainer fluid>
      <Container>
        <StyledContainerRow>
          <Col sm="12" md="3">
            <StyledRow>
              <StyledCol md="4">
                <Logo src={timberLogoText} alt="" />{" "}
              </StyledCol>
            </StyledRow>
          </Col>
          <Col sm="12" md="9">
            <div>
              © {moment().year()}.{" "}
              <a href="mailto:support@timbergrades.com">
                support@timbergrades.com
              </a>
            </div>
          </Col>
        </StyledContainerRow>
      </Container>
    </StyledContainer>
  );
};

export default Footer;
