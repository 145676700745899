import { FunctionComponent, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  NavbarToggler,
  NavbarBrand,
  Nav,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

// images
import imageTimberLogo from "images/timber-logo-120-60.png";
import defaultAvatar from "images/default-avatar.png";

// action creators
import { logUserOut } from "modules/user/actions";
import {
  OrganizationLogo,
  StyledCollapse,
  StyledContainer,
  StyledNavbar,
} from "./top-navigation.styles";
import RequireRole from "./require-role";
import { useCollectorState } from "modules/collector/hook";
import theme from "theme/theme";
import CustomNavItem from "./custom-nav-item";
import { useAppState } from "modules/app/hook";
import Image from "./image";

const TopNavigation: FunctionComponent = () => {
  const [mobileIsOpen, toggleMobileIsOpen] = useState(false);
  const [dropdownIsOpen, toggleDropdownIsOpen] = useState(false);
  const collector = useCollectorState();
  const appState = useAppState();
  const navigate = useNavigate();
  const { selectedOrganizationUnitData } = appState;

  const logout = (event: any) => {
    event.preventDefault();
    logUserOut(navigate);
  };

  const collapseHide = (event: any) => {
    const classes = event.target.className;
    if (mobileIsOpen === true && !classes.includes("dropdown-toggle")) {
      toggleMobileIsOpen(false);
    }
  };

  const renderNavBarToggler = () => {
    const { images } = collector;

    if (images.length === 0) {
      return (
        <NavbarToggler onClick={() => toggleMobileIsOpen(!mobileIsOpen)} />
      );
    }

    return (
      <NavbarToggler
        className="toggle-orange"
        style={{ borderColor: theme.orange }}
        onClick={() => toggleMobileIsOpen(!mobileIsOpen)}
      />
    );
  };

  const renderCollectorText = () => {
    const { images } = collector;

    if (images.length === 0) {
      return (
        <CustomNavItem to="/share" matchPath="/share">
          Share images
        </CustomNavItem>
      );
    }

    return (
      <CustomNavItem color="orange" to="/share" matchPath="/share">
        {`Share ${images.length} images`}
      </CustomNavItem>
    );
  };

  return (
    <StyledContainer>
      <StyledNavbar light expand="md">
        <NavbarBrand tag={Link} to="/">
          <OrganizationLogo>
            {selectedOrganizationUnitData &&
            selectedOrganizationUnitData.logo ? (
              <Image publicId={selectedOrganizationUnitData.logo} />
            ) : (
              <img src={imageTimberLogo} height="100%" alt="" />
            )}
          </OrganizationLogo>
        </NavbarBrand>
        {renderNavBarToggler()}

        <StyledCollapse onClick={collapseHide} isOpen={mobileIsOpen} navbar>
          <Nav className="ml-auto" navbar horizontal="end">
            <CustomNavItem to="/" matchPath="wat">
              Products
            </CustomNavItem>

            <RequireRole requiredRole="user">
              <CustomNavItem to="/new" matchPath="/new">
                New Product
              </CustomNavItem>
            </RequireRole>

            <RequireRole requiredRole="admin">
              <CustomNavItem to="/attributes" matchPath="/attributes">
                Attributes
              </CustomNavItem>
            </RequireRole>

            <RequireRole requiredRole="admin">
              <CustomNavItem to="/units" matchPath="/units">
                Organization Units
              </CustomNavItem>
            </RequireRole>

            <RequireRole requiredRole="admin">
              <CustomNavItem to="/users" matchPath="/users">
                Users
              </CustomNavItem>
            </RequireRole>

            <RequireRole requiredRole="admin">
              <CustomNavItem to="/groups" matchPath="/groups">
                Groups
              </CustomNavItem>
            </RequireRole>

            <RequireRole requiredRole="user">
              {renderCollectorText()}
            </RequireRole>

            <Dropdown
              isOpen={dropdownIsOpen}
              toggle={() => toggleDropdownIsOpen(!dropdownIsOpen)}
            >
              <DropdownToggle nav caret>
                <img
                  src={defaultAvatar}
                  alt=""
                  width="24"
                  height="24"
                  style={{ marginRight: 0 }}
                />
              </DropdownToggle>

              <DropdownMenu end>
                <DropdownItem
                  tag={Link}
                  to="/profile"
                  onClick={() => navigate("/profile")}
                >
                  My Profile
                </DropdownItem>

                <RequireRole requiredRole="sysadmin">
                  {<DropdownItem divider />}

                  <DropdownItem
                    tag={Link}
                    to="/sysadmin"
                    onClick={() => navigate("/sysadmin")}
                  >
                    Sysadmin
                  </DropdownItem>
                </RequireRole>

                {<DropdownItem divider />}

                <DropdownItem tag={Link} to="/" onClick={logout}>
                  Logout
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </Nav>
        </StyledCollapse>
      </StyledNavbar>
    </StyledContainer>
  );
};

export default TopNavigation;
