import { UserDataModel } from "modules/user/models";
import { OrganizationUsersActions } from "./actions";

export interface OrganizationUsersStateModel {
  loading: boolean;
  loadedUsers: UserDataModel[];
  error: null;
}

const initialState: OrganizationUsersStateModel = {
  loading: false,
  loadedUsers: [],
  error: null, // not used
};

export const organizationUsersReducer = (
  state = initialState,
  action: any = {}
) => {
  switch (action.type) {
    case OrganizationUsersActions.ORGANIZATION_USERS_LOAD:
      return {
        ...state,
        loading: true,
      };

    case OrganizationUsersActions.ORGANIZATION_USERS_LOAD_SUCCESS:
      return {
        ...state,
        loadedUsers: action.payload.users,
      };

    case OrganizationUsersActions.ORGANIZATION_USERS_LOAD_FAILURE:
      return {
        ...state,
        loadedUsers: [],
      };

    default:
      return state;
  }
};
