import { FunctionComponent } from "react";
import { Container } from "./label.styles";

interface LabelProps {
  children: any;
}

// this is a generic label to be used with any kind of form element/entity
const Label: FunctionComponent<LabelProps> = ({ children }) => {
  return <Container>{children}</Container>;
};

export default Label;
