import jwtDecode from "jwt-decode";
import { log } from "utils/log-utils";
import { UserActions } from "./actions";
import { UserDataModel } from "./models";

export interface UserStateModel {
  loading: boolean;
  isAuthenticated: boolean;
  data: UserDataModel | null;
  token: any | null;
  error: any | null;
}

// Initial state
const initialState: UserStateModel = {
  loading: false,
  isAuthenticated: false,
  data: null,
  token: null,
  error: null, // error object
};

// Reducer
export const userReducer = (state = initialState, action: any = {}) => {
  switch (action.type) {
    case UserActions.USER_LOGIN:
      return { ...state, loading: true, error: null };

    case UserActions.USER_LOGIN_SUCCESS:
      // log.info({ obj: action.payload.token }, "USER_LOGIN_SUCCESS reducer token:");
      return {
        ...state,
        loading: false,
        token: jwtDecode(action.payload.token),
        isAuthenticated: true,
        error: null,
      };

    case UserActions.USER_LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        token: null,
        data: null,
        isAuthenticated: false,
        username: null,
        error: action.payload,
      };

    case UserActions.USER_LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        token: null,
        userName: null,
        data: null,
      };

    case UserActions.USER_LOAD:
      return {
        ...state,
        loading: true,
      };

    case UserActions.USER_DELETE:
      return {
        ...state, // store
        loading: true,
      };

    case UserActions.USER_LOAD_SUCCESS: {
      log.info({ obj: action.payload }, "USER_LOAD_SUCCESS PAYLOAD");
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    }
    case UserActions.USER_LOAD_FAILURE:
      log.info({ obj: action }, "USER_LOAD_FAILURE with action: ");
      return {
        ...state,
        loading: false,
        error: { message: "User loading failure" },
      };

    case UserActions.USER_REFRESH_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case UserActions.MY_USER_REFRESH_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    default:
      return state;
  }
};
