import { FunctionComponent } from "react";
import Icon from "./icon";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { Container } from "./delete-icon.styles";

interface DeleteIconProps {
  style?: any;
  onClickAction: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

const DeleteIcon: FunctionComponent<DeleteIconProps> = ({
  onClickAction,
  style,
  onMouseEnter = () => {},
  onMouseLeave = () => {},
}) => {
  const handleClick = (e: any) => {
    e.stopPropagation();
    onClickAction();
  };

  return (
    <Container
      style={style}
      onMouseEnter={() => onMouseEnter()}
      onMouseLeave={() => onMouseLeave()}
      role="button"
      onKeyPress={() => {}}
      tabIndex={0}
      onClick={handleClick}
    >
      <Icon icon={faTrashAlt} size="lg" />
    </Container>
  );
};

export default DeleteIcon;
