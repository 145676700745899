import { TabContent } from "reactstrap";
import styled from "styled-components";

export const Container = styled.div`
  background-color: ${(props) => props.theme.white};
  padding: 30px;
`;

export const StyledTabContent = styled(TabContent)`
  padding-top: 20px;
`;
