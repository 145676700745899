import { FunctionComponent } from "react";

interface TableRowProps {
  children: any;
}

const TableRow: FunctionComponent<TableRowProps> = ({ children }) => {
  return (
    <tr role="button" tabIndex={-1}>
      {children}
    </tr>
  );
};

export default TableRow;
