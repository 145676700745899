import { FunctionComponent } from "react";
import _ from "lodash";
import { useFilterState } from "modules/filter/hook";
import {
  customToggleStyles,
  StyledCustomDropdown,
} from "./attribute-group-multiselect.styles";
import { filterAddAttribute } from "modules/filter/actions";
import { ItemModel } from "components/navigation-dropdown";

interface AttributeGroupMultiselectProps {
  group: any;
}

const AttributeGroupMultiselect: FunctionComponent<
  AttributeGroupMultiselectProps
> = ({ group }) => {
  const filterState = useFilterState();

  const attributeUuids = _.map(group.attributes, (attribute) => attribute.uuid);
  const selected =
    _.intersection(filterState.selectedAttributes, attributeUuids).length > 0;

  return (
    <div>
      <StyledCustomDropdown
        customToggleStyles={customToggleStyles(selected)}
        title={group.name}
        items={group.attributes.map((attribute: any) => {
          const item: ItemModel = {
            text: attribute.name,
            onClick: () => {
              filterAddAttribute(attribute.uuid);
            },
          };
          return item;
        })}
      />
    </div>
  );
};

export default AttributeGroupMultiselect;
