import { FunctionComponent } from "react";

interface TableProps {
  children: any;
  style?: any;
}
const Table: FunctionComponent<TableProps> = ({ children, style }) => {
  return (
    <table style={style} className="table table-striped">
      {children}
    </table>
  );
};

export default Table;
