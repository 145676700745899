import { store } from "redux/store";

export enum ProductListActions {
  PRODUCT_LIST_LOAD = "PRODUCT_LIST_LOAD",
  PRODUCT_LIST_LOAD_SUCCESS = "PRODUCT_LIST_LOAD_SUCCESS",
}

export const productListLoad = (organizationUnitUuid: string) => {
  store.dispatch({
    type: ProductListActions.PRODUCT_LIST_LOAD,
    apiCall: {
      endpoint: `/organization-units/${organizationUnitUuid}/products`,
      method: "get",
      success: () => {},
      failure: () => {},
    },
  });
};
