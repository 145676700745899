import styled from "styled-components";
import mixins from "theme/mixins";

export const Container = styled.div<any>`
  ${mixins.fontCoda()};

  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;

  color: ${(props) => props.theme[props.color]};
  background-color: ${(props) => props.theme[props.backgroundColor]};
`;
