import { FunctionComponent, useEffect, useState } from "react";
import { ProductModel } from "../product/model";
import emptyImage from "images/no-image-330-230.png";
import {
  Count,
  CountContainer,
  StyledBadge,
  StyledCard,
  StyledEmptyImage,
} from "./product-card.styles";
import { CardBody } from "reactstrap";
import { useNavigate } from "react-router";
import Image from "components/image";
import { ProductImageModel } from "modules/product-image/model";

interface ProductCardProps {
  product: ProductModel;
}

const ProductCard: FunctionComponent<ProductCardProps> = ({ product }) => {
  const navigate = useNavigate();
  const [image, toggleImage] = useState<ProductImageModel | undefined>(
    undefined,
  );
  const { images = [], links = [] } = product;

  useEffect(() => {
    const init = async () => {
      if (images && images[0]) {
        const firstImage = product.images[0];
        toggleImage(firstImage);
      }
    };
    init();
  }, []);

  const renderImageAndLinkCount = () => {
    return (
      <CountContainer>
        {images.length > 1 && <Count>{images.length} images</Count>}
        {links.length > 0 && (
          <Count>
            {links.length} {links.length === 1 ? "link" : "links"}
          </Count>
        )}
      </CountContainer>
    );
  };

  const getImageMumber = () => {
    if (image) {
      const img = images.find((i) => i.publicId === image?.publicId);
      if (img) {
        return img.number;
      }
    }
    return undefined;
  };

  const productAttributes = product.attributes;

  return (
    <StyledCard onClick={() => navigate(`/products/${product.uuid}`)}>
      <CardBody style={{ marginBottom: 5, marginTop: 5 }}>
        <h4>{product.title}</h4>
      </CardBody>

      <CardBody
        style={{
          display: "flex",
          minHeight: "230px",
          padding: "12px",
          paddingTop: 0,
          position: "relative",
        }}
      >
        {renderImageAndLinkCount()}
        {image && (
          <Image
            thumbnail
            publicId={image.publicId}
            number={getImageMumber()}
            format={image.format}
          />
        )}
        {!image && <StyledEmptyImage url={emptyImage} />}
      </CardBody>

      <CardBody
        style={{
          paddingTop: 0,
          paddingBottom: 6,
          paddingLeft: 11,
          paddingRight: 11,
        }}
      >
        {productAttributes.map((attribute) => (
          <StyledBadge
            key={`${product.uuid}-${attribute.uuid}`}
            onClick={() => {}}
          >
            {attribute.name}
          </StyledBadge>
        ))}
      </CardBody>
    </StyledCard>
  );
};

export default ProductCard;
