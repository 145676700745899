import AddInput from "components/add-input";
import PageTitle from "components/page-title";
import TableBody from "components/table/body";
import TableBodyCell from "components/table/body-cell";
import TableHead from "components/table/head";
import TableHeadCell from "components/table/head-cell";
import TableRow from "components/table/row";
import Table from "components/table/table";
import {
  organizationCreate,
  organizationDelete,
  organizationsFetch,
} from "modules/sysadmin/actions";
import { Fragment, FunctionComponent, useEffect } from "react";
import { useSysadminState } from "./hook";
import OrganizationDeleteCell from "./organization-delete-cell";
import OrganizationEditCell from "./organization-edit-cell";

const OrganizationList: FunctionComponent = () => {
  const sysadminState = useSysadminState();
  const { organizations } = sysadminState;

  useEffect(() => {
    organizationsFetch();
  }, []);

  if (!organizations) {
    return null;
  }

  return (
    <Fragment>
      <PageTitle title="Organizations" rightText="Add and edit organizations" />

      <Table>
        <TableHead>
          <TableRow>
            <TableHeadCell colSpan={3}>Organization</TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {organizations.map((organization) => {
            return (
              <TableRow key={organization.uuid}>
                <TableBodyCell>{organization.name}</TableBodyCell>
                <OrganizationEditCell organization={organization} />
                <OrganizationDeleteCell
                  name={organization.name}
                  onSubmit={() => organizationDelete(organization.uuid)}
                />
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      <div style={{ marginTop: 20 }}>
        <AddInput
          text="Add new organization"
          placeholder="Enter a name for the new organization"
          onSave={(name) => organizationCreate({ name })}
        />
      </div>
    </Fragment>
  );
};

export default OrganizationList;
